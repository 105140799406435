import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../service/dashboard.service';
@Component({
  selector: 'app-recurring-job-status',
  templateUrl: './recurring-job-status.component.html',
  styleUrls: ['./recurring-job-status.component.scss']
})
export class RecurringJobStatusComponent implements OnInit {

  delayedSearch: FormGroup;
  delayedFilter: FormGroup;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public maxSize: number = 9;
  public loading: boolean = false;
  collection: any = [1];
  page: number = 1;
  total: any = 0;
  closeResult: string;
  recurrentJobDetails: any = [];
  selectedJobData: any = {}
  newNamesArr: any = [];
  searchNameQuery: string = null;
  //enum for status = PASSED, FAILED
  constructor(private formBuilder: FormBuilder, private route: Router,
    private service: DashboardService, private modalService: NgbModal,private loader: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.delayedSearch = this.formBuilder.group({
      search: [null]
    });
    this.delayedFilter = this.formBuilder.group({
      filterStatus: [null]
    });
    this.recurringPageDetails();
  }
  delayedSearches() {
    console.log('serch', this.delayedSearch)
    this.recurringPageDetails()
  }
  delayedFilters() {
    this.recurringPageDetails()
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  pageChange(event) {
    this.page = (event);
    this.recurringPageDetails();
  }
  recurringPageDetails() {
    this.loader.show()
    let payload = {
      "page": this.page - 1,
      "size": 10,
      "orderBy": "modifiedDate",
      "order": "DESC",
      "status": this.delayedFilter.value.filterStatus === "null" ? null : this.delayedFilter.value.filterStatus,
      "name": this.delayedSearch.value.search ?   this.delayedSearch.value.search.trim() : null,
    }
    this.service.getRecurringJobData(payload).subscribe(res => {
        this.loader.hide()
        this.recurrentJobDetails = res.data.content.map(obj => ({ ...obj, newNames: obj.newNames ?  JSON.parse(obj.newNames ) : [] }));
        this.total = res.data.totalElements;
    },
    (err: HttpErrorResponse) => {
      this.loader.hide()
      console.log(err)
    })
  }
  goBack() {
    this.route.navigateByUrl('manage-datasource');
  }
  openDialog(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  setJobData(data) {
    this.selectedJobData = data
    this.newNamesArr = data.newNames
  }
}

