<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark unique-color" [containerInside]="false" style="position: fixed;width: 100%; top:0;z-index: 5;">
    <mdb-navbar-brand>
        <a class="navbar-brand " href="/dashboard">
            <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
        </a>
    </mdb-navbar-brand>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
           
            <button *ngIf="loggedIn && buttonVal" mdbBtn color="success" rounded="true" size="sm" [routerLink]="['/adminhome']" routerLinkActive="active">{{roles.name}}</button>
            <ng-container *ngIf="loggedIn"><span style="color: #fff;">Welcome&nbsp;<strong>{{currentUserName}}&nbsp;!</strong></span></ng-container>
            <span *ngIf="loggedIn" (click)="openDialog(notification)" style="cursor: pointer;" ><i class='far fa-bell ml-2' style="color: white;"></i><sup *ngIf="jobList.length>0" style="color: white;
                font-size: 10px;
                top: -12px;" >&#9679;</sup></span>
            <span *ngIf="loggedIn" (click)="logout()" id="logout-icon"><i class='fa fa-solid fa-power-off'></i></span>
        </li>
    </ul>
</mdb-navbar>
<div class="body" id="body-pd">
    <header *ngIf="loggedIn" class="header" id="header">
        <div class="header_toggle"> <i class='fa fa-bars' id="header-toggle" (click)="toggleNavbar()" style="padding-left: 25px; padding-top: 20px;"></i> </div>

    </header>
    <div *ngIf="loggedIn" class="l-navbar" id="nav-bar">
        <nav class="nav">
            <div>
                <div class="nav-list">
                    <ng-container *ngFor="let item of layoutMenus; let i = index">

                        <a routerLink="{{item?.router}}" [id]="item.title" class="nav_link active sidebar" [ngStyle]="{'color':  item?.router==currentUrl ? '#D2691E' : '#000000' }"> <i class="nav_icon" [ngClass]="[item?.icon]"></i> <span id="menuItem" class="nav_name">{{ item?.title}} </span> </a>

                    </ng-container>
                </div>
            </div>
            <a href="#" class="nav_link"> <i class='bx bx-log-out nav_icon'></i> <span class="nav_name">SignOut</span>
            </a>
        </nav>
    </div>
    <!--Container Main start-->
    <div class="height-100 bg-light" id="body-1" style="padding-top: 67px;padding-right: 0px;">
        <!-- <ng-container *ngIf="loggedIn">
      <app-admin-topmenu></app-admin-topmenu>
    </ng-container> -->
        <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </div>
</div>

<ng-template #notification let-modal>
    <section >
        <div class="d-flex" style="justify-content:space-between;">
            <h4 class="subHeading " style="color: #2C8085;padding:24px">Notifications</h4>
            <button type="button" style="color: #2C8085;padding:8px;font-size: 24px;" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        
        <div class="main" style="height: 400px;overflow-y:auto">
            <div style="padding: 0px 24px;" *ngFor="let job of jobList">
                <div class="d-flex justify-content-between  mb-2">
                    <p style="color:#2C8085 ;">{{job.dataSource?.name?job.dataSource.name:job.message}}</p>
                    <button type="button" class="btn round-button" style="height: auto;width:auto;margin: 0;padding: 0;max-height: 36px;" (click)="markRead(job.id)"  >Mark As Read</button>
                </div>
                <div class="d-flex justify-content-between  mb-2">
                    <p *ngIf="job.dataSource?.name" class="card-label">{{job.message}}</p>
                    <div *ngIf="job.dataSource==null" >
                        <!-- <p class="collapsible" >Details</p>
                        <div class="content" >
                            <p>{{job.detailedMessage}}</p>
                        </div> -->
                        <p mdbBtn color=" primary" class="card-label" style="height:36px;border-bottom: dashed 2px #2C7F85;padding: 12px;margin: 0;font-size: 16px; text-transform:capitalize;color:#6a6a6a" (click)="test.toggle()" mdbWavesEffect>
                            Details
                        </p>
                          <div class="mt-2" style="color:#6a6a6a" mdbCollapse #test="bs-collapse">
                            <p>{{job.detailedMessage}}</p>
                          </div>
                    </div>
                    <p class="card-label">{{job.createdDate | slice: 0:10}}</p>
                </div>
                
                
                <hr>
            </div>
            <h5 class="text-center mt-4 mb-4" *ngIf="jobList.length==0">
                 Notification Empty
            </h5>
        </div>
        <div class="d-flex" style="background-color: #f2f2f2;padding: 12px 24px" (click)="goToNotifications();modal.dismiss('Cross click')">
            
            <p style="color:#2C8085 ;margin: 0;cursor: pointer;">Go to Notifications</p>
            <section class="brand ml-2" style="cursor: pointer;">
                <img src="../assets/images/goto.svg" style="width: 100%;" >
            </section>
        </div>
        
    </section>

</ng-template>