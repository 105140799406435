import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { filter } from 'rxjs/operators';
//import { AppService } from 'src/app/services/';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  hasSub: boolean[] = [];
    layoutMenus: any;
    currentUrl: any;
    standardServices: any = null;
    constructor(
        private _layoutService: LayoutService,
        private router: Router,
        private data: DataService
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e) => {
            this.currentUrl = e['url'];
            this.hasSub = [];
            // console.log(this.currentUrl);
            this.getSub(this.currentUrl);
            // console.log(this.hasSub);
        });
    }

    getSub(currentUrl) {
        let layoutMenu = this.layoutMenus;
        for (var i = 0; i < layoutMenu.length; ++i) {
            if (layoutMenu[i].router === currentUrl) {
                this.hasSub[i] = true;
            } else {
                for (var j = 0; j < layoutMenu[i].children.length; ++j) {
                    if (layoutMenu[i].children[j].router === currentUrl) {
                        this.hasSub[i] = true;
                    }
                }
            }
        }
    }

    
    // to get slug
    getSlug(data) {
        return data.toLowerCase().replace(/ /g, '-');
    }

    ngOnInit() {
    //     this.appService.devOpsRestriction().subscribe((res) =>{
    //        if(res.slug==='ROLE_SUPERADMIN'){
    //            this.getLayout('ROLE_SUPERADMIN');
    //        }else{
    //             this.getLayout('admin');
    //        }
    //     });
    //     var a = this.data.loadClientData.subscribe((val) => {
    //         this.clientData = val;
    //     });
    //     this.data.subscriptions.push(a);

    //     var standardServices;
    //     var b = this.data.loadClientStandardServices.subscribe((val) => {
    //         this.standardServices = val;
    //     });
    //     this.data.subscriptions.push(b);
    // 
    this.getLayout(this.data.getUserRole());
  }

  
    getLayout(role: any) {
        this.layoutMenus = this._layoutService.getLayout(role);
        console.log(this.layoutMenus);
    }

    activeRoute(routename: string): boolean {
        // console.log(this.router.url);
        return this.router.url == routename;
    }

    //click hndler for sub menu
    subMenuClick(item, e) {
        console.log(item.title);
        // var individualHeight = 38;
        if (item.children.length) {
            document.getElementById(item.title).classList.toggle('rotateDown');
            document.getElementById('dropdown' + e).classList.toggle('show');
            // document.getElementById("dropdown" + e).style.height = (document.getElementById("dropdown" + e).style.height.length) ? "0px" : (individualHeight * parseInt(item.children.length)) + "px";
        }
    }

    openLink(item, i) {
        if (item.children.length) {
            this.subMenuClick(item, i);
        } else {
            this.router.navigate([item.router]);
        }
    }

    activeClass(item) {
        // console.log("this.router.url"+this.router.url);
        // console.log("this.currentUrl"+this.currentUrl);
        var cond1 = this.currentUrl == item.router;
        var hasRouter;
        if (item.children.length) {
            var arr = item.children;
            var hasRouter = arr.filter((a) => {
                return a.router == this.currentUrl;
            });
        }
        if (hasRouter) {
            if (hasRouter.length) {
                return 'active';
            }
        } else if (cond1) {
            return 'active';
        } else {
            return null;
        }
    }

    
    hasSubChange(index: any, menu: object) {
        // if(document.querySelector('app-sidebar > div.side-menu')['offsetWidth'] !== 70){
        // console.log(menu);
        if (menu['router'] == '/developer') {
            window.open('https://dashboard.kychub.com/developer', '_blank');
            return;
        }

        if (menu['router']) {
            this.router.navigate([menu['router']]);
            if (!this.hasSub[index]) {
                this.hasSub = [];
            }
            this.hasSub[index] = !this.hasSub[index];
        } else {
            if (!this.hasSub[index]) {
                this.hasSub = [];
            }
            this.hasSub[index] = !this.hasSub[index];
        }
        // }
    }

    // logOut(){
    //   this.data.removeAllCookie();
    //   this.router.navigate(['/login'])
    // }
    
}
