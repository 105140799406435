import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { Observable } from "rxjs";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  //     Roles with ids

  //     342  , processor
  //     339	, EXTRACTOR	extractor
  //     340	, TRANSFORMER	transformer
  //     341	, QA	qa
  //     16980, da
  //     338	, ADMIN	admin
  //     337	, SUPER ADMIN	super-admin

  layoutMenu: any = [
    {
      title: "Dashboard",
      router: "/dashboard",
      count: 0,
      role: ["all"],
      icon: "fa fa-home",
      children: [],
    },
    {
      title: "ER Dashboard",
      router: "/er-dashboard",
      count: 0,
      role: [337, 338],
      icon: "fa fa-window-restore",
      children: [],
    },
    {
      title: "Datasource Management",
      router: "/manage-datasource",
      count: 0,
      role: ["all"],
      icon: "fa fa-database",
    },
    {
      title: "Datasource Hub",
      router: "/datasource-hub",
      count: 0,
      role: ["all"],
      icon: "fa fa-suitcase",
    },

    {
      title: "Manage Team",
      router: "/manage-team",
      count: 0,
      role: [337, 338],
      icon: "fa fa-users",
    },
    {
      title: "Work Tracker",
      router: "/work-tracker",
      count: 0,
      role: [337, 338],
      icon: "fa fa-address-card",
      children: [],
    },
    {
      title: "Issue Tracker",
      router: "/issue-tracker",
      count: 0,
      role: ["all"],
      icon: "fa fa-exclamation-triangle",
      children: [],
    },
    {
      title: "Data Visualisation",
      router: "/data-visualisation",
      count: 0,
      role: ["all"],
      icon: "fa fa-plus",
      children: [],
    },
    {
      title: "Apache Airflow",
      router: "/apache-airflow",
      count: 0,
      role: ["all"],
      icon: "fa fa-plane",
    },
    {
      title: "QA Testing",
      router: "/qa-testing",
      count: 0,
      role: ["all"],
      icon: "fa fa-exclamation-triangle",
    },
    {
        title: "Merge and Test Mode",
        router: "/merge-test",
        count: 0,
        role: ["all"],
        icon: "fa fa-code-branch pl-1",
      },
    {
      title: "Datasource History",
      router: "/datasource-history",
      count: 0,
      role: [337, 338],
      icon: "fa fa-chart-bar",
    },
  ];

  layoutMenuTiny: any = [
    "/home",
    "/user-reports",
    "/risk-policy",
    "/aml-reports",
    "check-status",
    "/history",
    "/live-check",
    "/doc-extraction",
    "/settings",
    "/doc-extraction",
    "/roles",
    "/developer",
    "/faq",
  ];

  constructor() {
    // this.layoutMenuTiny = this.layoutMenu.map((x)=>{
    //   return x.router;
    // });
  }

  getLayout(roles: any) {
    roles = roles || [];
    // Returning layout with 'all' and those who have atleast 1 role common between clientuser roles arr and layout role arr
    return this.layoutMenu.filter(
      (layout: any) =>
        layout.role.includes("all") ||
        _.intersection(
          layout.role,
          roles.map((e) => e.id)
        ).length
    );
  }
}
