export class Requirement {
    name:string
    dataAgencyName:string
    dataAgencyUrl:string
    harvestingPageUrl:string
    isindividualLink:string
    individualUrl:string
    pageLanguage:string
    popupSelector:string
}
