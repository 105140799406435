

<section>
    <header class="header" id="header">
        <div class="header_toggle"> <i class='bx bx-menu' id="header-toggle"></i> </div>
    </header>
    <div class="l-navbar" id="nav-bar">
        <nav class="nav">
            <div> 
                <div class="nav_list"> 
                    <ng-container *ngFor="let item of layoutMenus; let i = index">
                        <a routerLink="{{item?.router}}"> <i [ngClass]="[item?.icon]"></i> <span class="nav_name">{{ item?.title }} </span> </a> 
                    </ng-container>
                </div>
            </div> 
            <a href="#" class="nav_link"> <i class='bx bx-log-out nav_icon'></i> <span class="nav_name">SignOut</span> </a>
        </nav>
    </div>
</section>