import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {CommonModule }from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { GettingStartedComponent } from './components/getting-started/getting-started.component';
import { AppHttpInterceptor } from './interceptors/http.interceptor';
import { SelectorsComponent } from './components/selectors/selectors.component';
import { PageDetailsComponent } from './components/page-details/page-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HarvestedDataComponent } from './components/harvested-data/harvested-data.component';
import { ImageLabellingComponent } from './components/image-labelling/image-labelling.component';
import { AdminhomeComponent } from './components/adminhome/adminhome.component';
import { AttributesComponent } from './components/attributes/attributes.component';
import { CleanersComponent } from './components/cleaners/cleaners.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DataconfigsComponent } from './components/dataconfigs/dataconfigs.component';
import { EditsourceComponent } from './components/editsource/editsource.component';
import { FormcontrolDirective } from './directives/formcontrol.directive';
import {NgxPaginationModule} from 'ngx-pagination';
import { ClientRegistrationComponent } from './components/client-registration/client-registration.component';
import { ErHomeComponent } from './components/er-home/er-home.component';
import { ListEntityComponent } from './components/list-entity/list-entity.component';
import { GroupEntitiesComponent } from './components/group-entities/group-entities.component';
import { CreateDsComponent } from './components/create-ds/create-ds.component';
import { PopulateDsComponent } from './components/populate-ds/populate-ds.component';
import { CreateDsSummaryComponent } from './components/create-ds-summary/create-ds-summary.component';
import { AdminTopmenuComponent } from './components/shared/admin-topmenu/admin-topmenu.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { ManageTeamModule } from './components/manage-team/manage-team.module';
import { WorkTrackerModule } from './components/work-tracker/work-tracker.module';
import { IssueTrackerModule } from './components/issue-tracker/issue-tracker.module';
import { ChartsModule } from 'ng2-charts';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CollapseModule, WavesModule } from 'angular-bootstrap-md';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    GettingStartedComponent,
    SelectorsComponent,
    PageDetailsComponent,
    HarvestedDataComponent,
    ImageLabellingComponent,
    AdminhomeComponent,
    AttributesComponent,
    CleanersComponent,
    DataconfigsComponent,
    EditsourceComponent,
    FormcontrolDirective,
    ClientRegistrationComponent,
    ErHomeComponent,
    ListEntityComponent,
    GroupEntitiesComponent,
    CreateDsComponent,
    PopulateDsComponent,
    CreateDsSummaryComponent,
    AdminTopmenuComponent,
    SidebarComponent,    
  ],
  imports: [
    BrowserModule,
    CollapseModule, WavesModule ,
    CommonModule,
    ManageTeamModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    DashboardModule,
    WorkTrackerModule,
    IssueTrackerModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    ChartsModule,
    NgxSpinnerModule,
    
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }), 
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    
    
    // ToastrModule added
  ],
  providers: [
    
    //{ provide: "API_URL", useValue: "http://api.aml.kychub.com" },
    // { provide: "API_URL", useValue: "http://api.aml.dev.kychub.com" },
    { provide: "API_URL_DET", useValue: "https://api.dashboard.fincai.kychub.com/det" },
    //{ provide: "API_URL", useValue: "http://api.dashboard.fincai.kychub.com" },
    { provide: "API_URL", useValue: "https://api.fincai.kychub.com" },
    //{provide: "API_DET_PYTHON", useValue: "https://dea.fincai.kychub.com"},
    
    // {provide: "API_DET_PYTHON", useValue: "http://15.206.141.8:8701"},
    {provide: "API_DET_PYTHON", useValue: "http://34.142.77.139:8701"},   // new value
    
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },

  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
