<!-- Editable table -->

<div class="col-lg col-md col-sm d-flex justify-content-center">
  <div class="card">

    <div class="card-body">
      <div id="table" class="table-editable">

        <form (ngSubmit)="onSubmit()">
          <a href="{{displayUrl}}" target="_blank">{{displayUrl}}</a>

          <span class="table-add float-right mb-3 mr-2">


            <button mdbBtn color="info" (click)="add()" rounded="true" size="md" class="mb-2" type="button">Add
              more</button>

            <button mdbBtn color="success" rounded="true" size="md" class="mb-2" type="submit">Save</button>


          </span>
          <table class="table table-bordered table-responsive-md table-striped text-center" style="table-layout: fixed">
            <tr>
              <th class="text-center">Property</th>
              <th class="text-center">Css Selector</th>
              <th class="text-center">Extraction Info</th>

              <th class="text-center">Value</th>
              <th class="text-center">Verify</th>
              <th class="text-center">Remove</th>
            </tr>
            <tr *ngFor="let person of personList; let id = index">
              <td>
                <select [(ngModel)]="person.property" name="person.property" [ngModelOptions]="{standalone: true}"
                  ngDefaultControl style="width: 100%;">
                  <option [ngValue]="item.slug" *ngFor="let item of property" >{{item.attribute}}</option>
                </select>
                <!-- <input type="text"    [(ngModel)]="person.property"    ngDefaultControl [ngModelOptions]="{standalone: true}" contenteditable="true"/> -->
              </td>
              <td>
                <input [(ngModel)]="person.cssSelector" ngDefaultControl [ngModelOptions]="{standalone: true}" style="width: 100%;" />
              </td>
              <td>
                <select class="browser-default custom-select" name="person.extractionInfo"
                  [(ngModel)]="person.extractionInfo" [ngModelOptions]="{standalone: true}" ngDefaultControl style="width: 100%;">
                  <option selected disabled >{{person.extractionInfo}}</option>
                  <option value="TEXT">TEXT</option>
                  <option value="URL">URL</option>
                  <option value="IMAGE">IMAGE</option>
                </select>
                <!-- <span contenteditable="true" (keyup)="changeValue(id, 'extractionInfo', $event)" (blur)="updateList(id, 'extractionInfo', $event)">{{person.extractionInfo}}</span> -->
              </td>

              <td>
                <input [(ngModel)]="person.value" [ngModelOptions]="{standalone: true}" ngDefaultControl style="width: 100%;" />

              </td>
              <td>
                <span class="table-remove">
                  <button type="button" mdbBtn color="yellow" rounded="true" size="sm" class="my-0"
                    (click)="check(person,id)">Check</button>
                </span>
              </td>
              <td>
                <span class="table-remove">
                  <button type="button" mdbBtn color="danger" rounded="true" size="sm" class="my-0"
                    (click)="remove(id)">Remove</button>
                </span>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Editable table -->