
<div class="container-fluid">
    <div class="row">
        <div class="col-2">
            <a mdbBtn floating="true" (click)="goback()">
                <mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon>
            </a>

        </div>
        <div class="col-10">
            <span class="float-right mt-2">
                <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/adminhome']"
                    routerLinkActive="active" class="mb-2" type="button">Home</button>

                <button mdbBtn color="primary" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/attributes']"
                    routerLinkActive="active" class="mb-2" type="button">Attributes</button>

                <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/cleaners']"
                    routerLinkActive="active" class="mb-2" type="button">Cleaners</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/dataconfigs']"
                    routerLinkActive="active" class="mb-2" type="button">Data Configs</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm"
                    [routerLink]="['/client-registration']" routerLinkActive="active" class="mb-2"
                    type="button">Register User</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/erhome']"
                    routerLinkActive="active" class="mb-2" type="button">Resolve Entity</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm"
                    [routerLink]="['/create-datasource']" routerLinkActive="active" class="mb-2" type="button">Create
                    Data Source</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm"
                    [routerLink]="['/populate-datasource']" routerLinkActive="active" class="mb-2"
                    type="button">Populate Data Source</button>


            </span>




        </div>
    </div>

</div>


<div class="container-fluid">
    <div class="row">

        <div class="col-lg col-md col-sm d-flex justify-content-center">
            <div class="card">
                <form [formGroup]="createDsForm" class="text-center ">

                    <p class="card-title mb-2">Data Source Details</p>

                    <div class="form-group">
                        <label for="amlDataSourceId">AML Data Source ID</label>
                        <input type="text" readonly formControlName="amlDataSourceId" class="form-control" placeholder="AML Data Source ID" />
                    </div>

                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" readonly formControlName="name" class="form-control" placeholder="Name." />
                    </div>
                    <div class="form-group">
                        <label for="slug">Slug</label>
                        <input type="text" readonly formControlName="slug" class="form-control" placeholder="Slug" />
                    </div>
                    <div class="form-group">
                        <label for="harvestingPageUrl">Harvesting Page URL</label>
                        <input type="url" readonly placeholder="Harvesting Page URL"
                            formControlName="harvestingPageUrl" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="dataAgencyName">Agency Name</label>
                        <input type="text" readonly id="myInput" placeholder="Agency Name" formControlName="dataAgencyName"
                            class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="dataAgencyUrl">Agency URL</label>
                        <input type="url" readonly placeholder="Agency URL" formControlName="dataAgencyUrl"
                            class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="dataAgencySlug">Agency Slug</label>
                        <input type="text" readonly placeholder="Agency Slug" formControlName="dataAgencySlug"
                            class="form-control" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>