import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import{Location} from '@angular/common'
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-populate-ds',
  templateUrl: './populate-ds.component.html',
  styleUrls: ['./populate-ds.component.scss']
})
export class PopulateDsComponent implements OnInit {

  populateDsForm: FormGroup = this.fb.group({
    dataSourceSlug:['']
  });
  loading:boolean;
  constructor(
    private fb:FormBuilder,
    private auth:AuthService,
    private location:Location,
    private data:DataService,
    private spinner:NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.spinner.show();
    let payload={
      dataSourceSlug : this.populateDsForm.value['dataSourceSlug'],
      path: ""
    }
    this.data.populateDs(payload).subscribe(result=>{
      this.spinner.hide();
      let alertObj = {
        message:result.message,
        status: result.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
      if(result.statusCode==200){
        this.populateDsForm.patchValue({
          dataSourceSlug:''
        })
      }
    })
  }

  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }
}
