<div class="content">
    <div class="row">
        <div class="col-12">
            <h5 class="new">
                <a>
                    <i class="fa fa-arrow-left" (click)="goBack()" style="cursor: pointer;"><span
                            style="margin-left: 20px; font-family: Arial"> Work Tracker</span></i>
                </a>
            </h5>
        </div>
    </div>
    <div class="card">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 common">
                <label class="label">Name </label>
                <p class="label-content">{{(detailsVal.clientsUser.firstName?detailsVal.clientsUser.firstName:'')+' '+(detailsVal.clientsUser.middleName?detailsVal.clientsUser.middleName:'')+' '+(detailsVal.clientsUser.lastName?detailsVal.clientsUser.lastName:'')}}
                </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 common">
                <label class="label">Working On </label>
                <p class="label-content">-</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 common">
                <label class="label">Email </label>
                <p class="label-content">{{detailsVal.clientsUser.email}}</p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 common">
                <label class="label">Role </label>
                <div class="roleBox d-flex" style="flex-wrap: wrap;justify-content: center;">
                    <ng-container *ngFor="let item of detailsVal.clientsUser.roles" style="display: inline-block;">
                        <ng-container [ngSwitch]="item?.name">
                            <ng-container *ngSwitchCase="'SUPER ADMIN'">
                                <button class="btn btn-super-admin btn-sm">{{ item.name }}</button>
                                <div class="vl"></div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'ADMIN'">
                                <button class="btn btn-admin btn-sm">{{ item?.name }}</button>
                                <div class="vl"></div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'EXTRACTOR'">
                                <button class="btn btn-extractor btn-sm">{{ item?.name }}</button>
                                <div class="vl"></div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'TRANSFORMER'">
                                <button class="btn btn-transformer btn-sm">{{ item?.name }}</button>
                                <div class="vl"></div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'QA'">
                                <button class="btn btn-qa btn-sm">{{ item?.name }}</button>
                                <div class="vl"></div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'PROCESSOR'">
                                <button class="btn btn-processor btn-sm">{{ item?.name }}</button>
                                <div class="vl"></div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row d-flex" style="justify-content: center;">
            <button class="btn button button-blue" style="max-width: 160px;" (click)="downloadReport(detailsVal.clientsUser.email)">Download Report</button>
        </div>
    </div>
    <div class="card">
        <div class="row">
            <div class="col-12" style="padding: 5px;">
                <h4 style="margin:20px 0px"><strong>Schedules</strong></h4>
                <div class="tabs-container">
                    <div (click)='switchTab(statusObj.status)' *ngFor="let statusObj of taskStatusArr" class="status-tab" [ngClass]="selectedTaskStatusForFilter === statusObj.status ? 'activeTab':'inactiveTab'">
                        {{statusObj.status}}
                    </div>
                </div>
                <div class="cards-wrapper" style="margin:20px">
                    <ng-container *ngIf="detailsData.length === 0">
                        <p>No result to shown</p>
                    </ng-container>
                    <ng-container *ngIf="detailsData.length !== 0">
                        <ng-container *ngFor="let content of detailsData">
                            <div class="card-container">
                                <div class="card-title" [ngClass]="content.process.role.slug.toLowerCase()">{{content.process.name | uppercase}}
                                </div>
                                <div class='card-content'>
                                    <div class="card-content-upper">
                                        <div class="process-title">
                                            <p class="label-content padding-hr">{{content.name}}</p>
                                        </div>
                                        <div class="additional-info-mid">
                                            <hr>
                                            <label class="label padding-hr mg-bt-none">Assigned To:&nbsp;<span
                                                    class="label-content">{{content.taskAssignedTo.firstName}}</span></label>
                                            <label class="label padding-hr mg-bt-none">Assigned By:&nbsp;<span
                                                    class="label-content">{{content.taskAssignedBy.firstName}}</span></label>
                                        </div>
                                    </div>

                                    <div class="additional-info">
                                        <hr>
                                        <label class="label padding-hr mg-bt-none">Finish Date:&nbsp;<span
                                                class="label-content">{{content.taskEstimatedCompletionDate}}</span></label>
                                        <label class="label padding-hr">Task Status:&nbsp;<span
                                                class="label-content">{{content.taskStatus}}</span></label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>