import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkTrackerService } from './service/work-tracker.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-work-tracker',
  templateUrl: './work-tracker.component.html',
  styleUrls: ['./work-tracker.component.scss']
})
export class WorkTrackerComponent implements OnInit {

  delayedSearch: FormGroup;
  delayedFilter: FormGroup;
  taskListValues: any = [];
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public maxSize: number = 9;
  public loading: boolean = false;
  collection: any = [1];
  page: number = 1;
  total: number = 0;
  allowedSections = [];

  constructor(private formBuilder: FormBuilder,
    private service: WorkTrackerService,
    private dataService: DataService,
    private router: Router,
    private loader: NgxSpinnerService) {
      this.delayedSearch = this.formBuilder.group({
        search: ['']
      });
      this.delayedFilter = this.formBuilder.group({
        search: ['']
      });
     }

  ngOnInit(): void {
    
    // let roles=this.dataService.getUserRole();
    // let role=roles[0].name;
    // console.log("role", role)
    // if(role=='SUPER ADMIN' ||role=='ADMIN' ){
    //   this.delayedSearch = this.formBuilder.group({
    //     search: ['']
    //   });
    //   this.delayedFilter = this.formBuilder.group({
    //     search: ['']
    //   });
    //   this.taskList();
    // }
    // else{
    //   this.router.navigateByUrl('dashboard');
    // }
    this.loader.show()
    this.dataService.getRestrictedSection('work tracker').subscribe(res => {
      for (let section of res.data) {
        this.allowedSections.push(section.slug)
        switch (section.slug) {
          case 'work-tracker-clients-list-paginated':
            this.taskList();
            break;


          default:
            break;
        }
      }
      this.loader.hide()
    })

  }
  delayedSearches() {
    this.loader.show()
    let payload = {
      "page": this.page - 1,
      "size": 10,
      "orderBy": null,
      "order": "DESC",
      "name":this.delayedSearch.value.search,
    }
    this.service.taskList(payload).subscribe(response => {
      this.taskListValues = response.data.content;
      this.total = response.data.totalElements;
      this.loader.hide()
    })
  }
  delayedFilters() {

  }
  pageChange(event) {
    this.page = (event)
  }
  taskList() {
    this.loader.show()
    let payload = {
      "page": this.page - 1,
      "size": 10,
      "orderBy": null,
      "order": "DESC"
    }
    this.service.taskList(payload).subscribe(response => {
      this.taskListValues = response.data.content;
      this.total = response.data.totalElements;
      this.loader.hide()
    })
  }
  workAssign(val) {
    this.service.workTrackerAssign(val);
    this.router.navigate(['details']);
  }

}


