import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { UpcomingWorkComponent } from './upcoming-work/upcoming-work.component';
import { DelayedWorkComponent } from './delayed-work/delayed-work.component';
import { UnassignedDatasourcesComponent } from './unassigned-datasources/unassigned-datasources.component';
import { RecurringJobStatusComponent } from './recurring-job-status/recurring-job-status.component';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [
    DashboardComponent,
    UpcomingWorkComponent,
    DelayedWorkComponent,
    UnassignedDatasourcesComponent,
    RecurringJobStatusComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    NgxPaginationModule
  ]
})
export class DashboardModule { }
