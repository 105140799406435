
<!-- <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <a mdbBtn floating="true" (click)="goback()"   ><mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>
  
      </div>
      <div class="col-10">
          <span class="float-right mt-2">
            <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/adminhome']" routerLinkActive="active"  class="mb-2" type="button" >Home</button>
  
              <button mdbBtn color="primary" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/attributes']" routerLinkActive="active"  class="mb-2" type="button" >Attributes</button>
    
              <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/cleaners']" routerLinkActive="active"  class="mb-2" type="button" >Cleaners</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/dataconfigs']" routerLinkActive="active"  class="mb-2" type="button" >Data Configs</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/client-registration']" routerLinkActive="active"  class="mb-2" type="button" >Register User</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/erhome']" routerLinkActive="active"  class="mb-2" type="button" >Resolve Entity</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/create-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Create Data Source</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/populate-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Populate Data Source</button>
    
          
            </span>
  
          
          
  
      </div>
    </div>
      
  </div> -->
      
<div class="container-fluid">
    <div class="row">

        <div class="col-lg col-md col-sm d-flex justify-content-center">
            <div class="card">
                <form [formGroup]="createDsForm" (ngSubmit)="onSubmit()" class="text-center ">

                    <p class="card-title mb-2">Create Data Source</p>

                    <!-- Email -->
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" formControlName="name" class="form-control" placeholder="Enter your Name."
                             />
                    </div>
                    
                    <div class="form-group">
                        <label for="dataAgencyName">Agency Name</label>
                        <input type="text" id="myInput" placeholder="Enter Agency Name" formControlName="dataAgencyName" class="form-control"
                             />
                    </div>
                    <div class="form-group">
                        <label for="dataAgencyUrl">Agency URL</label>
                        <input type="url" placeholder="Enter your Agency URL" formControlName="dataAgencyUrl" class="form-control"
                            />
                    </div>

                    <div class="form-group">
                        <label for="harvestingPageUrl">Harvesting Page URL</label>
                        <input type="url" placeholder="Enter your Harvesting Page URL" formControlName="harvestingPageUrl" class="form-control"
                            />
                    </div>
                    <div class="form-group">
                        <label for="crawlType">Crawl Type</label>
                        <select  formControlName="crawlType" class="form-control">
                            <option value="" disabled>Select Crawl Type</option> 
                            <option ngValue="HEADSELENIUM">Head</option>
                            <option ngValue="HEADLESSSELENIUM">Headless</option>
                        </select>
                    </div>
                   
                    <div class="form-group">
                        <label for="country">Country</label>
                        <input type="text" placeholder="Enter your Country" formControlName="country" class="form-control"
                            />
                    </div>
            
                <button mdbBtn color="info" [disabled]="loading"  size="md" class="my-3" type="submit">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Create</button>
               

        </form>
    </div>
</div>
</div>
</div>
