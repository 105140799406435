import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WorkTrackerService } from '../../work-tracker/service/work-tracker.service';
import { DataService } from 'src/app/services/data.service';
import { DatePipe, formatDate } from '@angular/common';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  detailsForm: FormGroup = this.form.group({
    sourceName: [''],
    raisedBy: [''],
    harvestingUrl: [''],
    assigedTo: [''],
    dataUrl: [''],
    status: [''],
    description: [''],
    startDate: [''],
    completionDate: [''],
    desc: ['']


  });
  commentVal:any=[];
  id: any;
  taskId: any;
  isAdmin: boolean = false;
  data: any;
  page:number=1;
  total:number=0;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  maxSize: number = 3;
  loading:boolean=false;
  constructor(
    private form: FormBuilder,
    private route: ActivatedRoute,
    private service: WorkTrackerService,
    private dataservice: DataService) { }

  ngOnInit(): void {
    // this.id = this.route.queryParams.get("val")
    // console.log("id issue1", this.id)
    this.route.paramMap.subscribe(param => {
      this.id = param.get("val");
      this.details();
      this.comments();

    })
  }
  details() {
    this.service.issueById(this.id).subscribe(res => {
      this.data = res.data;
      this.taskId = this.data.id;
      this.detailsForm.patchValue({
        sourceName: this.data.task.job.dataSource.name,
        raisedBy: this.data.issueAssignedBy.email,
        harvestingUrl: this.data.task.job.dataSource.harvestingPageUrl,
        assigedTo: this.data.issueAssignedTo.email,
        dataUrl: this.data.task.job.dataSource.dataAgencyUrl,
        status: this.data.issueStatus,
        description: this.data.probableIssue,
        startDate: this.convertToDate(this.data.issueStartDate),
        completionDate: this.convertToDate(this.data.issueEstimatedCompletionDate),
      })
      this.comments();
    });
  }
  convertToDate(dateString) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let d = dateString.split("-");
    let dat = new Date(d[1] + '/' + d[0] + '/' + d[2]);
    var date = new Date(dat),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");

  }
  updateChanges() {
    let payload = {
      id: this.id,
      task: {
        "id": this.taskId
      },
      issueAssignedTo: {
        email: this.detailsForm.value.assigedTo
      },
      issueEstimatedCompletionDate: new DatePipe('en-US').transform(this.detailsForm.value.completionDate, 'dd-MM-yyyy'),
      issueStartDate: new DatePipe('en-US').transform(this.detailsForm.value.startDate, 'dd-MM-yyyy'),
      issueStatus: this.detailsForm.value.status,
      probableIssue: this.detailsForm.value.description,
    }
    this.service.issueUpdate(payload).subscribe(val => {
      this.details();
      let alertObj = {
        message: "Issue Updated",
        status: "SUCCESS",
        autoDismiss: true,
        timeToDismiss: 3000
      };
      this.dataservice.toastrShow(alertObj.message, alertObj.status)
    })
    let body = {
      taskIssueTracking: {
        id: this.taskId
      },
      comment: this.detailsForm.value.desc,
      commentedBy: {
        email: this.data.issueAssignedTo.email
      }
    }
    this.service.commentUpdate(body).subscribe($val=>{
      this.detailsForm.value.desc=['']
      this.comments();
    })
    

  }
  comments(){
    let payload={
      page:this.page-1,
      size:3,
      orderBy:"createdDate",
      order:"DESC",
      taskIssueId:this.taskId
    }
    this.service.commentList(payload).subscribe(res=>{
      this.commentVal=res.data.content;
      this.total=res.data.totalElements;
    })
  }
  pageChange(event){
    this.page=event;
    this.comments();
  }

}
