

<div class="container-fluid">
  <div class="row">
    <div class="col-2">
      <a mdbBtn floating="true" (click)="goback()"   ><mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>

    </div>
    <div class="col-10">
        <span class="float-right mt-2">
          <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/adminhome']" routerLinkActive="active"  class="mb-2" type="button" >Home</button>

            <button mdbBtn color="primary" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/attributes']" routerLinkActive="active"  class="mb-2" type="button" >Attributes</button>
  
            <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/cleaners']" routerLinkActive="active"  class="mb-2" type="button" >Cleaners</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/dataconfigs']" routerLinkActive="active"  class="mb-2" type="button" >Data Configs</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/client-registration']" routerLinkActive="active"  class="mb-2" type="button" >Register User</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/erhome']" routerLinkActive="active"  class="mb-2" type="button" >Resolve Entity</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/create-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Create Data Source</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/populate-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Populate Data Source</button>
  
        
          </span>

        
        

    </div>
  </div>
    
</div>

<div class="container-fluid" style="margin-top: 0%;">
        <div class="row">
          
        <div class="card">
          
          <div class="card-body">
            <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="increaseSize($event)">
              <option [ngValue]="25">25 items per page</option>
              <option [ngValue]="50">50 items per page</option>
              <option [ngValue]="100">100 items per page</option>
            </select>
         -->
        
            <span class="table-add float-right mb-3 mr-2">
                <div class="d-flex justify-content-center " >
        
                    <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search by name" style="height: 32px;" id="search-input"
                         mdbInput>
                  
                  
                    <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
                    <button  style="margin:0px;" type="button" mdbBtn size="sm" color="dark" mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon></button>
                  
              </div>
            
              </span>
          <table class="table table-bordered table-striped text-center " style="table-layout: fixed;width:100%" mdbTable  #tableEl="mdbTable"  >
              <thead class="thead-dark sticky-top" >
                <tr>
                    <th style="width: 8%;">Action</th>
                     
                  <th style="width: 24%;">Name</th>
                  <th style="width: 30%;">Address</th>
                  <th>Summary</th>
                  <!-- <th>Harvesting Page Url</th> -->
                  
                  
                </tr>
                  
              </thead>
              <tbody #row>
                <tr mdbTableCol *ngFor="let item of tabData;let id=index;" id="{{id}}"  > 
                    <td>
                        <input type="checkbox"  (change)="checkData(id,item.guid,$event.target.checked)" [checked]="inputChecked(id,item)" />
                    </td>
                             
                  <td  >{{item?.fullName}}</td>
                  <td style="word-break: break-all;"  >{{item.address[0].fullAddress}}</td>
                  <td style="word-break: break-all;"  >{{item.summary}}</td>

                  <!-- <td>{{item.harvestingPageUrl}}</td> -->
                  
                      </tr>
              </tbody>
             
            </table>
         <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="group()">Group</button>
            </div>
        </div>
            <!-- <div class="d-flex justify-content-center">
            <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
          </div> -->
            
      </div>
      </div>