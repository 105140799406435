import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WorkTrackerService {

  constructor(private _http: HttpClient,private toastr: ToastrService,@Inject("API_URL") public API_URL: any) { }

  taskList(payload) {
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/work-tracker/clients-list`,payload);
  }
  downloadReport(email): Observable<HttpResponse<Blob>> {
    return this._http.get<Blob>(`${this.API_URL}/dashboard/v1/report/data-engineer?email=${email}`, { observe: 'response', responseType: 'blob' as 'json' });
  }
  workFilter(payload,email) {
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/work-tracker/filter?email=${email}`,payload);
  }
  public work_tracker = new BehaviorSubject<any>('');
  workTrackerAssign(value){
      this.work_tracker.next(value);
  }
  issueFilter(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/task-issue-tracking/filter`,payload);
  
  }
  issueById(id){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/task-issue-tracking/task-issue-detail/${id}`);
  
  }
  
  issueUpdate(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/task-issue-tracking/update`,payload);
  
  }
  commentUpdate(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/task-issue-comment/create`,payload);
  
  }
  commentList(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/task-issue-comment/filter`,payload);
  }
}
