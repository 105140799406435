import { Component, OnInit, Inject, AfterViewInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'
import { state } from '@angular/animations';
@Component({
  selector: 'app-group-entities',
  templateUrl: './group-entities.component.html',
  styleUrls: ['./group-entities.component.scss']
})
export class GroupEntitiesComponent implements OnInit {
  tabData:any;
  searchText:string;
  selectedItem: Array<any>=[];
  checkedData:boolean;
  groupedEntity:any=[];
  new: { id: number; value: any; };
  groupMappedItem: any;
  val: string;
  mainGuid: string;
  newarr: Array<any>=[];
  finalGroup: { guid: string; group: any; };
  
  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private modalService:NgbModal,
    private fb:FormBuilder,
    @Inject("API_URL") public API_URL: any,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // console.log(this.router.getCurrentNavigation().extras.state)
    console.log(history.state.data)
    let abc=history.state.data
    console.log(abc.item.details)
    this.mainGuid=abc.item.guid
    console.log(this.mainGuid)
    this.tabData=abc.item.details
    console.log()
  }
  checkData(id:number,item:string,isChecked:boolean){
    // let {checked, value} = event.target;
    // console.log(value)
    // console.log(checked)
    // this.val=value
    
    if(isChecked) {
      
      this.selectedItem.push({"id":id,"value":item});
      console.log("checked")
      console.log(item)
      console.log(this.selectedItem)
      
      
    }
      else {
      console.log("unchecked")
      console.log(item)
      let index = this.selectedItem.findIndex(x=>x.value===item)
      console.log(index)
  

      this.selectedItem.splice(index,1);
      
      console.log(this.selectedItem)
    }
    
  


    // if(this.checkedData=true){
    //   console.log("checked")
    // }
    // else(console.log("unchecked"))
   
    
    // this.tabData.filter(x => x.checked).map(x => {
    //   console.log(x.guid);
    // })
    // this.selectedItem.push(item)
  }
  inputChecked(i: any, data: any){
    let checked = false;
    // console.log("data",data)
    for(let j=0;j<this.tabData.length;j++){
      let temp=this.tabData[i].guid
      // console.log("temp",temp)
     
      if(temp==data.guid  ){
       checked=false
        // console.log("checkedsssss",temp)
      }
    }
    return checked
  }
  group(){
    this.selectedItem.map(x=>{
      console.log(x.id)
      const el = <HTMLElement>document.getElementById(`${x.id}`);
      if (el.classList.contains('hidden')) {
        el.classList.remove('hidden');
      } 
      else {
        el.classList.add('hidden');
      }

    })
    
    console.log(this.selectedItem)
    
    console.log("final Data",this.groupedEntity)
   this.selectedItem.map(x=>{
      (x.value);
      this.tabData.filter(item=>{
        if(x.value==item.guid){
          console.log(item)
           
           this.newarr.push(item.guid)
           
          
        }
      })
    });
    
  console.log("FINALLY",this.groupedEntity)
    this.selectedItem=[];
    this.finalGroup={"guid":this.mainGuid,"group":[this.newarr]};
    console.log(this.finalGroup);
    this.submitGroupedData(this.finalGroup);
    
    
  }
  submitGroupedData(obj){
    
    
    console.log(obj)
    console.log(this.newarr)
    this.data.manualResolve(obj).subscribe(
      data=>{
        console.log(data)
        this.newarr=[];
      }
    )
  }
  searchItems(){

  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }
}
