 

<div class="container-fluid">
  <div class="row">
    <div class="col-2">
      <a mdbBtn floating="true" (click)="goback()"   ><mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>

    </div>
    <div class="col-10">
        <span class="float-right mt-2">
          <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/adminhome']" routerLinkActive="active"  class="mb-2" type="button" >Home</button>

            <button mdbBtn color="primary" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/attributes']" routerLinkActive="active"  class="mb-2" type="button" >Attributes</button>
  
            <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/cleaners']" routerLinkActive="active"  class="mb-2" type="button" >Cleaners</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/dataconfigs']" routerLinkActive="active"  class="mb-2" type="button" >Data Configs</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/client-registration']" routerLinkActive="active"  class="mb-2" type="button" >Register User</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/erhome']" routerLinkActive="active"  class="mb-2" type="button" >Resolve Entity</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/create-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Create Data Source</button>
              <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/populate-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Populate Data Source</button>
  
        
          </span>

        
        

    </div>
  </div>
    
</div>

<div class="container-fluid" style="margin-top: 0%;">
        <div class="row">
          
        <div class="card">
          
          <div class="card-body">
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="increaseSize($event)">
              <option [ngValue]="25">25 items per page</option>
              <option [ngValue]="50">50 items per page</option>
              <option [ngValue]="100">100 items per page</option>
            </select>
        
            <select class="custom-select" style="width: auto" [(ngModel)]="entityResolutionStatus" (ngModelChange)="filterStatus($event)">
              <option value="IN_QACHECK">IN_QACHECK</option>
              
              
            </select>
            <select class="custom-select" style="width: auto" [(ngModel)]="entityType" (ngModelChange)="filterType($event)">
              <option value="Individual">Individual</option>
              <option value="Organisation">Organisation</option>
              
              
            </select>
            <span class="table-add float-right mb-3 mr-2">
                <div class="d-flex justify-content-center " >
        
                    <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search by name" style="height: 32px;" id="search-input"
                         mdbInput>
                  
                  
                    <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
                    <button  style="margin:0px;" type="button" mdbBtn size="sm" color="dark" mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon></button>
                  
              </div>
            
              </span>
          <table class="table table-bordered table-striped text-center " style="table-layout: fixed;width:100%" mdbTable  #tableEl="mdbTable"  >
              <thead class="thead-dark sticky-top" >
                <tr>
                   <th style="width: 8%;">ID</th>    
                  <th style="width: 24%;">Name</th>
                  <th style="width: 30%;">Resolution Status</th>
                  <!-- <th>Harvesting Page Url</th> -->
                  
                  <th class="th-xl" style="width: 30%;">Actions</th>
                </tr>
                  
              </thead>
              <tbody #row>
                <tr mdbTableCol *ngFor="let item of src| paginate: { itemsPerPage: pageSize, currentPage: page+1,totalItems: total };let id=index;" (click)="onClickSearch($event,item)">
                  <td  class="red-text">{{id+1}}</td>           
                  <td  >{{item?.dataSourceSlug}}</td>
                  <td style="word-break: break-all;"  >{{item?.entityResolutionStatus}}</td>
                  <!-- <td>{{item.harvestingPageUrl}}</td> -->
                  
                  <td  style="display: contents;">
                    {{item?.details.length}}
                    <span class="table-remove" >
                        
                        <!-- <button type="button" mdbBtn color="light" rounded="true" size="sm" mdbTooltip="Edit Data Source" placement="down" mdbWavesEffect  class="btn"  ><i class="fas fa-edit fa-lg"></i></button> -->
                      <!-- <button type="button" mdbBtn color="indigo" rounded="true" size="sm"  mdbTooltip="Add Config" placement="down" mdbWavesEffect  (click)="onClick($event,content,item)" class="btn"  ><i class="fas fa-plus fa-lg"></i></button>

                      <button type="button" mdbBtn color="light" rounded="true" size="sm" mdbTooltip="Edit Data Source" placement="down" mdbWavesEffect (click)="onClickEdit($event,item)" class="btn"  ><i class="fas fa-edit fa-lg"></i></button>
                      <button type="button" mdbBtn color="info" rounded="true" size="sm"  mdbTooltip="Check Image Labelling" placement="down" mdbWavesEffect (click)="checkImage($event,item)" class="btn"  ><i class="fas fa-image fa-lg"></i></button>
                      <button type="button" mdbBtn color="deep-orange" rounded="true" size="sm" mdbTooltip="Check Extracted Data" placement="down" mdbWavesEffect (click)="checkExtractedData($event,item)" class="btn"  ><i class="fas fa-table fa-lg"></i></button>
                      <button type="button" mdbBtn color="amber" rounded="true" size="sm" mdbTooltip="Start Fresh Extraction" placement="down" mdbWavesEffect (click)="startExtraction($event,item,start)" class="btn"  >Extract</button>

                      <button type="button" mdbBtn color="success" rounded="true" size="sm" mdbTooltip="Download JSON" placement="down" mdbWavesEffect (click)="onClickDownload($event,item)" class="btn"  ><i class="fas fa-arrow-down fa-lg"></i></button>
                       -->
                      
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot class="grey lighten-5 w-100">
                <tr>
                  <td colspan="8">
                    
                    <pagination-controls [maxSize]="maxSize"
                      
                     [directionLinks]="directionLinks"
                    
                    [responsive]="responsive" 
                     
                    (pageChange)="pagec($event)"
                     ></pagination-controls>

                    <!-- <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="elements"></mdb-table-pagination> -->
                  </td>
                </tr>
                </tfoot>
            </table>
            </div>
        </div>
            <!-- <div class="d-flex justify-content-center">
            <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
          </div> -->
            
      </div>
      </div>