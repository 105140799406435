import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import { DataService } from '../../services/data.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service'
import { HttpErrorResponse } from '@angular/common/http';
import { Requirement } from '../../models/requirement'
@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {
  // public requirements: FormGroup= new FormGroup({
  //   name:new FormControl ('', Validators.required),
  //   url:new FormControl ('', Validators.required),
  //   isindividualLink:new FormControl('',Validators.required),
  //   individualUrl:new FormControl ('', Validators.required),

  // });
  req = new Requirement()
  isindividualLink: any
  submitted: boolean;
  loading: boolean;
  selectors: any;
  url: any;
  isAdmin: boolean;
  roles: any;
  constructor(
    private data: DataService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.req.pageLanguage = null;
    this.req.popupSelector = null;
    this.auth.checkProfile().subscribe(
      data => {
        this.roles = this.auth.roles
        console.log("role", this.roles)
        // if(this.roles.slug=='ROLE_SUPERADMIN'){
        //   console.log('hi')
        //   this.isAdmin=true
        // }
        // else if(this.roles.slug=='ROLE_CLIENTVIEWER'){
        //   console.log('hello')
        //   this.isAdmin=false
        // }
        window.sessionStorage.setItem('userRole', this.roles.slug);
      }
    )



  }
  // get f() { return this.requirements.controls; }
  onSubmit(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      return;

    }
    this.loading = true;
    // this.req.name=this.requirements.get('name').value
    // this.req.url=this.requirements.get('url').value
    // this.req.isindividualLink=this.requirements.get('isindividualLink').value
    // this.req.individualUrl=this.requirements.get('individualUrl').value
    const extractionResult={
      "harvest":this.req.harvestingPageUrl,
      "indivisual":this.req.individualUrl
    }
    this.data.extraction(extractionResult);
    console.log("response1",this.req);
    this.spinner.show()
    this.data.postSelectors(this.req).subscribe(
      data => {
        console.log(data.statusCode == 200)
        if (data.statusCode == 200) 
        {
          this.spinner.hide()
          this.loading = false
          console.log('hello', data)
          let alertObj = {
            message: data.message,
            status: "success",
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message, "success")

          this.selectors = data['data']
          this.url = data['data']['amlDataSourceId']
          console.log('req', this.url)
          console.log('hi', this.selectors)
          let queryParams: any = [];
          // Create our array of values we want to pass as a query parameter
          // const arrayOfValues = [data];

          // Add the array of values to the query parameter as a JSON string
          queryParams = JSON.stringify(this.url);

          // Create our 'NaviationExtras' object which is expected by the Angular Router
          const navigationExtras: NavigationExtras = {
            queryParams

          };
          this.router.navigate(['/selectors', navigationExtras])
          console.log('json', queryParams)


        }


      },
      (err: HttpErrorResponse) => {
        console.log(err)
        this.loading = false;
        this.spinner.hide();
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message, alertObj.status)

      }

    )

  }
  logout() {
    this.auth.logout();
  }
}
