import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IssueTrackerComponent } from './issue-tracker.component';
import { DetailsComponent } from './details/details.component';
const routes: Routes = [
  {path: "", component: IssueTrackerComponent},
  {path: "issue-tracker/details/:val",component: DetailsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IssueTrackerRoutingModule { }
