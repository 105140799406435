import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManageTeamRoutingModule } from './manage-team-routing.module';
import { ManageTeamComponent } from './manage-team/manage-team.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ManageTeamComponent
  ],
  imports: [
    CommonModule,
    TagInputModule,
    NgxPaginationModule,
    MDBBootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    ManageTeamRoutingModule,
    NgSelectModule,
    NgbModule
  ]
})
export class ManageTeamModule { }
