import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartType, ChartOptions } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import { DashboardService } from './service/dashboard.service';
import { WorkTrackerService } from '../work-tracker/service/work-tracker.service';
import { DataService } from '../../services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  upcomingSearch: FormGroup;


  public pieChartOptions: ChartOptions = { responsive: true, legend: { position: 'right' } };
  public pieChartData: SingleDataSet = [300, 200, 170, 2085];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  // this.pieChartOptions.plugins.legend.position = this.pieChartOptions.plugins.legend.position === 'left' ? 'top' : 'left';
  public pieChartLabels: string[] = ['Total', 'P1', 'P2', 'P3'];
  public chartColors: Array<any> = [
    {
      backgroundColor: ['#00008B', '#1F75FE', '#74BBFB', '#E9F6FA'],
      borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
    }
  ];

  public logsPieChartData: SingleDataSet = [];
  public completedPieChartData: SingleDataSet = [];
  public delayedPieChartData: SingleDataSet = [];
  public waitingPieChartData: SingleDataSet = [];
  public ongooingPieChartData: SingleDataSet = [];
  upcomingValues: any = [];
  delayedValues: any = [];
  recurringValues: any = [];
  overallStatusValues: any = [];
  // Req enum values to filter out from api response
  categoryOverallStatusValues: any = ["SANCTION", "PEP", "Crime", "WatchList", "SIP"];
  unassignedValues: any = [];
  issueTrackVal: any = [];
  page: number = 1;
  total: number = 0;
  allowedSections: any = [];
  totaEntitiesCount: number = 0;
  constructor(
    private formBuilder: FormBuilder,
    private service: DashboardService,
    private workService: WorkTrackerService,
    private dataService: DataService,
    private loader: NgxSpinnerService,
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.loader.show()
    this.dataService.getRestrictedSection('dashboard').subscribe(res => {
      // Reducing api calls for non-admin,api giving duplicate data.
      this.allowedSections = [... new Set([...res.data.map(obj=>obj.slug)])]
      for (let slug of this.allowedSections) {
        console.log('data',)
        switch (slug) {
          case 'dashboard-ongoing':
            this.ongoingPie();
            break;
          case 'dashboard-delayed':
            this.delayedPie();
            break;
          case 'dashboard-waiting':
            this.waitingPie();
            break;
          case 'dashboard-completed':
            this.completedPie();
            break;
          case 'dashboard-logs':
            this.logsPie();
            break;
          case 'dashboard-upcoming-work':
            this.upcomingData();
            break;
          case 'dashboard-delayed-work':
            this.delayedData();
            break;
          case 'dashboard-unassigned-work':
            this.unassignedData();
            break;
          case 'dashboard-recurring-job-status':
            this.recurringData();
            break;
          case 'dashboard-overall-status':
            this.overallstatusData();
            break;
          case 'dashboard-task-issue-list':
            this.taskList();
            break;
          default:
            break;
        }
      }
      this.loader.hide()
    })

    this.upcomingSearch = this.formBuilder.group({
      search: ['']
    });


    // this.upcomingData();
    // this.delayedData();
    // this.recurringData();
    // this.unassignedData();
    // this.overallstatusData();

    // this.logsPie();
    // this.delayedPie();
    // this.completedPie();
    // this.waitingPie();
    // this.ongoingPie();
    // this.taskList();

  }



  upcomingSearches() {

  }

  upcomingData() {
    let payload = {
      "page": 0,
      "size": 2,
      "orderBy": null,
      "order": "DESC"
    }
    this.service.upcomingData(payload).subscribe(response => {
      this.upcomingValues = response.data.content;
      console.log("upcoming vals", this.upcomingValues);
    })
  }

  delayedData() {
    let payload = {
      "page": 0,
      "size": 2,
      "orderBy": null,
      "order": "DESC",
      // "taskStatus": "DELAYED"
    }
    this.service.delayedData(payload).subscribe(response => {
      this.delayedValues = response.data.content;
      console.log("delayed vals", this.delayedValues);
    })
  }
  recurringData() {

    this.service.recurringData().subscribe(response => {
      this.recurringValues = response.data;
      console.log("Recurring vals", response);
    })
  }
  overallstatusData() {
    let payload = {
      "size": 0,
      "query": {
        "bool": {
          "must": [
            {
              "bool": {
                "should": [
                  {
                    "bool": {
                      "should": [
                        {
                          "nested": {
                            "path": "details",
                            "query": {
                              "match": {
                                "details.fullName": {
                                  "query": "Donald Trump",
                                  "fuzziness": 2,
                                  "operator": "and"
                                }
                              }
                            }
                          }
                        },
                        {
                          "nested": {
                            "path": "details",
                            "query": {
                              "nested": {
                                "path": "details.alias",
                                "query": {
                                  "match": {
                                    "details.alias.fullName": {
                                      "query": "Donald Trump",
                                      "fuzziness": 2,
                                      "operator": "and"
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            },
            {
              "bool": {
                "should": [
                  {
                    "nested": {
                      "path": "details",
                      "query": {
                        "term": {
                          "details.entityType.keyword": {
                            "value": "Individual"
                          }
                        }
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      },
      "aggs": {
        "details": {
          "nested": {
            "path": "details"
          },
          "aggs": {
            "dataSource": {
              "terms": {
                "field": "details.datasource.name.keyword",
                "size": 10000
              }
            },
            "category": {
              "terms": {
                "field": "details.category.name.keyword",
                "size": 10000
              }
            },
            "gender": {
              "terms": {
                "field": "details.gender.keyword",
                "size": 10000
              }
            },
            "entityType": {
              "terms": {
                "field": "details.entityType.keyword",
                "size": 10000
              }
            },
            "nationality": {
              "terms": {
                "field": "details.nationality.name.keyword",
                "size": 10000
              }
            }
          }
        }
      }
    }
    this.service.overallstausData(payload).subscribe(response => {
      this.overallStatusValues = response.data;
      this.totaEntitiesCount = response.data.type.map(obj => obj.doc_count).reduce((a, b) => a + b)
      this.categoryOverallStatusValues = this.categoryOverallStatusValues.map(e => response.data.category.find(cate => cate.key === e))
      console.log("Overall status vals",response.data, this.categoryOverallStatusValues);
    })
  }
  unassignedData() {
    let payload = {
      "page": 0,
      "size": 2,
      "orderBy": null,
      "order": "DESC",
      "taskStatus": "UNASSIGNED"
    }
    this.service.unassignedData(payload).subscribe(response => {

      this.unassignedValues = response.data.content;
      console.log("unassigned vals", response);
    })
  }


  logsPie() {
    this.service.logsPiChartData().subscribe(response => {
      this.logsPieChartData.push(response.data.count);
      this.logsPieChartData.push(response.data.p1Count);
      this.logsPieChartData.push(response.data.p2Count);
      this.logsPieChartData.push(response.data.p3Count);
      console.log("logs Pie", this.logsPieChartData)
    })
  }
  ongoingPie() {
    this.service.ongoingPiChartData().subscribe(response => {
      this.ongooingPieChartData.push(response.data.count);
      this.ongooingPieChartData.push(response.data.p1Count);
      this.ongooingPieChartData.push(response.data.p2Count);
      this.ongooingPieChartData.push(response.data.p3Count);
      console.log("Ongoing", this.ongooingPieChartData[0]);
    })
  }
  completedPie() {
    this.service.completedPiChartData().subscribe(response => {
      this.completedPieChartData.push(response.data.count);
      this.completedPieChartData.push(response.data.p1Count);
      this.completedPieChartData.push(response.data.p2Count);
      this.completedPieChartData.push(response.data.p3Count);
    })
  }
  delayedPie() {
    this.service.delayedPiChartData().subscribe(response => {
      this.delayedPieChartData.push(response.data.count);
      this.delayedPieChartData.push(response.data.p1Count);
      this.delayedPieChartData.push(response.data.p2Count);
      this.delayedPieChartData.push(response.data.p3Count);
    })
  }
  waitingPie() {
    this.service.waitingPiChartData().subscribe(response => {
      this.waitingPieChartData.push(response.data.count);
      this.waitingPieChartData.push(response.data.p1Count);
      this.waitingPieChartData.push(response.data.p2Count);
      this.waitingPieChartData.push(response.data.p3Count);
    })
  }
  taskList() {
    let payload = {
      "page": this.page - 1,
      "size": 4,
      "order": "DESC",
      "orderBy": "createdDate"
    }
    this.workService.issueFilter(payload).subscribe(response => {
      this.issueTrackVal = response.data.content;
      this.total = response.data.totalElements;
    })
  }
  checkVisibility(key) {

  }

}
