

<!-- <app-admin-topmenu step="2"></app-admin-topmenu> -->

<div class="col-lg col-md col-sm ">
  <div class="row d-flex justify-content-center">
    <div class="card">

      <div class="card-body">
    <span class="table-add float-right mb-3 mr-2">
      <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText" (keyup)="searchItems()" id="search-input"
      mdbInput>
  
    </span> 
    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="increaseSize($event)">
      <option [ngValue]="25">25 items per page</option>
      <option [ngValue]="50">50 items per page</option>
      <option [ngValue]="100">100 items per page</option>
    </select>
      <table class="table table-bordered  table-striped text-center" mdbTable responsive="true" #tableEl="mdbTable">
          <thead class="thead-dark">
            <tr>
               <th>ID</th>    
              <th >Name</th>
              <th>Url</th>
              <!-- <th>Harvesting Page Url</th> -->
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
              
          </thead>
          <tbody #row>
            <tr mdbTableCol *ngFor="let item of sourceconfig | paginate: { itemsPerPage: pageSize, currentPage: page+1,totalItems: total };let i=index">
              <td  class="red-text">{{item.dataSource?.amlDataSourceId}}</td>           
              <td   >{{item.dataSource?.name}}</td>
              <td  >{{item.dataSource?.dataAgencyUrl}}</td>
              <!-- <td>{{item.harvestingPageUrl}}</td> -->
              <td  >{{item?.createdDate|date:'mediumDate'}}</td>
              <td  >
                <span class="table-remove">
                  <button type="button" mdbBtn color="light" rounded="true" size="sm"  (click)="onClickEdit($event,content,item)" class="my-0"  ><i class="fas fa-edit"></i></button>
                  

                </span>
              </td>
            </tr>
          </tbody>
          <tfoot class="grey lighten-5 w-100">
            <tr>
              <td colspan="8">
                <pagination-controls [maxSize]="maxSize"
                      
                [directionLinks]="directionLinks"
               
               [responsive]="responsive" 
                (pageChange)="pagec($event)"></pagination-controls>
              </td>
            </tr>
            </tfoot>
        </table>
        </div>
      </div>
    </div>
        <!-- <div class="d-flex justify-content-center">
        <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
      </div> -->
        
  </div>
  <ng-template #content class="modal-lg" let-modal >
    <div class="modal-header" style="padding: 1rem;">
      <h4 class="modal-title" id="modal-basic-title">Add Data Config</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="col-lg col-md col-sm d-flex justify-content-center">
            <div class="card">
              
                <div class="card-body">
                  
                  <div id="table" class="table-editable">
                    
                    <form (ngSubmit)="onSubmit()" >
                      <div class="form-group">
                        <label for="baseImgUrl">baseImgUrl</label>
                        <input appFormcontrol type="text" [(ngModel)]="config.baseImgUrl" name="baseImgUrl"  class="form-control"  />
                        
                    </div>
                    <div class="form-group">
                      <label for="employer">employer</label>
                      <input appFormcontrol type="text" [(ngModel)]="config.employer" name="employer"  class="form-control"  />
                      
                  </div>
                  <div class="form-group">
                    <label for="attribute">Category</label>
                    <select appFormcontrol [(ngModel)]="cat" ngDefaultControl [ngModelOptions]="{standalone: true}" name="cat.category" class="form-control">
                      <option selected disabled>{{cat?.name}}</option>
                      <option [ngValue]="item" *ngFor="let item of allCategory">{{item.name}}</option>
                      </select>
                </div>
                <div class="form-group">
                  <label for="risklevel">Risk Level</label>
                  <select appFormcontrol [(ngModel)]="config.riskLevel" [ngModelOptions]="{standalone: true}" ngDefaultControl class="form-control">
                    <option value="National">National</option> 
                    <option value="International">International</option> 
                    </select>
              </div>
              <div class="form-group">
                <label for="EntityType">Entity Type</label>
                <select appFormcontrol [(ngModel)]="config.entityType" [ngModelOptions]="{standalone: true}" ngDefaultControl class="form-control">
                  <option value="Individual">Individual</option> 
                  <option value="Organisation">Organisation</option> 
                  <option value="Aircraft">Aircraft</option> 
                  <option value="Vessel">Vessel</option>
                  </select>
            </div>
            <div class="form-group">
              <label for="nationality">Nationality</label>
              <input appFormcontrol type="text" [(ngModel)]="config.nationality" name="nationality"  class="form-control"  />
              
          </div>
          <div class="form-group">
            <label for="summary">Summary</label>
            <input appFormcontrol type="text" [(ngModel)]="config.summary" name="summary"  class="form-control"  />
            
        </div>
                      
                        
                    <table class="table table-bordered table-responsive-md table-striped text-center">
                      <tr>
                        <th class="text-center">Attribute</th>
                        
                        <th class="text-center">cleaner</th>
                      
                        <th class="text-center">Remove</th>
                      </tr>
                      <tr *ngFor="let item of updateConfig; let id = index">
                        <td>
                          <select [(ngModel)]="item.name"  (change)="onItemChange(item.name)" name="item.name" [ngModelOptions]="{standalone: true}" ngDefaultControl class="form-control"> 
                            <option [ngValue]="new.slug"  *ngFor="let new of allAttributes">{{new.attribute}}</option>
                          </select>
                          <!-- <input type="text"    [(ngModel)]="person.property"    ngDefaultControl [ngModelOptions]="{standalone: true}" contenteditable="true"/> -->
                        </td>
                        
                        <td>
                            
                    <ng-multiselect-dropdown
                    [placeholder]="'custom placeholder'"
                    [settings]="dropdownSettings"
                    [data]="dropdownList"
                    [(ngModel)]="item.cleaner"
                    name="item.cleaner"
                    [ngModelOptions]="{standalone: true}" 
                    ngDefaultControl
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)"
                    
                    >
                    </ng-multiselect-dropdown>
                            
                        </td>
                
                        <td>
                          <span class="table-remove">
                            <button type="button" mdbBtn color="danger" rounded="true" size="sm" class="my-0" (click)="remove(id)">Remove</button>
                          </span>
                        </td>
                      </tr>
                    </table>
                    <div class="modal-footer d-flex justify-content-center">
                      <button mdbBtn color="info" (click)="add()" rounded="true" size="md" class="mb-2" type="button" >Add more</button>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                        <button type="submit" class="btn btn-success">
                             Save
                        </button>
                    </div>
                </form>
                  </div>
                </div>
              </div>
              </div>
        
        </div>
    </ng-template>