import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageTeamService {

  constructor(private http: HttpClient, @Inject("API_URL") public API_URL:any) { }

  filterAPIForProcessFlow(payload: Object){
    return this.http.post(`${this.API_URL}/dashboard/v1/process-flow/filter`, payload).pipe(map((res:any) => res.data));
  }

  filterUser(payload: Object){
    return this.http.post(`${this.API_URL}/dashboard/v1/team-user/filter`, payload).pipe(map((res:any) => res.data));
  }

  updateProcessFlow(payload: Object){
    return this.http.put(`${this.API_URL}/dashboard/v1/process-flow/update`, payload).pipe(map((res:any) => res.data));
  }

  createProcessFlow(payload: Object){
    return this.http.post(`${this.API_URL}/dashboard/v1/process-flow/create`, payload).pipe(map((res:any) => res.data));
  }
  createTeam(payload: Object){
      return this.http.post(`${this.API_URL}/dashboard/v1/team/create`, payload).pipe(map((res:any) => res.data));
  }
  addNewTeamMember(payload: Object){
    return this.http.post(`${this.API_URL}/dashboard/v1/team-user/create`, payload).pipe(map((res:any) => res.data));
  }
  updateTeamMember(payload: Object){
    return this.http.put(`${this.API_URL}/dashboard/v1/team-user/update`, payload).pipe(map((res:any) => res.data));
  }
  teamCount(){
    return this.http.get(`${this.API_URL}/dashboard/v1/team-detail/count`).pipe(map((res: any) => res.data))
  }
  getEmailListByName(payload){
    return this.http.post(`${this.API_URL}/clientsuser/search`, payload).pipe(map((res:any) => res.data));
  }
  fetchAllTeams(){
    return this.http.get(`${this.API_URL}/dashboard/v1/team/get-teams`).pipe(map((res: any) => res.data))
  }
  deleteTeamUser(payload){
    return this.http.post(`${this.API_URL}/dashboard/v1/team-user/delete`, payload).pipe(map((res:any) => res.data));
  }
}
