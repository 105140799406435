import { Directive ,ElementRef} from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appFormcontrol]',
  host: {
    '(input)': 'onInput($event)'
  }
})
export class FormcontrolDirective {

  constructor(
    private _elmRef: ElementRef, 
    private ngControl: NgControl
  ) { }
  onInput(event) {
    console.log('In the directive :: ' + event);
    // capitalize the input and set the value
    if(this.ngControl.control.value==''){
    this.ngControl.control.setValue(event.target.value=null);
  }
  }
}
