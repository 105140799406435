<!--/.Navbar-->
<!-- Default form login -->
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 d-flex justify-content-center">
            <img src="assets/images/extraction.svg" style="margin-top: -80px;">
        </div>
        <div class="col-md-6 d-flex justify-content-center">
            <div class="card">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="text-center ">

                    <p class="card-title mb-2">Please Login to proceed.</p>

                    <!-- Email -->
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input type="text" formControlName="username" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" id="myInput" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-around">
                        <div>
                            <!-- Remember me -->
                            <!-- <mdb-checkbox [default]="true" type="checkbox" (change)="myFunction($event)">Show Password</mdb-checkbox> -->
                        </div>

                    </div>

                    <!-- Sign in button -->
                    <button mdbBtn color="info" [disabled]="loading" block="true" class="my-4" type="submit">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in</button>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    <!-- Register -->

                </form>
            </div>
        </div>
    </div>
</div>
<!-- Default form login -->