<div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <a mdbBtn floating="true" (click)="goback()"   ><mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>
  
      </div>
      <div class="col-10">
          <span class="float-right mt-2 mr-3">
            <!-- <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/adminhome']" routerLinkActive="active"  class="mb-2" type="button" >Home</button> -->
  
              <!-- <button mdbBtn color="primary" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/attributes']" routerLinkActive="active"  class="mb-2" type="button" >Attributes</button>
    
              <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/cleaners']" routerLinkActive="active"  class="mb-2" type="button" >Cleaners</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/dataconfigs']" routerLinkActive="active"  class="mb-2" type="button" >Data Configs</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/client-registration']" routerLinkActive="active"  class="mb-2" type="button" >Register User</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/erhome']" routerLinkActive="active"  class="mb-2" type="button" >Resolve Entity</button> -->
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/create-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Create Data Source</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/populate-datasource']" routerLinkActive="active"  class="mb-2" type="button" >Populate Data Source</button>
                <select (change)="goToMenu()" class="menuSelect" [(ngModel)]="selectedMenu">
                  <option value="" selected disabled>Select</option>
                  <option  *ngFor="let item of menuRoutes" [ngValue]="item">{{item.name}}</option>
                </select>
    
          
            </span>
  
          
          
  
      </div>
    </div>
      
  </div>