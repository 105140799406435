
<a mdbBtn floating="true" (click)="goback()"   ><mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>
<span class="float-right mt-2 mr-4 mb-2">
  <button class="btn btn-success" (click)="imglabel()">Label Image</button>
      
</span>
<div class="col-lg col-md col-sm d-flex justify-content-center">
  <div class="card">
  
    <div class="card-body">
  <span class="table-add float-right mb-3 mr-2">
    
     <button mdbBtn color="success" rounded="true" size="md" class="mb-2" type="button"(click)="download()" >Download JSON</button>

  </span>
    <table class="table table-bordered table-responsive-md table-striped text-center" mdbTable responsive="true">
        <thead class="thead-light">
          <tr>
             <th>#</th>    
            <th *ngFor="let head of newItems[0]|keyvalue ">{{head.key}}</th>
            
          </tr>
            
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let item of newItems;let i = index">
            <td>{{i+1}}</td>           
            <td *ngFor="let list of item | keyvalue">
              <span  style="word-break: break-word;" >{{list.value}}</span>
              <!-- <img src="{{item.Image}}" height="42px" width="42px"> -->
            </td>
          </tr>
        </tbody>
      </table>
      </div>
  </div>
      <!-- <div class="d-flex justify-content-center">
      <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
    </div> -->
      
</div>