import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-harvested-data',
  templateUrl: './harvested-data.component.html',
  styleUrls: ['./harvested-data.component.scss']
})
export class HarvestedDataComponent implements OnInit {
  sub: any;
  new: any;
  newItems: any;
  head: string[];
  path: any;
  blob: Blob;
  downloadurl: string;
  url: any;

  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    @Inject("API_URL") public API_URL: any
  ) { }


  ngOnInit(): void {
    
    this.sub = this.activatedRoute.params.subscribe(params => {
      
      console.log("runn",params)
      this.new = JSON.parse(params['res'])
      console.log("eunning",this.new)
      
    });
    this.data.getExtractedData(this.new).subscribe(
      data=>{
      if(data.statusCode==200){
        let alertObj = {
          message:data.message,
          status: "success",
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,"success")
        console.log("newdata",data);
        this.newItems=data['data']
        console.log(this.newItems);
        
        this.head=Object.keys(this.newItems[0])
        console.log(this.head)
        
      }
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
    }
    )
  }
  download(){
    this.data.getextractedpath(this.new).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log("path",data)
          this.path=data['data']
          console.log(this.path)
          this.downloadurl=`${this.API_URL}/download/extractedFile?filePath=${this.path}`
          window.open(this.downloadurl)
        }


      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }
  imglabel(){
    this.spinner.show()
    this.router.navigate(['image-labelling',this.new])
  }

}
