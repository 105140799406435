 

<!-- <app-admin-topmenu step="4"></app-admin-topmenu> -->

<div class="container-fluid" style="margin-top: 0%;">
        <div class="row">
          
        <div class="card">
          
          <div class="card-body">
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="increaseSize($event)">
              <option [ngValue]="25">25 items per page</option>
              <option [ngValue]="50">50 items per page</option>
              <option [ngValue]="100">100 items per page</option>
            </select>
        
        
            <span class="table-add float-right mb-3 mr-2">
                <div class="d-flex justify-content-center " >
        
                    <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search by name" style="height: 32px;" id="search-input"
                         mdbInput>
                  
                  
                    <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
                    <button  style="margin:0px;" type="button" mdbBtn size="sm" color="dark"(click)="searchItems()" mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon></button>
                  
              </div>
            
              </span>
          <table class="table table-bordered table-striped text-center " style="table-layout: fixed;width:100%" mdbTable  #tableEl="mdbTable"  >
              <thead class="thead-dark sticky-top" >
                <tr>
                   <th style="width: 8%;">ID</th>    
                  <th style="width: 24%;">Name</th>
                  <th style="width: 30%;">DOC COUNT</th>
                  <!-- <th>Harvesting Page Url</th> -->
                  
                  <th class="th-xl" style="width: 30%;">Actions</th>
                </tr>
                  
              </thead>
              <tbody #row>
                <tr mdbTableCol *ngFor="let item of src;let id=index;">
                  <td  class="red-text">{{id+1}}</td>           
                  <td>{{item?.key}}</td>
                  <td >{{item?.doc_count}}</td>
                  <!-- <td>{{item.harvestingPageUrl}}</td> -->
                  
                  <td  style="display: contents;">
                    <span class="table-remove" >
                        <button type="button" mdbBtn color="primary" rounded="true" size="sm" mdbTooltip="Check all Entity" placement="down" mdbWavesEffect (click)="onClickSearch($event,item)" class="btn"  >Select</button>
                      <!-- <button type="button" mdbBtn color="indigo" rounded="true" size="sm"  mdbTooltip="Add Config" placement="down" mdbWavesEffect  (click)="onClick($event,content,item)" class="btn"  ><i class="fas fa-plus fa-lg"></i></button>

                      <button type="button" mdbBtn color="light" rounded="true" size="sm" mdbTooltip="Edit Data Source" placement="down" mdbWavesEffect (click)="onClickEdit($event,item)" class="btn"  ><i class="fas fa-edit fa-lg"></i></button>
                      <button type="button" mdbBtn color="info" rounded="true" size="sm"  mdbTooltip="Check Image Labelling" placement="down" mdbWavesEffect (click)="checkImage($event,item)" class="btn"  ><i class="fas fa-image fa-lg"></i></button>
                      <button type="button" mdbBtn color="deep-orange" rounded="true" size="sm" mdbTooltip="Check Extracted Data" placement="down" mdbWavesEffect (click)="checkExtractedData($event,item)" class="btn"  ><i class="fas fa-table fa-lg"></i></button>
                      <button type="button" mdbBtn color="amber" rounded="true" size="sm" mdbTooltip="Start Fresh Extraction" placement="down" mdbWavesEffect (click)="startExtraction($event,item,start)" class="btn"  >Extract</button>

                      <button type="button" mdbBtn color="success" rounded="true" size="sm" mdbTooltip="Download JSON" placement="down" mdbWavesEffect (click)="onClickDownload($event,item)" class="btn"  ><i class="fas fa-arrow-down fa-lg"></i></button>
                       -->
                      
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot class="grey lighten-5 w-100">
                <!-- <tr>
                  <td colspan="8">
                    
                    <pagination-controls [maxSize]="maxSize"
                      
                     [directionLinks]="directionLinks"
                    
                    [responsive]="responsive" 
                     
                    (pageChange)="pagec($event)"
                     ></pagination-controls>

                  </td>
                </tr> -->
                </tfoot>
            </table>
            </div>
        </div>
            <!-- <div class="d-flex justify-content-center">
            <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
          </div> -->
            
      </div>
      </div>