<!-- <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark unique-color" [containerInside]="false">

    
    <mdb-navbar-brand>
        <a class="navbar-brand" href="#">
        <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
</mdb-navbar-brand>
<ul class="navbar-nav ml-auto">
  <li class="nav-item">
    <button mdbBtn  color="success" rounded="true" size="sm"  [routerLink]="['/adminhome']" routerLinkActive="active" >{{roles.name}}</button>

    <button mdbBtn color="danger" rounded="true" size="sm"  (click)="logout()">Logout</button>
  </li>
</ul>
</mdb-navbar> -->
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 d-flex justify-content-center">
            <img src="assets/images/extraction.svg" style="margin-top: -80px;">
        </div>
        <div class="col-md-6 d-flex justify-content-center">
            <div class="card">
            <form #requirement="ngForm" (ngSubmit)="onSubmit(requirement)" class="text-center ">

                <p class="card-title ">Data Extraction Engine.</p>
            
                <!-- Email -->
                <div class="form-group">
                            
                    <label for="name">Source Name</label>
                    <input  class="form-control" required type="text" id="name" [(ngModel)]="req.name" name="req.name" placeholder="Enter the name of organisation. " >
                    
                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">name is required</div>
                  </div> -->
                  </div>
                  <div class="form-group">
                            
                    <label for="dataAgencyName">Agency Name</label>
                    <input  class="form-control" required type="text" id="name" [(ngModel)]="req.dataAgencyName" name="dataAgencyName" placeholder="Enter the name of Agency(ORG). " >
                    
                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">name is required</div>
                  </div> -->
                  </div>
                  <div class="form-group">
                    <label for="dataAgencyUrl">Source URL</label>
                    <input type="url" class="form-control" id="sourceUrl" [(ngModel)]="req.dataAgencyUrl" name="dataAgencyUrl"  placeholder="Enter the URL of the website." >
                    <!-- <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                      <div *ngIf="f.url.errors.required">url is required</div>
                    </div> -->
                  </div>
                  <div class="form-group">
                    <label for="harvestingPageUrl">Harvesting Page URL</label>
                    <input type="url" class="form-control" id="harvestingPageUrl" [(ngModel)]="req.harvestingPageUrl" name="req.harvestingPageUrl"  placeholder="Enter the URL of website." >
                    <!-- <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                      <div *ngIf="f.url.errors.required">url is required</div>
                    </div> -->
                  </div>
                  <div class="form-group">
                    <label for="pageLanguage">Language</label>
                    <input type="text" class="form-control" id="pageLanguage" [(ngModel)]="req.pageLanguage" name="req.pageLanguage"  placeholder="Enter the Language." >
                    <!-- <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                      <div *ngIf="f.url.errors.required">url is required</div>
                    </div> -->
                  </div>
                  <div class="form-group">
                    <label for="popupSelector">Header Label</label>
                    <input type="text" class="form-control" id="popupSelector" [(ngModel)]="req.popupSelector" name="req.popupSelector"  placeholder="Enter the Header Label." >
                    <!-- <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                      <div *ngIf="f.url.errors.required">url is required</div>
                    </div> -->
                  </div>
                  
                  <label for="inlinecheckbox">Does the page have Individual URL</label>

            <div class="form-group">
              
                <div class="form-check form-check-inline">
                    <input data-md-icheck  [(ngModel)]="req.isindividualLink" name="req.isindividualLink" class="form-check-input" type="radio" id="inlineCheckbox1" value="true" > 
                    <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
              
              
                <div class="form-check form-check-inline">
                      <input data-md-icheck [(ngModel)]="req.isindividualLink" name="req.isindividualLink" class="form-check-input" type="radio" id="inlineCheckbox2" value="false" >
                      <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
        
            </div>
            
                  <div class="form-group" *ngIf="req.isindividualLink=='true'" >
                      <label for="individualUrl">Individual URL</label>
                      <input type="text" class="form-control" id="Url" [(ngModel)]="req.individualUrl" name="req.individualUrl" placeholder="Enter the url from where you want to pick data" >
                      <!-- <div *ngIf="submitted && f.individualUrl.errors" class="invalid-feedback">
                        <div *ngIf="f.individualUrl.errors.required">url is required</div>
                      </div> -->
                    </div>
                  
                  
                
            
                <!--  button -->
                <button mdbBtn color="info" block="true" class="my-8" type="submit" [disabled]="requirement.invalid">Proceed</button>
            
                
                
            </form>
            </div>
            </div>
            </div>
            </div>
