import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'

@Component({
  selector: 'app-cleaners',
  templateUrl: './cleaners.component.html',
  styleUrls: ['./cleaners.component.scss']
})
export class CleanersComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  closeResult: string;
  new: { page: number; size: number; order: string; orderBy: string; };
  allcleaners: any;
  property: any;
  editcleaner: any;
  searchText: string = '';
  previous: string;
  elements: any = [];
  maxVisibleItems: number = 8;
  page:number=0;
  menuRoutes=[
    {route:"/attributes",name:"Attributes"},
    {route:"/cleaners",name:"Cleaners"},
    {route:"/dataconfigs",name:"Data Configs"},
    {route:"/client-registration",name:"Register User"},
    {route:"/erhome",name:"Resolve Entity"}
  ];
  selectedMenu:any=this.menuRoutes[1];
  constructor(
    private modalService: NgbModal,
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private cdRef: ChangeDetectorRef
  ) { }
  @HostListener('input') oninput() {
    this.searchItems();
}
  ngOnInit(){
    this.new={
      "page" : this.page,
      "size" : 2000,
      "order" : "ASC",
      "orderBy" : "createdDate"
    }
    this.spinner.show()
    this.data.getCleanersInfo(this.new).subscribe(
      data=>{
        console.log(data)
        this.spinner.hide()
        this.allcleaners=data['data']['content']
        console.log(this.allcleaners)
        this.mdbTable.setDataSource(this.allcleaners)
        this.elements=this.mdbTable.getDataSource()
        this.previous=this.mdbTable.getDataSource()
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
    this.data.getProperty(null).subscribe(
      data=>{
        console.log(data)
        this.property=data['data']
        console.log(this.property)
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  public createForm: FormGroup= new FormGroup({
    name:new FormControl ('', Validators.required),
    attribute:new FormControl ('', Validators.required),
    shortDescription:new FormControl ('', Validators.required),
    absolutePath:new FormControl ('', Validators.required),
    isActive:new FormControl ('', Validators.required),
    inputArg:new FormControl ('', Validators.required),
    returnType:new FormControl ('', Validators.required),
    description:new FormControl ('', Validators.required),
    example:new FormControl ('', Validators.required),
  })
  
  onSubmitnew(){
    console.log(this.createForm.value)
    this.spinner.show()
    this.data.createCleaner(this.createForm.value).subscribe(
      data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll()
          this.createForm.reset()
          this.spinner.hide()
          console.log(data)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 3000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        this.spinner.hide()
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
        
      }
    )
  }
  onClick(event,contentone,item){
   

    
    let name = item.name
  
    console.log(name); 
    this.data.getCleanerByName(name).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data)

          this.editcleaner=data['data']
          this.modalService.open(contentone, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  onUpdate(){
    console.log(this.editcleaner)
    this.spinner.show()
    this.data.updateCleaner(this.editcleaner).subscribe(
      data=>{
        if(data.statusCode==200){
          this.spinner.hide()
          this.modalService.dismissAll()
          console.log(data)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 3000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
        this.mdbTable.setDataSource(this.previous);
        this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
        this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
        this.mdbTable.setDataSource(prev);
    }
  }
  // goToMenu(){
  //   //  console.log("menu value: ",this.selectedMenu);
  //    this.router.navigate([this.selectedMenu.route])
  //  }
  logout(){
    this.auth.logout();
  }
  // goback(){
  //   this.location.back();
  // }
}
