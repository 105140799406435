import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import { HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import{Location} from '@angular/common';
@Component({
  selector: 'app-selectors',
  templateUrl: './selectors.component.html',
  styleUrls: ['./selectors.component.scss']
})
export class SelectorsComponent implements OnInit {
  url: any;
  newurl: any;
  selectors: any;
  ext: any;
  a: any;
  editField: string;
  property:string;
  cssSelector:string;
  extractionInfo:string;
  
  personList:  Array<any>=[]
  options = [
    { value: '1', label: 'TEXT' },
    { value: '2', label: 'IMAGE' },
    { value: '3', label: 'URL' },
  ];

  awaitingPersonList:  Array<any> = [
    
    
  ];
  newdata: any;
  modalsNumber: any;
  mainurl: Params;
  individualUrl: any;
  n: any;
  verify: { property: any; cssSelector: any; extractionInfo: any; individualUrl: string; harvestingLinkUrl:any};
  propval: any;
  newval:{ property: any; cssSelector: any; extractionInfo: any; individualUrl: any;value:any; };
  checkid: any;
  displayUrl: any;
  indurl: boolean;
  harvesturl: any;
  extractedData:any;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private spinner :NgxSpinnerService,
    private auth:AuthService,
    private location:Location
    
  ) { 
    
    
  }

  ngOnInit() {
    this.url = this.activatedRoute.params.subscribe(params => {
      
      console.log("runn",params)
      this.mainurl=params
      this.newurl = JSON.parse(params['queryParams'])
      console.log(this.newurl);
      
  });
  this.data.getSelectors(this.newurl).subscribe(
    data=>{
        
        if(data.statusCode==200){
          let alertObj = {
            message: data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 10000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
          console.log(alertObj)
 
          console.log('gotit',data)
          if(data.data.individualUrl==null){
            this.displayUrl=data['data']['harvestingPageUrl']
            
            this.indurl=true
            this.harvesturl=this.displayUrl
          }
         else if(data.data.individualUrl!==null) {
            this.displayUrl=data.data.individualUrl
            this.indurl=false
            this.individualUrl=this.displayUrl
            console.log(this.displayUrl)
          }
          this.selectors=data['data']['selectors']['selectorResponses']
          this.ext=this.selectors
          this.personList=this.selectors
          console.log(this.personList)
    }
  },
  (err:HttpErrorResponse)=>{
    console.log(err)
    let alertObj = {
      message: err.error.message,
      status: err.error.infoType,
      autoDismiss: true,
      timeToDismiss: 10000
    };
    this.data.toastrShow(alertObj.message,alertObj.status)

  }
  )
  this.data.getProperty(null).subscribe(
    data=>{
      console.log(data)
      this.property=data['data']
      console.log(this.property)
    }
  )
  

  } 
  
  remove(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.personList.splice(id, 1);
  }

  add() {
    this.personList.push({property:'',cssSelector:'',extractionInfo:'',value:''})
    console.log(this.personList)
      
  }
  check(event,id){
    
    this.data.dataExtraction.subscribe(val=>{
      this.extractedData=val;
    })
    console.log("datas",this.extractedData)
    this.verify={"property":event.property,"cssSelector":event.cssSelector,"extractionInfo":event.extractionInfo,"individualUrl":this.extractedData.indivisual,"harvestingLinkUrl":this.extractedData.harvest}
    
    console.log("values",this.verify)
    // this.checkid=id
    this.spinner.show()
    this.data.verifySelector(this.verify).subscribe(
      data=>{
        
        this.propval=data['data']
        this.newval={"property":event.property,"cssSelector":event.cssSelector,"extractionInfo":event.extractionInfo,"individualUrl":this.individualUrl,"value":this.propval}
        let index = this.personList.find(item=>item.property==event.property).value=this.propval;
        
        this.spinner.hide()
        // this.personList.find(item=>item.id===id).value = this.propval
        // console.log(this.newval)
        
        console.log(this.propval)
      
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )

    
    
  }

  
  onSubmit(){
    console.log(this.personList)
    this.newdata=this.personList
    console.log(this.newdata)
    
    this.data.updateSelectors(this.newdata,this.newurl).subscribe(
      data=>{
        console.log(data.statusCode==200)
        this.spinner.hide();
        if (data.statusCode==200) {
          
          let alertObj = {
            message: data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 10000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
          
          console.log(data);
          
          this.router.navigate(['/page-details',this.mainurl])
        }
        
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 10000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
      );
      

      
          
  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back()
  }

  
  

}
