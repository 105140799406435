
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient, @Inject("API_URL") public API_URL: any,@Inject("API_DET_PYTHON") public API_DET_PYTHON:any, @Inject("API_URL_DET") public API_URL_DET: any) { }

  public job = new BehaviorSubject<any>('');
  jobVal(value) {
    this.job.next(value);
  }
  getNotification(payload: Object) {
    return this.http.post(`${this.API_URL}/dashboard/v1/notifications/filter`, payload).pipe(map((res: any) => res.data));
  }

  
  
  markAllRead(){
    return this.http.put(`${this.API_URL}/dashboard/v1/notifications/mark-all-read`, null);
  }
  markReadById(id){
    return this.http.put(`${this.API_URL}/dashboard/v1/notifications/mark-read?id=${id}`, null);
  }
  createDs(id){
    return this.http.post(`${this.API_URL}/dashboard/v1/bulk-upload/create?listId=${id}`,null);
  }
  updateList(payload){
    return this.http.put(`${this.API_URL}/dashboard/v1/bulk-upload/update-list`, payload);
  }
}
