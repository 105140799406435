import { Component, OnInit, Inject, AfterViewInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'
@Component({
  selector: 'app-adminhome',
  templateUrl: './adminhome.component.html',
  styleUrls: ['./adminhome.component.scss']
})
export class AdminhomeComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  list:  Array<any>=[]
  dataSources: any;
  sourcedata: any;
  path: any;
  downloadurl: string;
  closeResult: string;
  allAttributes: any;
  newdata: any[];
  selectedItems: Array<any> = [];
  dropdownSettings: any = {};
  dropdownList: Array<any> = [];
  cleaners: any[];
  config:{"baseImgUrl":string,"employer":string,"category":string,"riskLevel":string,"entityType":string}
  allCategory: any;
  createForm:FormGroup;
  searchText: string = '';
  previous: string;
  elements: any = [];
  maxVisibleItems: number = 8;
  extId: string;
  page:number=0;
  pagi: { page: number; size: number;order:string;orderBy:string;};
  total: any;
  pagin: { page: number; size: number;order:string;orderBy:string; };
  updatedData: any;
  loading: boolean;
  pageSize:number=25
  eventLog: any;
  sideMenu:any=[
    {
      title:"Home",
      icon:"fa fa-home",
      route:"/adminhome"
    }
  ];

  public directionLinks: boolean = true;
    public autoHide: boolean = false;
    public responsive: boolean = false;
    public maxSize: number = 9;
  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private spinner:NgxSpinnerService,
    private modalService:NgbModal,
    private fb:FormBuilder,
    @Inject("API_URL") public API_URL: any,
    private cdRef: ChangeDetectorRef
  ) { }
  
  ngOnInit(): void {
    this.getAllData();
    this.getProperties();

    this.getCategory();
    
    this.createForm=this.fb.group({
      baseImgUrl:[null],
      employer:[null],
      entityType:[null],
      riskLevel:[null],
      category:[null],
      nationality:[null],
      summary:[null]

    })
   console.log(this.dropdownList)
  //  this.selectedItems = []

    
  this.dropdownSettings = {
    singleSelection: false,
    idField: 'absolutePath',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 300,
    
    
  };
  }
  getAllData(page=this.page,size=this.pageSize,name=this.searchText){
    let new2={"page":page,"size":size,"order":'ASC',"orderBy":'lastModifiedDate',"name":name}
    this.spinner.show()
    this.loading=true
    this.data.searchSourceAdmin(new2).subscribe(
      data=>{
        console.log(data)
        this.updatedData=data['data']['content']
        this.dataSources=this.updatedData
        this.loading=false
       
        this.total=data['data'].totalElements
        this.page=data['data']['pageable'].pageNumber
        this.spinner.hide()
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )


  }
pagec(event){
 
let abc=(event-1)
this.getAllData(abc,this.pageSize,this.searchText)
// this.pagin={"page":abc,"size":this.pageSize,"order":'ASC',"orderBy":'lastModifiedDate'}
// this.spinner.show()
// this.data.searchSourceAdmin(this.pagin).subscribe(
//   data=>{
//     console.log(data)
//     let abc=data['data']['content']
//     this.dataSources=abc
//     // this.total=data['data'].totalElements
//     this.page=data['data']['pageable'].pageNumber
//     this.spinner.hide()
   
//   }
// )
  }
  increaseSize(event){
    this.getAllData(this.page,event,this.searchText)
//     
  }
  getProperties(){
    
       this.data.getProperty(null).subscribe(
      data=>{
        console.log(data)
        this.allAttributes=data['data']
        console.log(this.allAttributes)
      },
      (err:HttpErrorResponse)=>{
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  getCategory(){
    this.data.getCategory().subscribe(
      data=>{
        console.log(data)
        this.allCategory=data['data']
        
      },
      (err:HttpErrorResponse)=>{
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }

  onItemChange(item:any){
    console.log(item)
    this.spinner.show()
    this.data.getCleanersInfoByAttribute(item).subscribe(
      data=>{
        this.spinner.hide()
        this.cleaners=data['data']
        let abc=this.cleaners.map((o)=>o.absolutePath)
        // this.cleaners=data.data.absolutePath
        console.log(abc)
        console.log(this.cleaners)
        this.dropdownList=abc
      },
      (err:HttpErrorResponse)=>{
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )

  }
  onItemSelect(item: any) {
    console.log(item);

  }
  onSelectAll(items: any) {
    console.log(items);
  }
  searchItems() {
    this.getAllData(this.page,this.pageSize,this.searchText);
    // let sh={"page":this.page,"size":this.pageSize,"order":'ASC',"name":this.searchText}
    // this.data.searchSourceAdmin(sh).subscribe(
    //   data=>{
    //     console.log(data)
    //     this.updatedData=data['data']['content']
    //     this.dataSources=this.updatedData
        
    //     this.total=data['data'].totalElements
    //   }
    // )
    
}
  
  onClick(event,content,item){
    
    let datasrc= item
    
    console.log(datasrc); 
    this.sourcedata=datasrc
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'xl' ,backdrop:'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  remove(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.list.splice(id, 1);
  }

  add() {
    this.list.push({name:'',cleaner:[]})
    console.log(this.list)
      
  }
  onSubmit(){
    console.log(this.createForm.controls.baseImgUrl.value)
    // console.log(this.list)
    // this.newdata=this.list
    // console.log(this.newdata)
    let details={"dataSource":this.sourcedata,"config":{"baseImgUrl":this.createForm.controls.baseImgUrl.value,"employer":this.createForm.controls.employer.value,"category":this.createForm.controls.category.value,"entityType":this.createForm.controls.entityType.value,"riskLevel":this.createForm.controls.riskLevel.value,"nationality":this.createForm.controls.nationality.value,"summary":this.createForm.controls.summary.value,"properties":this.list}}
    console.log(details)
    this.spinner.show()
    this.data.createConfig(details).subscribe(
      data=>{
        if(data.statusCode==200){
          this.spinner.hide()
          console.log(data)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 3000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        } 
      },
      (err:HttpErrorResponse)=>{
        this.spinner.hide()
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
    
  }
  
  onClickEdit(event,item){
    console.log(item)
    let id = item.amlDataSourceId
  
    console.log(id); 
    this.router.navigate(['/editsource',id])
    
  }

  onClickDownload(event,item){
    
    let id = item.amlDataSourceId
  
    console.log(id); 
    this.data.getextractedpath(id).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log("path",data)
          this.path=data['data']
          console.log(this.path)
          this.downloadurl=`${this.API_URL}/download/extractedFile?filePath=${this.path}`
          window.open(this.downloadurl)
        }


      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  checkImage(event,item){
    console.log(item)
    let id =JSON.stringify(item.amlDataSourceId)
    console.log(id)
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/image-labelling/${id}`])
    );
  
    window.open(url, '_blank');
    // this.router.navigate(['/image-labelling',id])
  }
  checkExtractedData(event,item){
    console.log(item)
    console.log(item)
    let id =JSON.stringify(item.amlDataSourceId)
    this.router.navigate(['/harvested-data',id])
  }
  startExtraction(event,item,start){
    this.extId=JSON.stringify(item.amlDataSourceId)
    this.modalService.open(start, {ariaLabelledBy: 'modal-basic-title',size: 'sm' ,backdrop:'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  extractData(){
   
    this.modalService.dismissAll()
    this.spinner.show()
 
    this.data.postExtraction(this.extId).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log('working',data);
          this.spinner.hide()
          let alertObj = {
            message: data.message,
            status: 'SUCCESS',
            autoDismiss: true,
            timeToDismiss: 10000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
         
          
          
        }
      },
      (err:HttpErrorResponse)=>{
        this.spinner.hide()
        console.log(err)
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 10000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
     
 }

 
  logout(){
    this.auth.logout();
  }
  
}
