import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import{Location} from '@angular/common'
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-ds',
  templateUrl: './create-ds.component.html',
  styleUrls: ['./create-ds.component.scss']
})
export class CreateDsComponent implements OnInit {

  createDsForm: FormGroup = this.fb.group({
    name:[''],
    dataAgencyName:[''],
    dataAgencyUrl:[''],
    harvestingPageUrl:[''],
    crawlType:[''],
    country:['']
  });
  error="";
  loading: boolean;
  constructor(
    private fb:FormBuilder,
    private auth:AuthService,
    private location:Location,
    private data:DataService,
    private router:Router,
    private spinner:NgxSpinnerService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){

    this.spinner.show();
    let payload={
      name:this.createDsForm.value['name'],
      dataAgencyName:this.createDsForm.value['dataAgencyName'],
      dataAgencyUrl:this.createDsForm.value['dataAgencyUrl'],
      harvestingPageUrl:this.createDsForm.value['harvestingPageUrl'],
      crawlType:this.createDsForm.value['crawlType'],
      country:this.createDsForm.value['country']
    }
    this.data.createDs(payload).subscribe(result=>{

      this.spinner.hide();
      let alertObj = {
        message:result.message,
        status: result.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
      if(result.statusCode==200){
        let extra:NavigationExtras={
          relativeTo:this.route,
          state:result
        }
        this.router.navigate(['/create-datasource-summary'],extra)
      }
    })
    // console.log("payload: ",payload);
  }

  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }
}
