
<a mdbBtn floating="true" (click)="goback()"   ><mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>

<div class="col-sm-2 col-md-2 col-lg-2  " id="mydiv" style="position: fixed;">
  <div class="card ">
      
      <select (change)="shapeChanged($event)" [(ngModel)]="createdShape" size="5">
        <option *ngFor="let shape of shapesToDraw;let i=index" [ngValue]="shape"
          >W:{{shape.width|json}},H:{{shape.height|json}},Name:{{shape.name|json}},</option>
          <option [ngValue]="undefined">Unselect</option>

      </select>

      <div>
        <label>Delete by Name</label>
        <button *ngFor="let item of shapesToDraw;let i=index" class="btn btn-outline-danger" (click)="delete(i)"  >{{item.name}}</button>
      </div>
     
      <div class="mt-3 mb-3">
          <label>Name:</label> 
          <input name="createdShape.name" [ngModel]="createdShape?.name" (ngModelChange)="createdShape.name = $event" placeholder="Enter label name." style="width:100%;">
          
      </div>
      <h5>"Click submit only after labelling the images."</h5>
      
      <button class="btn btn-primary" (click)="open(content)" >Submit</button>
  </div>

  
  
       
      

</div>

<!-- <img src="{{imagenew}}" height="auto" width="auto"> -->
<svg class="shapeCanvas" 

(mousedown)="startDrawing($event)" 
(mousemove)="keepDrawing($event)"
(mouseup)="stopDrawing($event)"

[attr.height]="imgheight"
[attr.width]="imgwidth"
id="new"

>
<image [attr.href]="imagenew" />

<ng-container  *ngFor="let shape of shapesToDraw"  >
<rect class="shape"

  [style.stroke-width]="shape==(currentShape|async)?2:1" 

  [attr.x]="shape.xCordinates" 

  [attr.y]="shape.yCordinates" 

  [attr.width]="shape.width"       

  [attr.height]="shape.height"/>
  
</ng-container>
  
  <g >
      <text contentEditable="true" style="font-size: 40px;">foo</text>
  </g>
          
    
  

  

</svg>

<ng-template #content let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title">Please </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure want to save the labels ?&hellip;</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="modal.close && onSubmit()">Confirm</button>
  </div>
</ng-template>

  