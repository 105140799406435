import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkTrackerRoutingModule } from './work-tracker-routing.module';
import { WorkTrackerComponent } from './work-tracker.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DetailsComponent } from './details/details.component';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    WorkTrackerComponent,
    DetailsComponent
  ],
  imports: [
    CommonModule,
    WorkTrackerRoutingModule,
    ReactiveFormsModule,
    ChartsModule,
    NgxPaginationModule
  ]
})
export class WorkTrackerModule { }
