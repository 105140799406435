export class filterArray {
  key: string;
  value: string;
}
export class roleArray {
  key: number;
  value: string;
}
import { Component, OnInit, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { EmailValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MdbTableDirective, MdbTablePaginationComponent, TableModule } from 'angular-bootstrap-md';
import { DataService } from 'src/app/services/data.service';
import { ManageTeamService } from '../services/manage-team.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-team',
  templateUrl: './manage-team.component.html',
  styleUrls: ['./manage-team.component.scss']
})
export class ManageTeamComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  peopleTypeahead = new EventEmitter<string>();
  filterList: filterArray[] = [
    { key: 'ACTIVE', value: 'ACTIVE' },
    { key: 'INACTIVE', value: 'INACTIVE' }
  ];
  roleArray: roleArray[] = [
    // { key: 351, value: 'Admin' },
    { key: 347, value: 'Extractor' },
    { key: 348, value: 'Transformer' },
    { key: 349, value: 'QA' },
    { key: 350, value: 'Processor' },
    // { key: 451, value: 'Create a New Process' }
  ];
  roles: any = []
  newMemberRole: any = [
    { value: 337, display: 'Super Admin', slug: 'super-admin' },
    { value: 338, display: 'Admin', slug: 'admin' },
    { value: 339, display: 'Extractor', slug: 'extractor' },
    { value: 340, display: 'Transformer', slug: 'transformer' },
    { value: 341, display: 'QA', slug: 'qa' },
    { value: 342, display: 'Processor', slug: 'processor' },
  ];
  item = [];
  closeResult: string = '';
  modalType: any;
  page: number = 0;
  pageSize = 20;
  total: number;
  tableData: any[];
  public directionLinks: boolean = true;
  public responsive: boolean = false;
  newRoleForm: FormGroup = this.formBuilder.group({
    name: [''],
    color: [''],
    role: ['']
  });
  newMemberForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    teamId: ['default', Validators.required]
  });
  editMemberForm: FormGroup = this.formBuilder.group({
    id:[''],
    email: [''],
    teamId: [''],
    isActive: ['']
  })
  newProcessForm: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    teamId: [null, Validators.required]
  });
  newTeamForm: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    status: ['ACTIVE', Validators.required]
  });
  array: any[] = [];
  newArray: any[][] = [['super admin']]
  showDropDown: boolean = false;
  processID: any;
  processTeamID: any;
  processStatus: any;
  teamCountData: any[];
  roleAddedArray: any[] = []
  isFilter: boolean = false;
  filterStatus: any;
  teamStatus: string = 'all';
  allowedSections: any = []
  editableArr: any = []
  dropdownSettings = {
    singleSelection: true,
    idField: 'absolutePath',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 300,
  };
  isLoading = false;
  emailArr = [];
  emailQuerySelect: string = ""
  emailSelectedForNewMember: string
  rolesArrForNewMember: [{}]
  isTeamIdInputDisable: boolean = false
  lockTeamSelect: boolean = true
  teamsArr: any = []
  rolesAddedForEditMember: any[] = ['extractor', 'transformer']
  roleArrForEditMember: any[] = []
  teamUserToDeleteID: any
  processFlowToTerminate: any
  constructor(private modalService: NgbModal, private route: Router, private formBuilder: FormBuilder, private dataService: DataService, private manageTeamServices: ManageTeamService) { }

  ngOnInit(): void {
    this.dataService.getRestrictedSection('manage team').subscribe(res => {
      for (let section of res.data) {
        this.allowedSections.push(section.slug)
      }
    })
    let roles = this.dataService.getUserRole();
    let role = roles[0].name;
    if (role == 'SUPER ADMIN' || role == 'ADMIN') {
      this.filterAPIForProcessFlow();
      this.filterUser();
      this.teamCount();
    }
    else {
      this.route.navigateByUrl('dashboard');
    }
    this.getEmailList();
    this.getTeamList();
  }

  teamCount() {
    this.manageTeamServices.teamCount().subscribe((res) => this.teamCountData = res);
  }

  getEmailList() {

    this.peopleTypeahead.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(term => this.manageTeamServices.getEmailListByName({
        "page": 0,
        "size": 10,
        "orderBy": "createdDate",
        "order": "ASC",
        "email": term
      }))
    ).subscribe(x => {
      this.emailArr = x.content;
      console.log('arr email', this.emailArr)
    }, (err) => {
      console.log(err);
      this.emailArr = [];
    });

  }
  getTeamList() {
    this.manageTeamServices.fetchAllTeams().subscribe((res) => {
      this.teamsArr = [...res]
    })
  }
  filterAPIForProcessFlow() {
    const payload = {
      "page": this.page,
      "size": this.pageSize,
      "orderBy": "createdDate",
      "order": "DESC",
      "status": "ACTIVE"
    }
    this.manageTeamServices.filterAPIForProcessFlow(payload).subscribe((res) => {
    this.newArray = res.content.map(c=>({...c,processFlow:JSON.parse(c.sqnProcessFlow|| "").map(p=>({...c.processFlow.find(f=>f.id===p.id)}))}))    
      this.total = res.totalElements;
    });
  }
  handleEditable(a: { target: { checked: any; }; }, id: any) {
    if (a.target.checked) {
      this.editableArr.push(id)
    }
    else {
      const i = this.editableArr.indexOf(id)
      if (i !== -1) {
        this.editableArr.splice(i, 1)
      }

    }
  }
  removeProcessStep(index, data) {
    this.showDropDown = false;
    const processData = [...data.processFlow.map(obj => ({ id: obj.id }))]
    const remElem = processData.splice(index, 1)
    const payload = {
      "id": data.id,
      "name": data.name,
      "description": data.description,
      "status": data.status,
      "processFlow": [

        ...processData
      ],
      "team": {
        "id": data.team.id
      }
    }
    this.manageTeamServices.updateProcessFlow(payload).subscribe(() => {
      this.filterAPIForProcessFlow()
      this.editableArr = []
    });


  }
  filterUser() {
    const payload = {
      "page": this.page,
      "size": this.pageSize,
      "orderBy": "createdDate",
      "order": "DESC",
    }
    this.manageTeamServices.filterUser(payload).subscribe((res) => {
      this.tableData = res.content;
      this.total = res.totalElements;
    })
  }

  filterByStatus(status) {
    this.filterStatus = status;
    this.isFilter = true;
    const payload = {
      "page": 0,
      "size": 10,
      "orderBy": "createdDate",
      "order": "DESC",
      "status": status,
      'teamId': this.teamStatus === 'all' ? null : parseInt(this.teamStatus)
    }
    this.manageTeamServices.filterUser(payload).subscribe((res) => {
      this.tableData = res.content
    })
  }
  filterByTeam(status) {
    this.teamStatus = status;
    this.isFilter = true;
    const payload = {
      "page": 0,
      "size": 10,
      "orderBy": "createdDate",
      "order": "DESC",
      "status": this.filterStatus,
      'teamId': status == 'all' ? null : parseInt(status)
    }
    this.manageTeamServices.filterUser(payload).subscribe((res) => {
      this.tableData = res.content
    })
  }
  openTerminateProcessFlowDialog(template: TemplateRef<any>, data = {}) {
    this.processFlowToTerminate = data
    this.modalService.open(template, { windowClass: 'dialog-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openDialogOneArg(template: TemplateRef<any>) {
    this.newMemberForm.get('email').setValue(null);
    this.newMemberForm.get('teamId').setValue(null);
    this.modalService.open(template, { windowClass: 'dialog-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openEditMemberDialog(template: TemplateRef<any>, data) {
    this.editMemberForm.patchValue({
      id:data?.id,
      email: data?.teamUser?.email,
      teamId: data?.team?.id,
      isActive: data?.teamUser?.isActive === "ACTIVE" ? true : false
    })

    if (data.roles.map(obj => obj.slug).indexOf('super-admin') !== -1) {
      this.roleArrForEditMember = [{ id: 337, slug: 'super-admin', name: 'SUPER ADMIN' }]
    }
    else if (data.roles.map(obj => obj.slug).indexOf('admin') !== -1) {
      this.roleArrForEditMember = [{ id: 338, slug: 'admin', name: 'ADMIN' }]
    }
    else {
      this.roleArrForEditMember = [{ id: 339, slug: 'extractor', name: 'EXTRACTOR' },
      { id: 340, slug: 'transformer', name: 'TRANSFORMER' },
      { id: 341, slug: 'qa', name: 'QA' },
      { id: 342, slug: 'processor', name: 'PROCESSOR' }]
    }

    this.rolesAddedForEditMember = data.roles.map(e => e.slug)
    console.log('roled added arr', this.rolesAddedForEditMember)
    this.modalService.open(template, { windowClass: 'dialog-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    console.log('edit data--', data, this.editMemberForm)
  }
  openDeleteMemberDialog(template: TemplateRef<any>, data) {
    this.teamUserToDeleteID = data.id
    this.modalService.open(template, { windowClass: 'dialog-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addDropDown(id, teamId, status) {
    console.log('adde new', id, teamId, status)
    this.showDropDown = true
    this.processID = id;
    this.processTeamID = teamId;
    this.processStatus = status;
    let popup = {
      message: 'Please select any Role or create a new role'
    }
    this.dataService.toastrShow(popup.message, 'success');
  }

  addNewRole(e, newRole, data) {
    this.showDropDown = false;
    const processFlow = [...data.processFlow]
    if (parseInt(e) !== 451) {
      const payload = {
        "id": data.id,
        "name": data.name,
        "description": data.description,
        "status": data.status,
        "processFlow": [...processFlow.map(obj => ({ id: obj.id })), { id: e }],
        "team": {
          "id": data.team.id
        },
        "terminated":data.terminated
      }
      this.manageTeamServices.updateProcessFlow(payload).subscribe(() => {
        this.filterAPIForProcessFlow()
        this.editableArr = []
      });
    }
    else {
      this.modalService.open(newRole, { windowClass: 'dialog-class' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  handleEmailSelectForAddMember(e) {
    const addtionalRoleOptions = [
      { id: 339, slug: 'extractor', name: 'Extractor' },
      { id: 340, slug: 'transformer', name: 'Transformer' },
      { id: 341, slug: 'qa', name: 'QA' },
      { id: 342, slug: 'processor', name: 'Processor' }
    ]
    const dataEmail = e
    if (dataEmail.roles.map(obj => obj.slug).indexOf("super-admin") !== -1) {
      this.isTeamIdInputDisable = true
    }
    if (dataEmail.roles.map(obj => obj.slug).indexOf("super-admin") === -1 && dataEmail.roles.map(obj => obj.slug).indexOf("admin") === -1) {
      if (dataEmail.roles.length === 0) {
        dataEmail.roles = [
          { id: 337, slug: 'super-admin', name: 'Super Admin' },
          { id: 338, slug: 'admin', name: 'Admin' }, ...addtionalRoleOptions]
      }
      else {
        dataEmail.roles = [...addtionalRoleOptions]
      }
    }
    this.roles = dataEmail.roles
    this.clearRoleData()
    this.lockTeamSelect = false
  }
  clearAddMemberData() {
    this.roles = []
    this.isTeamIdInputDisable = false
    this.emailSelectedForNewMember = null
    this.clearRoleData()
    this.lockTeamSelect = true
    this.newMemberForm.reset()
  }
  clearRoleData() {
    this.roleAddedArray = []
  }
  clearEmailData() {
    this.clearAddMemberData()
  }
  handleRoleAdd(e) {
    if (e.slug === 'super-admin') {
      this.roles = [{ id: 337, slug: 'super-admin', name: 'Super Admin' }]
    }
    else if (e.slug === 'admin') {
      this.roles = [{ id: 338, slug: 'admin', name: 'Admin' }]
    }
    else {
      this.roles = [
        { id: 339, slug: 'extractor', name: 'Extractor' },
        { id: 340, slug: 'transformer', name: 'Transformer' },
        { id: 341, slug: 'qa', name: 'QA' },
        { id: 342, slug: 'processor', name: 'Processor' }
      ]
    }
  }
  addNewProcessFlow() {
    const payload = {
      "name": this.newProcessForm.value.name,
      "description": this.newProcessForm.value.description,
      "status": "ACTIVE",
      "processFlow": [
        {
          "id": 347
        }
      ],
      "team": {
        "id": this.newProcessForm.value.teamId
      },
      "terminated":false
    }
    this.manageTeamServices.createProcessFlow(payload).subscribe(() => {
      this.filterAPIForProcessFlow()
      this.editableArr = []
    });
  }
  handleTerminateProcessFlow() {
    const payload = {
      ...this.processFlowToTerminate,
      terminated: true,
    }
    this.manageTeamServices.updateProcessFlow(payload).subscribe(() => {
      this.filterAPIForProcessFlow()
      this.editableArr = []
    });
  }

  addNewMember() {
    const rolesArr = this.roleAddedArray.map((slug) => ({ id: this.newMemberRole.find(obj => obj.slug === slug).value }))
    const payload = {
      "teamUser":
      {
        "email": this.newMemberForm.value.email
      }
      ,
      "roles": rolesArr,
      "team": {
        "id": this.newMemberForm.value.teamId
      },
      "status": "ACTIVE"
    }
    let popup = {
      message: 'New Member Added Successfully',
      status: "SUCCESS",
      autoDismiss: true,
      timeToDismiss: 5000
    }
    this.manageTeamServices.addNewTeamMember(payload).subscribe(() => {
      this.dataService.toastrShow(popup.message, popup.status);
      this.filterAPIForProcessFlow();
      this.filterUser();
      this.teamCount();
    });
  }
  handleUpdateMember() {

    const rolesArr = this.rolesAddedForEditMember.map((slug) => ({ id: this.newMemberRole.find(obj => obj.slug === slug).value }))
    const payload = {
      "id": this.editMemberForm.value.id,
      "teamUser": {
        "email": this.editMemberForm.value.email
      },
      "roles": rolesArr,
      "team": {
        "id": this.editMemberForm.value.teamId
      },
      "status": this.editMemberForm.value.isActive == true ? "ACTIVE" : "INACTIVE"
    }
    let popup = {
      message: 'Member Updated Successfully',
      status: "SUCCESS",
      autoDismiss: true,
      timeToDismiss: 5000
    }
    this.manageTeamServices.updateTeamMember(payload).subscribe(() => {
      this.dataService.toastrShow(popup.message, popup.status);
      this.filterUser();
    });
  }

  handleDeleteTeamMember() {
    const payload = { id: this.teamUserToDeleteID }
    this.manageTeamServices.deleteTeamUser(payload).subscribe((res) => {
      this.filterAPIForProcessFlow();
      this.filterUser();
      this.teamCount();
    })
  }
  handleAddNewTeam(){
    const payload = {
      ...this.newTeamForm.value
    }
    this.manageTeamServices.createTeam(payload).subscribe((res) =>{
      console.log('add team res',res)
      this.filterAPIForProcessFlow();
      this.filterUser();
      this.teamCount();
    })
  }
  pagec(event) {
    this.page = event - 1;
    if (this.isFilter) {
      this.filterByStatus(this.filterStatus);
    } else {
      this.filterUser();
    }
  }
}
