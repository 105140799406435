import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import {DataService} from '../../services/data.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss']
})
export class PageDetailsComponent implements OnInit {
  sub: any;
  new: any;
  res: { url: any; };
  model ={pagination:'',paginationType:'',isNextButton:'',noOfPages:'',harvestingPageUrl:'',nextPageSelector:'',havestingLinksSelector:'',isTable:''}
  pagination2={theCheckbox:'',inputSelectors:''}
  mainurl: Params;
  pageination: boolean=true;
  pageinationtype: boolean=true;
  nextbtn: boolean=true;
  abc: { pagination: string; paginationType: string; isNextButton: boolean; noOfPages: number; harvestingPageUrl: string; nextPageSelector: string; havestingLinksSelector: string; isTable: string; };
  constructor(
    private activatedRoute:ActivatedRoute,
    private data:DataService,
    private modalService: NgbModal,
    private spinner:NgxSpinnerService,
    private router:Router,
    private auth:AuthService,
    private location:Location,
  ) { }

  ngOnInit(): void {
    // this.sub = this.activatedRoute.snapshot.paramMap.get('res')
    //   console.log(this.sub)
      this.sub = this.activatedRoute.params.subscribe(params => {
        
        console.log("runn",params)
        this.new = JSON.parse(params['queryParams'])
        console.log("eunning",this.new)
        
      });
    
  }
  onSubmit(content){
    console.log(parseFloat(this.model.noOfPages))
   this.abc ={pagination:this.model.pagination,paginationType:this.model.paginationType,isNextButton:this.nextbtn,noOfPages:parseFloat(this.model.noOfPages),harvestingPageUrl:this.model.harvestingPageUrl,nextPageSelector:this.model.nextPageSelector,havestingLinksSelector:this.model.havestingLinksSelector,isTable:this.model.isTable}
    console.log(this.abc)
    
    this.data.putPagination(this.abc,this.new).subscribe(
      data=>{
        if(data.statusCode==200){
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
          console.log(data)
          this.modalService.open(content,{centered:true})
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
      );
  }
  extractData(){
   
    this.modalService.dismissAll()
    this.spinner.show(undefined, {
      type: "square-loader",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 1)",
      color: "white",
      fullScreen: true
    });
 
    this.data.postExtraction(this.new).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log('working',data);
          this.spinner.hide()
          this.router.navigate(['/harvested-data',this.new])
          console.log(this.new);
          
          
        }
      },
      (err:HttpErrorResponse)=>{
        this.spinner.hide()
        console.log(err)
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 10000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
     
 }
 logout(){
   this.auth.logout();
 }
 goback(){
  this.location.back();
}
page(e){
  console.log(e)
  
  if(e=='Yes'){
    this.pageination=true
  }
  else{
    this.pageination=false
  }
  
}
pagetype(ee){
  console.log(ee)
  if(ee=='next page button'){
    this.pageinationtype=true
  }
  else {
    this.pageinationtype=false
  }
}
nxtbtn(eee){
console.log(eee)
if(eee=='yes'){
  this.nextbtn=true
}
else{
  this.nextbtn=false
}
}
}

