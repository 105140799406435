<div class="content">
    <div class="row">
        <div class="col-12">
            <h5 class="new"><a>
                <i class="fa fa-arrow-left" (click)="goBack()" style="cursor: pointer;"><span
                    style="margin-left: 20px; font-family: Arial">Delayed Work</span></i>
              </a></h5>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedSearch">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedSearches()" formControlName="search" placeholder="Search Team Members here." />
                    <div class="iconStyle">
                        <i class="fa fa-search"></i> 
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedFilter">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedFilters()" formControlName="search" placeholder="Filter" />
                    <div class="iconStyle">
                        <i class="fa fa-filter"></i> 
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
            <div class="card">
                <table class="table sm-view" >
                    <thead>
                        <tr>
                            <th scope="col">Datasource name</th>
                            <th scope="col">Harvesting URL</th>
                            <th scope="col">Finish Date</th>
                            <th scope="col">Process</th>
                            <th scope="col">Current status</th>
                            <th scope="col">Priority</th>
                        </tr>
                    </thead>
      
                    <tbody>
                        <ng-container >
                            <tr class="text-card" *ngFor="let content of delayedValues | paginate: { itemsPerPage: 10, currentPage: page ,totalItems: total }">
                                <td style="vertical-align: middle;">{{content.job.dataSource.name}}</td>
                                <td style="vertical-align: middle;">{{content.job.dataSource.harvestingPageUrl}}</td>
                                <td style="vertical-align: middle;">{{content.taskEstimatedCompletionDate}}</td>
                                <td style="vertical-align: middle;">
                                    <ng-container [ngSwitch]="content.process?.name">
                                        <ng-container *ngSwitchCase="'SUPER ADMINSTRATION'">
                                            <button class="btn btn-super-admin btn-sm">{{ content.process?.name }}</button>
                                            <div class="vl"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'Adminstration'">
                                            <button class="btn btn-admin btn-sm">{{ content.process?.name }}</button>
                                            <div class="vl"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'Extraction'">
                                            <button class="btn btn-extractor btn-sm">{{ content.process?.name }}</button>
                                            <div class="vl"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'Transformation'">
                                            <button class="btn btn-transformer btn-sm">{{ content.process?.name }}</button>
                                            <div class="vl"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'QA'">
                                            <button class="btn btn-qa btn-sm">{{ content.process?.name }}</button>
                                            <div class="vl"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'Processing'">
                                            <button class="btn btn-processor btn-sm">{{ content.process?.name }}</button>
                                            <div class="vl"></div>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td style="vertical-align: middle;" [ngStyle]="{ 'color' : (content.taskStatus === 'DELAYED') ? '#b70000' : (content.taskStatus === 'ASSIGNED' ||content.taskStatus === 'COMPLETED_ON_DEV') ? '#00a510' : '#E54C0A' }">{{content.taskStatus}}</td>
                                <td style="vertical-align: middle;">{{content.job.dataSource.priority}}</td>
                            </tr>
                            
                        </ng-container>
                        
                        
                    </tbody>
                    
                    <tfoot *ngIf="delayedValues?.length !== 0" >
                        <tr>
                            <td colspan="6">
                            <pagination-controls 
                                [maxSize]="maxSize"
                                [directionLinks]="directionLinks"
                                directionLinks="true"
                                previousLabel="Previous"
                                nextLabel="Next" 
                                (pageChange)="pageChange($event)"
                            ></pagination-controls>
                        </td>
                        </tr>
                    </tfoot>
                    
                </table>
                <ng-container *ngIf="delayedValues?.length === 0">
                    <h5 class="text-center">No Data Found</h5>
                </ng-container>
            </div>
    </div>
</div>