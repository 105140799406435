<div class="content" *ngIf="allowedSections.indexOf('work-tracker-clients-list-paginated') !== -1">
    <div class="row">
        <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedSearch">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedSearches()" formControlName="search" placeholder="Search Team Members here." />
                    <div class="iconStyle">
                        <i class="fa fa-search"></i> 
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedFilter">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedFilters()" formControlName="search" placeholder="Filter" />
                    <div class="iconStyle">
                        <i class="fa fa-filter"></i> 
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
            <div class="card">
                <table class="table sm-view d-block" style="overflow-x: auto;" >
                    <thead>
                        <tr>
                            <th >Name</th>
                            <th >Email Id</th>
                            <th >Role</th>
                            <th >Working On</th>
                            <th >Scheduled Work</th>
                        </tr>
                    </thead>
      
                    <tbody>
                        <ng-container *ngIf="taskListValues?.length!==0" >
                            <tr class="text-card" (click)="workAssign(content)" style="cursor: pointer;" *ngFor="let content of taskListValues | paginate: { itemsPerPage: 10, currentPage: page ,totalItems: total }" >
                                
                                <td style="vertical-align: middle;">{{(content.clientsUser.firstName?content.clientsUser.firstName:'')+' '+(content.clientsUser.middleName?content.clientsUser.middleName:'')+' '+(content.clientsUser.lastName?content.clientsUser.lastName:'')}}</td>
                                <td style="vertical-align: middle;">{{content.clientsUser.email}}</td> 
                                <td style="vertical-align: middle; width: 670px; flex-wrap: wrap;justify-content: center;" class="d-flex" >

                                    <ng-container *ngFor="let item of content.clientsUser.roles">
                                        <ng-container [ngSwitch]="item?.name">
                                            <ng-container *ngSwitchCase="'SUPER ADMIN'">
                                                <button class="btn btn-super-admin btn sm mr-2">{{ item.name }}</button><div class="vl"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'ADMIN'">
                                                <button class="btn btn-admin btn sm mr-2">{{ item?.name }}</button><div class="vl"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'EXTRACTOR'">
                                                <button class="btn btn-extractor btn sm mr-2">{{ item?.name }}</button><div class="vl"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'TRANSFORMER'">
                                                <button class="btn btn-transformer btn sm mr-2">{{ item?.name }}</button><div class="vl"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'QA'">
                                                <button class="btn btn-qa btn sm mr-2">{{ item?.name }}</button><div class="vl"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PROCESSOR'">
                                                <button class="btn btn-processor btn sm mr-2">{{ item?.name }}</button><div class="vl"></div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td style="vertical-align: middle;">-</td>
                                <td style="vertical-align: middle;">{{content?.swCount}}</td>
                            </tr>
                            
                        </ng-container>
                        
                    </tbody>
                    <tfoot *ngIf="taskListValues?.length!==0" >
                        <tr>
                            <td colspan="5">
                            <pagination-controls 
                                [maxSize]="maxSize"
                                [directionLinks]="directionLinks"
                                directionLinks="true"
                                previousLabel="Previous"
                                nextLabel="Next" 
                                ></pagination-controls>
                        </td>
                        </tr>
                    </tfoot>
                    
                </table>
                <ng-container *ngIf="taskListValues?.length===0">
                    <h5 class="text-center">No Data Found</h5>
                </ng-container>
            </div>
    </div>
</div>