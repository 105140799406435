import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { DataService } from './services/data.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutService } from './services/layout.service';
import { AuthService } from './services/auth.service'
import { first } from 'rxjs/operators';
import { NotificationsService } from './components/notifications/services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'data-extraction-engine-fe';
  isAdmin: boolean;
  layoutMenus: any;
  toggle: any;
  nav: any;
  bodypd: any;
  menuitem: any;
  headerpd: any;
  body_1: any;
  roles: any;
  currentUrl: any;
  loggedIn: boolean = false;
  buttonVal: boolean = false;
  navOpen: boolean = false;
  currentUserName:any = null;
  jobList: any=[]



  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private data: DataService,
    private _layoutService: LayoutService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private service: NotificationsService
  ) {
    
  }
  ngOnInit() {
    console.log('user', this.data.getUserRole())
    this.layoutMenus=[];
    this.getLayout(this.data.getUserRole())
    // if(this.data.getUserRole()[0].name=='SUPER ADMIN' || this.data.getUserRole()[0].name=='ADMIN'){
    //   this.getLayout('admin');
    // }
    // else{
    //   this.getLayout('all');
    // }


    if(this.loggedIn){
    this.auth.checkProfile().subscribe(
  
      data => {
        console.log('profile',data)
        this.roles = this.auth.roles;
        if (this.auth.roles) {
          this.loggedIn = true;
          this.currentUserName=this.auth.getCurrentUserName
        }
      }
    )
    }

  }
  
  changeOfRoutes() {

    if(this.loggedIn){
      this.data.statusUpdate().subscribe(val => { });
      this.getNotification();
    }
    else{
    this.auth.checkProfile().subscribe(
      data => {
        console.log('data---',data)
        if (localStorage.getItem('role')) {
          this.loggedIn = true;
          this.currentUserName=this.auth.getCurrentUserName
          this.layoutMenus = []
          this.getLayout(this.data.getUserRole());
          // if (this.data.getUserRole()[0].name == 'SUPER ADMIN' || this.data.getUserRole()[0].name == 'ADMIN') {
          //   this.getLayout('admin');
          //   console.log("this is the first")
          // }
          // else {
          //   this.getLayout('all');
          //   console.log("this is the first")
          // }
        }
        else {
          this.loggedIn = false;
        }
        console.log("the val", this.loggedIn)
        if (!this.loggedIn) {
          this.router.navigate['/login'];
        }

      }

    )
    }



    this.currentUrl = this.router.url;
    if (this.router.url == "/getting-started") {

      this.buttonVal = true;
    } else {
      this.buttonVal = false;
    }

  }
  logout() {
    if (this.navOpen) {
      this.toggleNavbar();
    }
    this.auth.logout();
    if (localStorage.getItem('role')) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }
  getLayout(role: any) {
    this.layoutMenus = this._layoutService.getLayout(role);
    console.log("this is the layout", this.layoutMenus);
  }
  markRead(id){
    this.service.markReadById(id).subscribe(res=>{
      this.getNotification();
    })
  }

  toggleNavbar() {
    this.navOpen = !this.navOpen;
    this.toggle = document.getElementById('header-toggle'),
      this.nav = document.getElementById('nav-bar'),
      this.bodypd = document.getElementById('body-pd'),
      this.headerpd = document.getElementById('header')
    this.body_1 = document.getElementById('body-1')
    this.menuitem = document.getElementById('menuItem')
    console.log("toggle");
    this.nav.classList.toggle('show')
    // change icon
    this.toggle.classList.toggle('fa-close')
    // add padding to body
    this.bodypd.classList.toggle('body-pd')
    // add padding to header
    this.headerpd.classList.toggle('body-nav')
    this.body_1.classList.toggle('body_1')
    this.menuitem.classList.toggle('menuItem')

    this.layoutMenus.forEach(element => {
      document.getElementById(element.title).classList.toggle('menuLink')
    });
  }


  openDialog(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', backdrop: 'static' }).result.then((result) => {
      
    });
  }
  goToNotifications(){
    this.router.navigate(['/notification'])
  }
  getNotification(){
    let payload={
      "page": 0,
      "size": 50,
      "order": "DESC",
      "orderBy": "createdDate",
      "read":false
    }
    this.service.getNotification(payload).subscribe(res=>{
      this.jobList=res.content;
    })
  }

}
