import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DelayedWorkComponent } from './delayed-work/delayed-work.component';
import { RecurringJobStatusComponent } from './recurring-job-status/recurring-job-status.component';
import { UnassignedDatasourcesComponent } from './unassigned-datasources/unassigned-datasources.component';
import { UpcomingWorkComponent } from './upcoming-work/upcoming-work.component';

const routes: Routes = [
  {path: "", component: DashboardComponent},
  {path: "delayed-work", component: DelayedWorkComponent},
  {path: "recurring-job-status", component: RecurringJobStatusComponent},
  {path: "unassigned-datasources", component: UnassignedDatasourcesComponent},
  {path: "upcoming-work", component: UpcomingWorkComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
