import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
@Component({
  selector: 'app-delayed-work',
  templateUrl: './delayed-work.component.html',
  styleUrls: ['./delayed-work.component.scss']
})
export class DelayedWorkComponent implements OnInit {
  delayedSearch:FormGroup;
  delayedFilter:FormGroup;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public maxSize: number = 9;
  public loading:boolean=false;
  collection: any=[1];
  page:number=1; 
  delayedValues:any =[];
  total:number=0;

  constructor( private formBuilder: FormBuilder,private route: Router,
               private service: DashboardService) { }

  ngOnInit(): void {
    this.delayedSearch = this.formBuilder.group({
      search: ['']
    });
    this.delayedFilter = this.formBuilder.group({
      search: ['']
    });
    this.delayedData();
  }
  delayedSearches(){

  }
  delayedFilters(){
    
  }
  pageChange(event){
     this.page=(event);
     this.delayedData();
  }
  delayedData() {
    let payload = {
      "page": this.page-1,
      "size":10,
      "orderBy": null,
      "order": "DESC",
      // "taskStatus":"DELAYED"
    }
    this.service.delayedData(payload).subscribe(response => {
      this.delayedValues = response.data.content;
      console.log("delayed vals", this.delayedValues);
      this.total=response.data.totalElements;
    })
  }
  goBack(){
    this.route.navigateByUrl('manage-datasource');
  }

}
