import { Component, OnInit, Inject, AfterViewInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'

@Component({
  selector: 'app-er-home',
  templateUrl: './er-home.component.html',
  styleUrls: ['./er-home.component.scss']
})
export class ErHomeComponent implements OnInit {
  page: number=0;
  pageSize: number=25;
  eventType: any;
  eventStatus: any;
  startDate: any;
  endDate: any;
  entityResolutionStatus:string;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = false;
  public maxSize: number = 9;
  src: any;
  total: any;
  searchText:string;
  
  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private modalService:NgbModal,
    private fb:FormBuilder,
    @Inject("API_URL") public API_URL: any,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.entityResolutionStatus="IN_QACHECK"
    this.getEntity();
    // this.searchSource(this.page,this.pageSize);
  }
getEntity(){
  
  this.data.getEntityByFilter().subscribe(
    data=>{
      console.log(data)
      if(data.statusCode==200){
        console.log(data)
        this.src=data['data']['source']
        console.log("hii",this.src)
        this.total=data['data'].totalElements
        this.page=data['data'].pageNumber
      }
    }
  )
}
// searchSource(page=this.page,size=this.pageSize){
//   let abc={
//     "page" :  page,
//       "size" : size,
//       "order" : "ASC",

//       "source":[],
//       "entityResolutionStatus":this.entityResolutionStatus,
      

//   }
//   this.data.searchErSource(abc).subscribe(
//     data=>{
      
//       if(data.statusCode==200){
//         console.log(data)
//         this.src=data['data']['content']
//         console.log("hii",this.src)
//         this.total=data['data'].totalElements
//         this.page=data['data']['pageable'].pageNumber
//       }
//     },
//     (err:HttpErrorResponse)=>{
//       console.log(err)
      
//         let alertObj = {
//           message:err.error.message,
//           status: err.error.infoType,
//           autoDismiss: true,
//           timeToDismiss: 5000
//         };
//         this.data.toastrShow(alertObj.message,alertObj.status)
//     }
//   )
// }
searchItems(){

}
increaseSize(event){
  console.log(event)
  // this.searchSource(this.page,event);
}
pagec(event){
console.log(event)
let p=event-1
  // this.searchSource(p,this.pageSize)
}
onClickSearch(event,item){
  console.log(event)
  console.log(item)
  let abc=item.key
  console.log(abc)
  this.router.navigate(["/list-entity",abc])

}


logout(){
  this.auth.logout();
}

}
