import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-ds-summary',
  templateUrl: './create-ds-summary.component.html',
  styleUrls: ['./create-ds-summary.component.scss']
})
export class CreateDsSummaryComponent implements OnInit {

  createDsForm: FormGroup = this.fb.group({
    amlDataSourceId:[''],
    name:[''],
    slug:[''],
    harvestingPageUrl:[''],
    dataAgencyName:[''],
    dataAgencyUrl:[''],
    dataAgencySlug:['']
  });
  successMsg="";
  constructor(
    private fb:FormBuilder,
    private auth:AuthService,
    private location:Location,
  ) { }

  ngOnInit(): void {

    let state=history.state;
    console.log("history state: ",state)
    if(state!=undefined && state['data']!=undefined){
      this.createDsForm.patchValue({
        amlDataSourceId:state['data']['amlDataSourceId'],
        name:state['data']['name'],
        slug:state['data']['slug'],
        harvestingPageUrl:state['data']['harvestingPageUrl'],
        dataAgencyName:state['data']['dataAgencyName'],
        dataAgencyUrl:state['data']['dataAgencyUrl'],
        dataAgencySlug:state['data']['dataAgencySlug']
      });
      this.successMsg=state['message']?state['message']:""
    }
  }

  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }
}
