import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _http: HttpClient,private toastr: ToastrService,@Inject("API_URL") public API_URL: any) { }

  upcomingData(payload) {
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/page/upcoming`,payload);
  }
  delayedData(payload) {
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/page/delayed`,payload);
  }
  
  unassignedData(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/page/unassigned`,payload);
  }
  recurringPageData(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/page/recurring-job`,payload);
  }
  recurringData(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/rjs/counts`);
  }
  overallstausData(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/overall-status`,payload);
  }
  logsPiChartData(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/logs`);
  }
  ongoingPiChartData(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/ongoing`);
  }
  completedPiChartData(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/completed`);
  }
  waitingPiChartData(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/waiting`);
  }
  delayedPiChartData(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/delayed`);
  }
  getPieChartDataByStatus(status){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/${status}`);
  }
  issueFilter(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/create-data-source/task-list`,payload);
  }
  getRecurringJobData(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/rj-history/filter`,payload);
  }
}
