import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-editsource',
  templateUrl: './editsource.component.html',
  styleUrls: ['./editsource.component.scss']
})
export class EditsourceComponent implements OnInit {
  sub: any;
  sourcedata: any;
  selectors: any;
  selectorList:  Array<any>=[]
  property: any;
  options = [
    { value: '1', label: 'TEXT' },
    { value: '2', label: 'IMAGE' },
    { value: '3', label: 'URL' },
  ];
  verify: { property: any; cssSelector: any; extractionInfo: any; individualUrl: any; harvestingLinkUrl: any; };
  propval: any;
  newval: { property: any; cssSelector: any; extractionInfo: any; individualUrl: any; value: any; };
  individualurl: any;
  harvestingPageUrl: any;
  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private modalService:NgbModal,
  ) { }

  ngOnInit(): void {
    this.sub=this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.sub)
    this.data.getProperty(null).subscribe(
      data=>{
        console.log(data)
        this.property=data['data']
        console.log(this.property)
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
    this.data.editsource(this.sub).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data)
         this.sourcedata=data['data']
         this.selectors=data['data']['selectors']['selectorResponses']
         this.individualurl=data['data'].individualUrl
         console.log(this.individualurl)
         this.harvestingPageUrl=data['data'].harvestingPageUrl
         this.selectorList=this.selectors
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  onUpdate(){
    console.log(this.sourcedata)
    this.data.updateDataSource(this.sourcedata).subscribe(
      data=>{
        console.log(data)
        let alertObj = {
          message:data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )
  }
  remove(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.selectorList.splice(id, 1);
  }

  add() {
    this.selectorList.push({property:'',cssSelector:'',extractionInfo:'',value:''})
    console.log(this.selectorList)
      
  }
  check(event,id){
    this.verify={"property":event.property,"cssSelector":event.cssSelector,"extractionInfo":event.extractionInfo,"individualUrl":this.individualurl,"harvestingLinkUrl":this.harvestingPageUrl}
    console.log(this.verify)
    this.spinner.show()
    this.data.verifySelector(this.verify).subscribe(
      data=>{
        
        this.propval=data['data']
        this.newval={"property":event.property,"cssSelector":event.cssSelector,"extractionInfo":event.extractionInfo,"individualUrl":this.individualurl,"value":this.propval}
        let index = this.selectorList.find(item=>item.property==event.property).value=this.propval;
        
        this.spinner.hide()
        // this.personList.find(item=>item.id===id).value = this.propval
        // console.log(this.newval)
        
        console.log(this.propval)
      
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )
  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }

}
