import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
@Component({
  selector: 'app-unassigned-datasources',
  templateUrl: './unassigned-datasources.component.html',
  styleUrls: ['./unassigned-datasources.component.scss']
})
export class UnassignedDatasourcesComponent implements OnInit {

  delayedSearch:FormGroup;
  delayedFilter:FormGroup;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public maxSize: number = 9;
  public loading:boolean=false;
  collection: any=[1];
  page:number=1;
  total:number=0;
  unassignedValues:any=[];
  constructor( private formBuilder: FormBuilder,
    private route : Router,
    private service: DashboardService) { }

  ngOnInit(): void {
    this.delayedSearch = this.formBuilder.group({
      search: ['']
    });
    this.delayedFilter = this.formBuilder.group({
      search: ['']
    });
    this.unassignedData();
  }
  delayedSearches(){

  }
  delayedFilters(){
    
  }
  pageChange(event){
    this.page=(event);
    this.unassignedData();
  }
  unassignedData() {
    let payload = {
      "page": this.page-1,
      "size":10,
      "orderBy": null,
      "order": "DESC",
      "taskStatus":"UNASSIGNED"
    }
    this.service.unassignedData(payload).subscribe(response => {
      this.unassignedValues = response.data.content;
      console.log("unassigned vals", response);
      this.total=response.data.totalElements;
    })
  }
  goBack(){
    this.route.navigateByUrl('manage-datasource');
  }

}

