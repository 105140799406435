import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl,FormBuilder, Validators } from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'

@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.scss']
})
export class ClientRegistrationComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  error: any;
  closeResult: string;
  error_messages = {
    'firstName': [
      { type: 'required', message: 'First Name is required.' },
    ],
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'minlength', message: 'Email length.' },
      { type: 'maxlength', message: 'Email length.' },
      { type: 'required', message: 'please enter a valid email address.' }
    ],
    'contactNumber': [
      { type: 'required', message: 'Contact Number is required.' },
    ],
    'password': [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'password length.' },
      { type: 'maxlength', message: 'password length.' }
    ],
    'confirmPassword': [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'password length.' },
      { type: 'maxlength', message: 'password length.' }
    ],
    'role':[
      { type: 'required', message: 'Role is required' },
    ]
  };
  setForm:FormGroup;
  usertoken: any;

  userRoles=[];
  // menuRoutes=[
  //   {route:"/attributes",name:"Attributes"},
  //   {route:"/cleaners",name:"Cleaners"},
  //   {route:"/dataconfigs",name:"Data Configs"},
  //   {route:"/client-registration",name:"Register User"},
  //   {route:"/erhome",name:"Resolve Entity"}
  // ];
  // selectedMenu:any=this.menuRoutes[3];
  constructor(
    private modalService: NgbModal,
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private fb:FormBuilder
  ) 
  {
    this.registerForm = this.fb.group({
      firstName:new FormControl ('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50)])),
      contactNumber:new FormControl('',Validators.compose([Validators.required,
        Validators.minLength(9),
        Validators.maxLength(11)])),
      role:new FormControl('',Validators.compose([Validators.required]))
    });
    this.setForm = this.fb.group({
    password:new FormControl ('', Validators.compose([Validators.required])),
    confirmPassword: new FormControl('', Validators.compose([Validators.required,
      Validators.minLength(6),
      Validators.maxLength(50)])),
    
    
    },
    { 
      validators: this.password.bind(this)
    }
    );

  }


  ngOnInit() {
    this.data.getuserRoles().subscribe(result=>{
      if(result.statusCode==200)
        this.userRoles= result.data;
      else
        this.userRoles=[];
    })
  }
     
  get f() { return this.registerForm.controls; }
  onSubmit(content) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    

    // this.loading = true;
    console.log(this.registerForm.value);
    this.spinner.show()
    let payload={
      contactNumber: this.registerForm.value['contactNumber'],
      email: this.registerForm.value['email'],
      firstName: this.registerForm.value['firstName'],
      role:{
          slug: this.registerForm.value['role']
      }
    }
    this.data.createUser(payload).subscribe(
      data=>{
        this.spinner.hide();
        if(data.statusCode==200){
          this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'})

          console.log(data)
          this.usertoken=data['data'].token
          console.log(this.usertoken)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )

  }
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  onSubmitnew(){
    console.log(this.setForm.value)
    let abc={"password":this.setForm.controls.password.value,"confirmPassword":this.setForm.controls.confirmPassword.value,"token":this.usertoken}
    console.log(abc)
    this.spinner.show()
    this.data.setUpAccount(abc).subscribe(
      data=>{
        console.log(data)
        this.spinner.hide();
        this.modalService.dismissAll();
        this.setForm.reset();
        this.registerForm.reset();
        this.registerForm.patchValue({
          role:""
        });
        let alertObj = {
          message:data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide();
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 3000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  // goToMenu(){
  //   //  console.log("menu value: ",this.selectedMenu);
  //    this.router.navigate([this.selectedMenu.route])
  //  }
  logout(){
    this.auth.logout();
  }
  // goback(){
  //   this.location.back();
  // }
}
