<!-- <app-admin-topmenu step="3"></app-admin-topmenu> -->
      
<div class="container-fluid">
    <div class="row">

        <div class="col-lg col-md col-sm d-flex justify-content-center">
            <div class="card">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit(content)" class="text-center ">

                    <p class="card-title mb-2">Register User</p>

                    <!-- Email -->
                    <div class="form-group">
                        <label for="firstName">Firstname</label>
                        <input type="text" formControlName="firstName" class="form-control" placeholder="Enter your FirstName."
                             />
                        <!-- <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">firstName is required</div>
                        </div> -->
                        <div class="error-messages" >
                            <ng-container *ngFor="let error of error_messages.firstName" >
                                <div class="error-message" *ngIf="registerForm.get('firstName').hasError(error.type) && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
                                    {{ error.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" id="myInput" placeholder="Enter your Email address." formControlName="email" class="form-control"
                             />
                             <!-- [ngClass]="{ 'is-invalid': submitted && f.email.errors }" -->
                        <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">email is required</div>
                        </div> -->
                        <div class="error-messages">
                            <ng-container *ngFor="let error of error_messages.email">
                                <div class="error-message" *ngIf="registerForm.get('email').hasError(error.type) && 
                (registerForm.get('email').dirty || registerForm.get('email').touched)">
                                    {{ error.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="contactNumber">Contact Number</label>
                        <input type="contactNumber" placeholder="Enter your Contact number." formControlName="contactNumber" class="form-control"
                            />
                        <!-- <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.contactNumber.errors.required">contactNumber is required</div>
                        </div> -->
                        <div class="error-messages" >
                            <ng-container *ngFor="let error of error_messages.contactNumber" >
                                <div class="error-message" *ngIf="registerForm.get('contactNumber').hasError(error.type) && (registerForm.get('contactNumber').dirty || registerForm.get('contactNumber').touched)">
                                    {{ error.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="role">User Role</label>
                        <select required formControlName="role" class="form-control">
                            <option value="" disabled>Select User Role</option> 
                            <option *ngFor="let role of userRoles" [ngValue]="role.slug">{{role.name}}</option>
                        </select>
                    </div>
                    <!-- Remember me -->
                    <!-- <mdb-checkbox [default]="true" type="checkbox" (change)="myFunction($event)">Show Password</mdb-checkbox> -->
            

            
                <button mdbBtn color="info" [disabled]="loading && !registerForm.valid"  size="md" class="my-5" type="submit">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign Up</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                <!-- Register -->

        </form>
    </div>
</div>
</div>
</div>
<ng-template #content  let-modal >
    <div class="modal-header" style="padding: 1rem;">
      <h4 class="modal-title" id="modal-basic-title">Setup Account</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
        <form [formGroup]="setForm" (ngSubmit)="onSubmitnew()">
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" placeholder="Enter your Password" formControlName="password" class="form-control"
                     />
                <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">password is required</div>
                </div> -->
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.password">
                        <div class="error-message" *ngIf="setForm.get('password').hasError(error.type) && (setForm.get('password').dirty || setForm.get('password').touched)">
                            {{ error.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input type="confirmPassword" placeholder="Retype Your Password" formControlName="confirmPassword" class="form-control"
                     />
                <!-- <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">confirmPassword is required</div>
                </div> -->
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.confirmPassword">
                        <div class="error-message" *ngIf="setForm.get('confirmPassword').hasError(error.type) && (setForm.get('confirmPassword').dirty || setForm.get('confirmPassword').touched)">
                            {{ error.message }}
                        </div>
                    </ng-container>
                    <ng-container >
                        <div class="error-message" *ngIf="setForm.errors">
                            Entered Password Didn't Match!!
                        </div>
                    </ng-container>
                    <!-- <pre>{{ setForm.errors | json}}</pre> -->
                </div>
            </div>
            

            
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark float-left" (click)="modal.close('Save click')">Close</button>
                <button type="submit" class="btn btn-success ">
                     Save
                </button>
            </div>
            
        </form>
        </div>
    </ng-template>