<a mdbBtn floating="true" (click)="goback()">
  <mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon>
</a>

<div class="col-lg col-md col-sm">
  <div class="card">
    <div class="card-title d-flex justify-content-center mt-2">
      <h3>Update Data Source Details</h3>
    </div>
    <div class="card-body">
      <form (ngSubmit)="onUpdate()">
        <div class="form-group">
          <label for="name">Name</label>
          <input disabled type="text" [ngModel]="sourcedata?.name" (ngModelChange)="sourcedata.name=$event" name="name"
            class="form-control" />

        </div>
        <div class="form-group">
          <label for="slug">Slug</label>
          <input disabled type="text" [ngModel]="sourcedata?.slug" (ngModelChange)="sourcedata.slug=$event" name="slug"
            class="form-control" />

        </div>


        <div class="form-group">
          <label for="dataAgencyName">Data Agency Name</label>
          <input type="text" [ngModel]="sourcedata?.dataAgencyName" (ngModelChange)="sourcedata.dataAgencyName=$event"
            name="sourcedata.dataAgencyName" class="form-control" />

        </div>
        <div class="form-group">
          <label for="dataAgencySlug">Data Agency Slug</label>
          <input type="text" [ngModel]="sourcedata?.dataAgencySlug" (ngModelChange)="sourcedata.dataAgencySlug=$event"
            name="dataAgencySlug" class="form-control" />

        </div>
        <div class="form-group">
          <label for="dataAgencyUrl">Data Agency URL</label>
          <input type="text" [ngModel]="sourcedata?.dataAgencyUrl" (ngModelChange)="sourcedata.dataAgencyUrl=$event"
            name="sourcedata.dataAgencyUrl" class="form-control" />

        </div>

        <div class="form-group">
          <label for="harvestingPageUrl">Harvesting Page Url</label>
          <input disabled type="text" [ngModel]="sourcedata?.harvestingPageUrl"
            (ngModelChange)="sourcedata.harvestingPageUrl=$event" name="sourcedata.harvestingPageUrl"
            class="form-control" />

        </div>
        <label for="isindividualLink">Is individual Link</label>
        <div class="form-group">

          <div class="form-check form-check-inline">
            <input data-md-icheck [ngModel]="sourcedata?.isindividualLink"
              (ngModelChange)="sourcedata.isindividualLink=$event" name="sourcedata.isindividualLink"
              class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true">
            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
          </div>


          <div class="form-check form-check-inline">
            <input data-md-icheck [ngModel]="sourcedata?.isindividualLink"
              (ngModelChange)="sourcedata.isindividualLink=$event" name="sourcedata.isindividualLink"
              class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false">
            <label class="form-check-label" for="inlineCheckbox2">No</label>
          </div>

        </div>
        <div class="form-group">
          <label for="individualUrl">Individual Url</label>
          <input type="text" [ngModel]="sourcedata?.individualUrl" (ngModelChange)="sourcedata.individualUrl=$event"
            name="sourcedata.individualUrl" class="form-control" />

        </div>
        <label>Selectors Details</label>
        <div class="card">

          <div class="card-body">
            <div id="table" class="table-editable">
              <span class="table-add float-right mb-3 mr-2">


                <button mdbBtn color="info" (click)="add()" rounded="true" size="md" class="mb-2" type="button">Add
                  more</button>




              </span>
              <table class="table table-bordered table-responsive-md table-striped text-center">
                <tr>
                  <th class="text-center">Property</th>
                  <th class="text-center">Css Selector</th>
                  <th class="text-center">Extraction Info</th>

                  <th class="text-center">Value</th>
                  <th class="text-center">Verify</th>
                  <th class="text-center">Remove</th>
                </tr>
                <tr *ngFor="let person of selectorList; let id = index">
                  <td>
                    <select [(ngModel)]="person.property" name="person.property" [ngModelOptions]="{standalone: true}"
                      ngDefaultControl>
                      <option [ngValue]="item.slug" *ngFor="let item of property">{{item.attribute}}</option>
                    </select>
                    <!-- <input type="text"    [(ngModel)]="person.property"    ngDefaultControl [ngModelOptions]="{standalone: true}" contenteditable="true"/> -->
                  </td>
                  <td>
                    <input [(ngModel)]="person.cssSelector" ngDefaultControl [ngModelOptions]="{standalone: true}" />
                  </td>
                  <td>
                    <select class="browser-default custom-select" name="person.extractionInfo"
                      [(ngModel)]="person.extractionInfo" [ngModelOptions]="{standalone: true}" ngDefaultControl>
                      <option selected disabled>{{person.extractionInfo}}</option>
                      <option value="TEXT">TEXT</option>
                      <option value="URL">URL</option>
                      <option value="IMAGE">IMAGE</option>
                    </select>
                    <!-- <span contenteditable="true" (keyup)="changeValue(id, 'extractionInfo', $event)" (blur)="updateList(id, 'extractionInfo', $event)">{{person.extractionInfo}}</span> -->
                  </td>

                  <td>
                    <input [(ngModel)]="person.value" [ngModelOptions]="{standalone: true}" ngDefaultControl />

                  </td>
                  <td>
                    <span class="table-remove">
                      <button type="button" mdbBtn color="yellow" rounded="true" size="sm" class="my-0"
                        (click)="check(person,id)">Check</button>
                    </span>
                  </td>
                  <td>
                    <span class="table-remove">
                      <button type="button" mdbBtn color="danger" rounded="true" size="sm" class="my-0"
                        (click)="remove(id)">Remove</button>
                    </span>
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>
        <label class="mt-3" for="pagination">Pagination Type</label>

        <div class="form-group">

          <div class="form-check form-check-inline">
            <input data-md-icheck [ngModel]="sourcedata?.pagination" (ngModelChange)="sourcedata.pagination=$event"
              class="form-check-input" type="radio" id="inlineCheckbox1" value="YES" name="pagination">
            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
          </div>


          <div class="form-check form-check-inline">
            <input data-md-icheck [ngModel]="sourcedata?.pagination" (ngModelChange)="sourcedata.pagination$event"
              class="form-check-input" type="radio" id="inlineCheckbox2" value="NO" name="pagination">
            <label class="form-check-label" for="inlineCheckbox2">No</label>
          </div>

        </div>
        <div class="form-group">
          <label for="paginationType">Type of Pagination</label>
          <select class="form-control" [ngModel]="sourcedata?.paginationType"
            (ngModelChange)="sourcedata.paginationType=$event" name="paginationType" id="paginationtype">
            <option value="" disabled selected>Please choose pagination type</option>
            <option value="NEXTPAGEBUTTON">Next Button</option>
            <option value="Load More">Load More</option>
            <option value="Infinite Scroll">Infifnite Scroll</option>
          </select>
        </div>
        <label>Next Button</label>
        <div class="form-group">

          <div class="form-check form-check-inline">
            <input data-md-icheck [ngModel]="sourcedata?.nextButton" (ngModelChange)="sourcedata.nextButton=$event"
              class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true" name="nextButton">
            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
          </div>


          <div class="form-check form-check-inline">
            <input data-md-icheck [ngModel]="sourcedata?.nextButton" (ngModelChange)="sourcedata.nextButton$event"
              class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false" name="nextButton">
            <label class="form-check-label" for="inlineCheckbox2">No</label>
          </div>

        </div>
        <!-- <div class="form-group"  >
                            
            <label for="harvestingPageUrl">Harvesting Page Link Url</label>
            <input  class="form-control"  type="text" id="harvestingPageUrl" name="harvestingPageUrl"  [ngModel]="sourcedata?.harvestingPageUrl" (ngModelChange)="sourcedata.harvestingPageUrl=$event"  >
            
        </div> -->
        <div class="form-group">

          <label for="nextPageSelector">Next Page Selector</label>
          <input class="form-control" type="text" id="nextPageSelector" name="nextPageSelector"
            [ngModel]="sourcedata?.nextPageSelector" (ngModelChange)="sourcedata.nextPageSelector=$event">

        </div>
        <div class="form-group">

          <label for="noOfPages">No of pages</label>
          <input class="form-control" type="text" id="noOfPages" name="noOfPages" [ngModel]="sourcedata?.noOfPages"
            (ngModelChange)="sourcedata.noOfPages=$event">

        </div>
        <div class="form-group">

          <label for="harvesting">Harvesting link selectors</label>
          <textarea class="form-control" type="text" name="harvestingLinksSelector"
            [ngModel]="sourcedata?.harvestingLinksSelector" (ngModelChange)="sourcedata.harvestingLinksSelector=$event">
            </textarea>
        </div>
        <div class="form-group">

          <label for="isTable">Table</label>
          <div class="form-group">
            <div class="form-check form-check-inline">
              <input data-md-icheck [ngModel]="sourcedata?.table" (ngModelChange)="sourcedata.table=$event"
                class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true" name="isTable">
              <label class="form-check-label" for="inlineCheckbox1">Yes</label>
            </div>


            <div class="form-check form-check-inline">
              <input data-md-icheck [ngModel]="sourcedata?.table" (ngModelChange)="sourcedata.table=$event"
                class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false" name="isTable">
              <label class="form-check-label" for="inlineCheckbox2">No</label>
            </div>

          </div>

        </div>


        <button type="submit" class="btn btn-success">
          Save
        </button>


      </form>
    </div>
  </div>
</div>

