import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
@Component({
  selector: 'app-upcoming-work',
  templateUrl: './upcoming-work.component.html',
  styleUrls: ['./upcoming-work.component.scss']
})
export class UpcomingWorkComponent implements OnInit {

  delayedSearch:FormGroup;
  delayedFilter:FormGroup;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public maxSize: number = 9;
  public loading:boolean=false;
  collection: any=[1];
  page:number=1;
  upcomingValues: any = [];
  total:number=0;
  constructor( private formBuilder: FormBuilder,
    private route : Router,
    private service: DashboardService
    ) { }

  ngOnInit(): void {
    this.delayedSearch = this.formBuilder.group({
      search: ['']
    });
    this.delayedFilter = this.formBuilder.group({
      search: ['']
    });
    this.upcomingData();
  }
  delayedSearches(){

  }
  delayedFilters(){
    
  }
  pageChange(event){
    this.page=(event);
    this.upcomingData();
  }
  upcomingData() {
    let payload = {
      "page": this.page-1,
      "size":10,
      "orderBy": null,
      "order": "DESC"
    }
    this.service.upcomingData(payload).subscribe(response => {
      this.upcomingValues = response.data.content;
      console.log("upcoming vals", this.upcomingValues);
      this.total=response.data.totalElements;
    })
  }
  goBack(){
    this.route.navigateByUrl('manage-datasource');
  }

}

