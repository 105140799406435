import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss']
})
export class AttributesComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  allAttributes: any;
  closeResult: string;
  editAttribute: any;
synonyms:Array<string>=[]
  createForm: FormGroup;
  searchText: string = '';
  previous: string;
  elements: any = [];
  maxVisibleItems: number = 8;
  page:number=0;
  // menuRoutes=[
  //   {route:"/attributes",name:"Attributes"},
  //   {route:"/cleaners",name:"Cleaners"},
  //   {route:"/dataconfigs",name:"Data Configs"},
  //   {route:"/client-registration",name:"Register User"},
  //   {route:"/erhome",name:"Resolve Entity"}
  // ];
  // selectedMenu:any=this.menuRoutes[0];
  constructor(
    private modalService: NgbModal,
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private fb:FormBuilder,
    private cdRef: ChangeDetectorRef
    
  ) { }
  @HostListener('input') oninput() {
    this.searchItems();
}
  ngOnInit(): void {
    this.spinner.show()
    let abc={"page":this.page,"size":2000}
    this.data.getAllAttribueFilter(abc).subscribe(
      data=>{
        console.log(data)
        this.allAttributes=data['data']['content']
        console.log(this.allAttributes)
        this.mdbTable.setDataSource(this.allAttributes)
        this.elements=this.mdbTable.getDataSource()
        this.previous=this.mdbTable.getDataSource()
        this.spinner.hide()
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
    this.createForm= this.fb.group({
      attribute:['', Validators.required],
     slug:['', Validators.required],
  
     
  
     synonyms:['',Validators.required],
     description:['', Validators.required],
     
    })
    
    
  }
  
  onSubmitnew(){
    console.log(this.createForm.controls.synonyms.value.sp)
    let st=this.createForm.controls.synonyms.value
  let syn=st.split(",")
  
  let updatedvalue={"attribute":this.createForm.controls.attribute.value,"slug":this.createForm.controls.slug.value,"synonyms":syn,"description":this.createForm.controls.description.value}
    
  console.log(updatedvalue)
    this.spinner.show()
    this.data.addProperty(updatedvalue).subscribe(
      data=>{
        if(data.statusCode==200){
          this.createForm.reset()
          this.modalService.dismissAll()
          this.spinner.hide()
          console.log(data)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 3000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  onClick(event,contentone,item){
  
     let id = JSON.stringify(item['id'])
    // console.log(id)
    console.log(id)
    this.data.getAttributeById(id).subscribe(
      data=>{
        if(data.statusCode==200){
        console.log(data)

        this.editAttribute=data['data']
        console.log(this.editAttribute)
        this.modalService.open(contentone, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  onUpdate(){
    console.log(this.editAttribute)
    let st=this.editAttribute.synonyms
    let synonym=st.split(",")
    let updatedData={"attribute":this.editAttribute.attribute,"slug":this.editAttribute.slug,"synonyms":synonym,"description":this.editAttribute.description}
    let id=this.editAttribute.id
    console.log(updatedData)
    console.log(synonym)
    this.spinner.show()
    this.data.updateAttribute(updatedData,id).subscribe(
      data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll()
          this.spinner.hide()
        console.log(data)
        let alertObj = {
          message:data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 3000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
        this.mdbTable.setDataSource(this.previous);
        this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
        this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
        this.mdbTable.setDataSource(prev);
    }
  }

  // goToMenu(){
  //   //  console.log("menu value: ",this.selectedMenu);
  //    this.router.navigate([this.selectedMenu.route])
  //  }
 logout(){
    this.auth.logout();
  }
  // goback(){
  //   this.location.back();
  // }
}
