import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  showPassword: boolean;
  error: any;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private data: DataService,
    private auth: AuthService
  ) {
    this.showPassword = false;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]

    });
  }
  get f() { return this.loginForm.controls; }
  onSubmit() {
    this.submitted = true;


    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }


    this.loading = true;
    console.log(this.loginForm.value);
    // this.authService.userAuthentication(this.username, this.pass).subscribe(data=>{console.log(data)})
    this.authService.userAuthentication(this.loginForm.value).subscribe(
      data => {
        console.log(data);
        let alertObj = {
          message: "Logged-In Successfully",
          status: "SUCCESS",
          autoDismiss: true,
          timeToDismiss: 5000
        };
        // this.data.toastrShow(alertObj.message,alertObj.status)


        this.router.navigate(['/dashboard']);

      },
      (err: HttpErrorResponse) => {
        console.log(err)
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message, alertObj.status)
        this.error = err.error.message;
        this.loading = false;
      }
    );
  }
  myFunction(e) {

    this.showPassword = e.target.checked;

  }
}
