

<!-- <app-admin-topmenu step="0"></app-admin-topmenu> -->

    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        
        <div class="card">

          <div class="card-body">
        <span class="table-add float-left mb-3 mr-2">
          
           <button mdbBtn color="indigo" rounded="true" (click)="open(content)" size="sm" class="mb-2" type="button" >Add New Attribute</button>
      
        </span>
        <span class="table-add float-right mb-3 mr-2">
          
          
            <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText" (keyup)="searchItems()" id="search-input"
                  mdbInput>
            
              
       </span>
          <table class="table table-bordered table-striped text-center " #tableEl="mdbTable" mdbTable  >
              <thead class="thead-dark">
                <tr>
                   <th>ID</th>    
                  <th >Attributes</th>
                  <th>Slug</th>
                  <th>Synonyms</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
                  
              </thead>
              <tbody #row> 
                <tr mdbTableCol *ngFor="let item of elements | paginate: { itemsPerPage: 10, currentPage: page };let i=index">
                  <td  class="red-text">{{item?.id}}</td>           
                  <td >{{item?.attribute}}</td>
                  <td >{{item?.slug}}</td>
                  <td >{{item?.synonyms}}</td>
                  <td >{{item?.description}}</td>
                  
                  
                  <td >
                    <span class="table-remove">
                      <button type="button" mdbBtn color="light" rounded="true" size="sm" class="my-0" id="{{item.id}}" (click)="onClick($event,contentone,item)"  ><i class="fas fa-edit"></i></button>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot class="grey lighten-5 w-100">
                <tr>
                  <td colspan="8">
                    <pagination-controls (pageChange)="page = $event"></pagination-controls>

                  </td>
                </tr>
                </tfoot>
            </table>
            </div>
        </div>
      </div>
            <!-- <div class="d-flex justify-content-center">
            <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
          </div> -->
            
      </div>
      <ng-template #content  let-modal >
        <div class="modal-header" style="padding: 1rem;">
          <h4 class="modal-title" id="modal-basic-title">Create new attribute</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form [formGroup]="createForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                    <label for="attribute">Attribute</label>
                    <input type="text" formControlName="attribute"   class="form-control"  />
                    
                </div>
                <div class="form-group">
                    <label for="slug">Slug</label>
                    <input type="text" formControlName="slug"  class="form-control" />
                    
                </div>
                <div class="form-group">
                  <label for="synonyms">Synonyms</label>
                  <input type="text" formControlName="synonyms"  class="form-control" />
                  
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <input type="text" formControlName="description"  class="form-control" />
                
            </div>
                
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button type="submit" class="btn btn-success">
                         Save
                    </button>
                </div>
                
            </form>
            </div>
        </ng-template>
        <ng-template #contentone  let-modal >
            <div class="modal-header" style="padding: 1rem;">
              <h4 class="modal-title" id="modal-basic-title">Update attribute</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              
                <form  (ngSubmit)="onUpdate()">
                    <div class="form-group">
                        <label for="attribute">Attribute</label>
                        <input type="text" [(ngModel)]="editAttribute.attribute"  name="editAttribute.attribute" class="form-control"  />
                        
                    </div>
                    <div class="form-group">
                        <label for="slug">Slug</label>
                        <input type="text" [(ngModel)]="editAttribute.slug" name="editAttribute.slug" class="form-control" />
                        
                    </div>
                    <div class="form-group">
                      <label for="synonyms">synonyms</label>
                      <input type="text" [(ngModel)]="editAttribute.synonyms" name="editAttribute.synonyms" class="form-control" />
                      
                  </div>
                  <div class="form-group">
                    <label for="description">description</label>
                    <input type="text" [(ngModel)]="editAttribute.description" name="editAttribute.description" class="form-control" />
                    
                </div>
                    
                    
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                        <button type="submit" class="btn btn-success">
                             Save
                        </button>
                    </div>
                    
                </form>
                </div>
            </ng-template>