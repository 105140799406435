import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkTrackerComponent } from './work-tracker.component';
import { DetailsComponent } from './details/details.component';
const routes: Routes = [
  {path: "", component: WorkTrackerComponent},
  {path: "details", component: DetailsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkTrackerRoutingModule { }
