import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import { WorkTrackerService } from '../service/work-tracker.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  public pieChartOptions: ChartOptions = { responsive: true };
  public pieChartData: SingleDataSet = [300, 200, 170, 2085];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  detailsData: any = [];
  detailsVal: any = [];
  taskStatusArr: any = [
    { status: "UNASSIGNED" },
    { status: "ASSIGNED" },
    { status: "IN_PROGRESS" },
    { status: "DELAYED" },
    { status: "COMPLETED" },
    { status: "IN_ISSUE_RESOLUTION" }]
  selectedTaskStatusForFilter:string= null
  //bar graph
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', stack: 'a' },
    { data: [78, 98, 70, 59, 76, 47, 100], label: 'Series C', stack: 'a' }

  ];
  constructor(private formBuilder: FormBuilder,
    private route: Router,
    private service: WorkTrackerService,
    private loader : NgxSpinnerService,
    private data: DataService )
    {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.service.work_tracker.subscribe(val => {
      this.detailsVal = val;
      console.log("hdhdh", val.clientsUser.email);
      this.details();
    })

  }
  details() {
    this.loader.show()
    let payload:any = {
      "page": 0,
      "size": 100,
      "orderBy": "createdDate",
      "order": "ASC",
      
    }
    if(this.selectedTaskStatusForFilter === "DELAYED"){
      payload = {...payload , delayed:true}
    }
    else{
      payload = {...payload ,taskStatus: this.selectedTaskStatusForFilter}
    }
    this.service.workFilter(payload, this.detailsVal.clientsUser.email).subscribe(response => {
      this.loader.hide()
      this.detailsData = response.data.content;
    },(err)=>{
      this.loader.hide()
      console.log(err)
    })
  }
  switchTab(status){
    if(status === this.selectedTaskStatusForFilter){
      this.selectedTaskStatusForFilter = null
    }else{
      this.selectedTaskStatusForFilter = status
    }
    
    this.details()
  }
  goBack() {
    this.route.navigateByUrl('work-tracker');
  }
  downloadReport(item) {
    console.log(" val", item);
    let id = item

    this.service.downloadReport(id).subscribe(
      data => {
        var fileName = "report.pdf";
      const contentDisposition = data.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      saveAs(data.body, fileName);
      },
      (err: HttpErrorResponse) => {
        console.log(err)
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message, alertObj.status)
      }
    )
  }
}
