import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';


import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;
  currentPythonToken: BehaviorSubject<any>;
  pythonToken: Observable<any>;
  userRole: BehaviorSubject<any>;
  currentUserName: BehaviorSubject<any>;
  role: Observable<any>;
  userName: Observable<any>;

  constructor(
    private _http: HttpClient,
    private router: Router,
    @Inject("API_URL") public API_URL: any,
    @Inject('API_DET_PYTHON') public API_DET_PYTHON: any,
  ) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentPythonToken = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('pythonToken')));
    this.pythonToken = this.currentPythonToken.asObservable();
    this.userRole = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('role')))
    this.role = this.userRole.asObservable();
    this.currentUserName = new BehaviorSubject<any>(localStorage.getItem('userName'))
    this.userName = this.currentUserName.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get pythonTokenValue(): any {
    return this.currentPythonToken.value
  }
  public get roles(): any {
    return this.userRole.value
  }
  public get getCurrentUserName(): any {
    return this.currentUserName.value
  }
  userAuthentication(obj) {
    let data = new HttpParams()
      .set("username", obj.username)
      .set("password", obj.password)
      .set("grant_type", "password")

    return this._http.post<any>(`${this.API_URL}/oauth/token`, data)
      .pipe(map( data => {
        if (data.access_token !== null) {
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUserSubject.next(data);
          return data;
        }
      }));
  }

  checkProfile() {
    return this._http.get<any>(`${this.API_URL}/clientsuser`).pipe(map(data => {
      // adding username for welcome functionality
      localStorage.setItem('userName', JSON.stringify(data.data.firstName))
      localStorage.setItem('role', JSON.stringify(data.data.role))
      localStorage.setItem('roles', JSON.stringify(data.data.roles))
      this.userRole.next(data.data.role)
      this.currentUserName.next(data.data.firstName)
    }))


  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('pythonToken');
    localStorage.removeItem('role')
    localStorage.removeItem('roles')
    localStorage.removeItem('userName')
    this.currentUserSubject.next(null);
    this.currentPythonToken.next(null)
    this.userRole.next(null)
    this.currentUserName.next(null)
    this.router.navigate(['/login'])
  }

}
