import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkTrackerService } from '../work-tracker/service/work-tracker.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-issue-tracker',
  templateUrl: './issue-tracker.component.html',
  styleUrls: ['./issue-tracker.component.scss']
})
export class IssueTrackerComponent implements OnInit {
  delayedSearch:FormGroup;
  delayedFilter:FormGroup;
  issueTrackVal:any=[];
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public maxSize: number = 9;
  public loading:boolean=false;
  collection: any=[1];
  page:number=1; 
  total:number=0;
  allowedSections=[];

  constructor( private formBuilder: FormBuilder,
              private service: WorkTrackerService,
              private dataService: DataService,
              private router : Router,
              private loader: NgxSpinnerService) { }

  ngOnInit(): void {
    

    let roles=this.dataService.getUserRole();
    let role=roles[0].name;
    console.log("role", role)
      this.delayedSearch = this.formBuilder.group({
        search: ['']
      });
      this.delayedFilter = this.formBuilder.group({
        search: ['']
      });
      this.loader.show()
      this.dataService.getRestrictedSection('issue tracker').subscribe(res => {
        for (let section of res.data) {
          this.allowedSections.push(section.slug)
          switch (section.slug) {
            case 'issue-tracker-clients-list-paginated':
              this.taskList();
              break;
            default:
              break;
          }
        }
        console.log("resss---", this.allowedSections)
        this.loader.hide()
      })
    
    
  }
  delayedSearches(){

  }
  delayedFilters(){
    
  }
  pageChange(event){
    this.page=(event);
    this.taskList();
 }
  taskList(){
    let payload={
      "page": this.page-1,
      "size": 10,
      "order": "DESC",
      "orderBy":"createdDate"
    }
    this.service.issueFilter(payload).subscribe(response=>{
      this.issueTrackVal=response.data.content;
      this.total=response.data.totalElements;
    })
  }
  viewDetails(val){
    this.service.workTrackerAssign(val);
    this.router.navigate(['issue-tracker/details/'+val]);
  }

}



