import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';

//  Roles with ids
//  342  , processor
//  339	, EXTRACTOR	extractor
//  340	, TRANSFORMER	transformer
//  341	, QA	qa
//  16980, da 
//  337	, SUPER ADMIN	super-admin
//  338	, ADMIN	admin



const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'datasource-history',
    loadChildren: () => import('src/app/components/datasource-log/datasource-log.module').then(m => m.DatasourceLogModule),
    canActivate: [AuthGuard],
    data: { roles: [338, 337] }
  },
  {
    path: 'er-dashboard',
    loadChildren: () => import('src/app/components/er-dashboard/er-dashboard.module').then(m => m.ErDashboardModule),
    canActivate: [AuthGuard],
    data: { roles: [338, 337] }
  },
  {
    path: 'det',
    loadChildren: () => import('src/app/components/data-extraction/data-extraction.module').then(m => m.DataExtractionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'data-visualisation',
    loadChildren: () => import('src/app/components/data-visualisation/data-visualisation.module').then(m => m.DataVisualisationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-team',
    loadChildren: () => import('src/app/components/manage-team/manage-team.module').then(m => m.ManageTeamModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'apache-airflow',
    loadChildren: () => import('src/app/components/apache-airflow/apache-airflow.module').then(m => m.ApacheAirflowModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-datasource',
    loadChildren: () => import('src/app/components/datasource-management/datasource-management.module').then(m => m.DatasourceManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: () => import('src/app/components/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'datasource-hub',
    loadChildren: () => import('src/app/components/datasource-hub/datasource-hub.module').then(m => m.DatasourceHubModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'work-tracker',
    loadChildren: () => import('src/app/components/work-tracker/work-tracker.module').then(m => m.WorkTrackerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'issue-tracker',
    loadChildren: () => import('src/app/components/issue-tracker/issue-tracker.module').then(m => m.IssueTrackerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'qa-testing',
    loadChildren: () => import('src/app/components/qa-manage/qa-manage.module').then(m => m.QaManageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'merge-test',
    loadChildren: () => import('src/app/components/merge-test/merge-test.module').then(m => m.MergeTestModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
