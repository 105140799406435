import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IssueTrackerRoutingModule } from './issue-tracker-routing.module';
import { IssueTrackerComponent } from './issue-tracker.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DetailsComponent } from './details/details.component';


@NgModule({
  declarations: [
    IssueTrackerComponent,
    DetailsComponent
  ],
  imports: [
    CommonModule,
    IssueTrackerRoutingModule,
    NgxPaginationModule,
    ReactiveFormsModule
  ]
})
export class IssueTrackerModule { }
