import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, NavigationExtras } from "@angular/router";
import{ToastrService}from 'ngx-toastr';
import{DataService} from '../../services/data.service';
import{AuthService} from '../../services/auth.service';
import{Location} from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md'
@Component({
  selector: 'app-dataconfigs',
  templateUrl: './dataconfigs.component.html',
  styleUrls: ['./dataconfigs.component.scss']
})
export class DataconfigsComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  sourceconfig: Array<any>=[];
  updateConfig: any[];
  allAttributes: any;
  
  selectedItems: Array<any> = [];
  dropdownSettings: any = {};
  dropdownList: Array<any> = [];
  cleaners: any[];
  closeResult: string;
  dataSrc: any;
  config: any;
  category: any;
  allCategory: any;
  cat: any;
  id: any;
  searchText: string = '';
  pageSize:number=25
  page:number=0;
  total: any;
  menuRoutes=[
    {route:"/attributes",name:"Attributes"},
    {route:"/cleaners",name:"Cleaners"},
    {route:"/dataconfigs",name:"Data Configs"},
    {route:"/client-registration",name:"Register User"},
    {route:"/erhome",name:"Resolve Entity"}
  ];
  selectedMenu:any=this.menuRoutes[2];
  public directionLinks: boolean = true;
    public autoHide: boolean = false;
    public responsive: boolean = false;
    public maxSize: number = 9;
  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
    private toaster:ToastrService,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    private modalService:NgbModal,
    private cdRef: ChangeDetectorRef
  ) { }
  @HostListener('input') oninput() {
    this.searchItems();
}
  ngOnInit() {
    this.getAllData();
    this.getCategory();
    this.getProperty();
    this.getCleanersList();
  
  
  this.dropdownSettings = {
    singleSelection: false,
    idField: 'absolutePath',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 300,
    
    
  };

  }
  getAllData(page=this.page,size=this.pageSize,search=this.searchText){
    let abc={
      "page" : page,
      "size" : size,
      "order" : "ASC",
      "orderBy" : "createdDate",
      "name":search
  }
  this.spinner.show()
  this.data.getallsourceconfig(abc).subscribe(
    data=>{
      console.log(data)
      this.spinner.hide()
      this.sourceconfig=data['data']['content']
      this.total=data['data'].totalElements
      console.log(this.total)
      this.page=data['data']['pageable'].pageNumber
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      this.spinner.hide()
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
      
    }
  )
  
  }
  
 getCategory(){
  this.data.getCategory().subscribe(
    data=>{
      console.log(data)
      this.allCategory=data['data']
      
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
      
    }
  )
 }
 getProperty(){
  this.data.getProperty(null).subscribe(
    data=>{
      console.log(data)
      this.allAttributes=data['data']
      console.log(this.allAttributes)
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
    }
  )
 }
 getCleanersList(){
  this.data.getCleanersList().subscribe(
    data=>{
      console.log(data)
      this.cleaners=data['data']
      // let abc=this.cleaners.map((o)=>o.absolutePath)
      // // this.cleaners=data.data.absolutePath
      // console.log(abc)
      // console.log(this.cleaners)
      // this.dropdownList=abc
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
    }
  )
 }
 pagec(event){
   console.log(event)
let abc=event-1
this.getAllData(abc,this.pageSize,this.searchText)
 }
  onItemChange(item:any){
    console.log(item)
    this.spinner.show()
    this.data.getCleanersInfoByAttribute(item).subscribe(
      data=>{
        this.spinner.hide()
        this.cleaners=data['data']
        let abc=this.cleaners.map((o)=>o.absolutePath)
        // this.cleaners=data.data.absolutePath
        console.log(abc)
        console.log(this.cleaners)
        this.dropdownList=abc
      },
      (err:HttpErrorResponse)=>{
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )

  }
  increaseSize(event){
    this.getAllData(this.page,event,this.searchText)
  }
  onClickEdit(event,content,item){
    
    let srcSlug = item.dataSource.slug
  
    console.log(srcSlug); 
    this.data.getsourceconfig(srcSlug).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data)
          
          this.updateConfig=data['data']['config']['properties']
          this.config=data['data']['config']
          console.log('cat',this.config)
          this.cat=this.config['category']
          console.log("cato",this.cat)
          // this.cat=this.config['']
          this.dataSrc=data['data']['dataSource']
          this.id=data['data'].id
          console.log(this.id)
          console.log('src',this.dataSrc)
          this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'xl' ,backdrop:'static'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
          
          console.log(this.updateConfig)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  remove(id: any) {
    // this.awaitingPersonList.push(this.personList[id]);
    this.updateConfig.splice(id, 1);
  }

  add() {
    this.updateConfig.push({name:'',cleaner:[]})
    console.log(this.updateConfig)
      
  }
  onSubmit(){
    console.log(this.updateConfig)
    let updated={"id":this.id,"dataSource":this.dataSrc,"config":{"baseImgUrl":this.config.baseImgUrl,"employer":this.config.employer,"category":this.cat,"entityType":this.config.entityType,"riskLevel":this.config.riskLevel,"summary":this.config.summary,"properties":this.updateConfig,"nationality":this.config.nationality}}
    console.log(updated)
    // console.log(updated)
    this.spinner.show()
    this.data.updateSourceConfig(updated).subscribe(
      data=>{
        if(data.statusCode==200){
          this.spinner.hide()
          this.modalService.dismissAll()
          console.log(data)
        }
        
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  searchItems() {
    this.getAllData(this.page,this.pageSize,this.searchText)
    // const prev = this.mdbTable.getDataSource();
    // if (!this.searchText) {
    //     this.mdbTable.setDataSource(this.previous);
    //     this.elements = this.mdbTable.getDataSource();
    // }
    // if (this.searchText) {
    //     this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
    //     this.mdbTable.setDataSource(prev);
    // }
  }  
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  // goToMenu(){
  //   //  console.log("menu value: ",this.selectedMenu);
  //    this.router.navigate([this.selectedMenu.route])
  //  }
  logout(){
    this.auth.logout();
  }
  // goback(){
  //   this.location.back();
  // }
}
