<div class="content">
    <div class="row" *ngIf="allowedSections.indexOf('issue-tracker-clients-list-paginated') !== -1">
        <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedSearch">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedSearches()" formControlName="search" placeholder="Search Team Members here." />
                    <div class="iconStyle">
                        <i class="fa fa-search"></i>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedFilter">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedFilters()" formControlName="search" placeholder="Filter" />
                    <div class="iconStyle">
                        <i class="fa fa-filter"></i>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="card">
            <table class="table sm-view">
                <thead>
                    <tr>
                        <th>Datasource Name</th>
                        <th>Raised By</th>
                        <th>Assigned To</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    <ng-container *ngIf="issueTrackVal?.length!==0">
                        <tr class="text-card" (click)="viewDetails(content.id)" *ngFor="let content of issueTrackVal | paginate: { itemsPerPage: 10, currentPage: page ,totalItems: total }">
                            <td style="vertical-align: middle;">{{(content.task.job.name?content.task.job.name:'')}}</td>
                            <td style="vertical-align: middle;">
                                {{(content.issueAssignedBy.firstName?content.issueAssignedBy.firstName:'')+' '+(content.issueAssignedBy.middleName?content.issueAssignedBy.middleName:'')+' '+(content.issueAssignedBy.lastName?content.issueAssignedBy.lastName:'')}}
                            </td>
                            <td style="vertical-align: middle;">
                                {{(content.issueAssignedTo.firstName?content.issueAssignedTo.firstName:'')+' '+(content.issueAssignedTo.middleName?content.issueAssignedTo.middleName:'')+' '+(content.issueAssignedTo.lastName?content.issueAssignedTo.lastName:'')}}
                            </td>
                            <!-- <td style="vertical-align: middle;">{{(content.task.taskAssignedBy.firstName?content.task.taskAssignedBy.firstName:'')+' '+(content.task.taskAssignedBy.middleName?content.task.taskAssignedBy.middleName:'')+' '+(content.task.taskAssignedBy.lastName?content.task.taskAssignedBy.lastName:'')}}</td>
                                <td style="vertical-align: middle;">{{(content.task.taskAssignedTo.firstName?content.task.taskAssignedTo.firstName:'')+' '+(content.task.taskAssignedTo.middleName?content.task.taskAssignedTo.middleName:'')+' '+(content.task.taskAssignedTo.lastName?content.task.taskAssignedTo.lastName:'')}}</td>
                                 -->
                            <td style="vertical-align: middle;">{{content?.issueStatus}}</td>

                        </tr>

                    </ng-container>

                </tbody>
                <tfoot *ngIf="issueTrackVal?.length!==0">
                    <tr>
                        <td colspan="4">
                            <pagination-controls [maxSize]="maxSize" [directionLinks]="directionLinks" directionLinks="true" previousLabel="Previous" nextLabel="Next" (pageChange)="pageChange($event)"></pagination-controls>
                        </td>
                    </tr>
                </tfoot>

            </table>
            <ng-container *ngIf="issueTrackVal?.length===0">
                <h5 class="text-center">No Data Found</h5>
            </ng-container>
        </div>
    </div>
</div>