<div class="content">
    <div class="row">
        <div class="col-12">
            <h5 class="new">
                <a>
                    <i class="fa fa-arrow-left" (click)="goBack()" style="cursor: pointer;"><span
                            style="margin-left: 20px; font-family: Arial">Recurring Job Status</span></i>
                </a>
            </h5>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedSearch">
                <div class="filterStyles">
                    <input type="search" (keyup)="delayedSearches()" formControlName="search" placeholder="Search Recurring Jobs" />
                    <div class="iconStyle">
                        <i class="fa fa-search"></i>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 common">
            <form [formGroup]="delayedFilter">
                <div class="filterStyles">
                    <select (change)="delayedFilters()" formControlName="filterStatus" class="fullWidth status-filter">
                        <option [value]='null'>Select Status</option>
                        <option value="PASSED">PASSED</option>
                        <option value="FAILED">FAILED</option>
                    </select>
                    <!-- <input type="search" (keyup)="delayedFilters()" formControlName="search" placeholder="Filter" />
                    <div class="iconStyle">
                        <i class="fa fa-filter"></i>
                    </div> -->
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="card">
            <table class="table sm-view">
                <thead>
                    <tr>
                        <!-- <th scope="col">ID</th> -->
                        <th scope="col" style="width: 30%;">Datasource Name</th>
                        <!-- <th scope="col">Recurring Job Detail</th> -->
                        <th scope="col" style="width: 100px;">Status</th>
                        <th scope="col" style="width: 20%;">Error Message</th>

                        <!-- <th scope="col">Count Post Cleaning</th> -->
                        <th scope="col" style="width: 140px;">Total Past Count</th>
                        <th scope="col" style="width: 135px;">Total New Count</th>
                        <th scope="col" style="width: 105px;">Total Count</th>
                        <th scope="col" style="width: 125px;">Total Changed</th>
                        <th scope="col" style="width: 120px;">Updated Date</th>
                        <th scope="col" style="width: 110px;">New Entries</th>
                    </tr>
                </thead>

                <tbody>
                    <ng-container>
                        <tr class="text-card" *ngFor="let content of recurrentJobDetails | paginate: { itemsPerPage: 10, currentPage: page ,totalItems: total }">
                            <!-- <td style="vertical-align: middle;">{{content.id}}</td> -->
                            <td style="vertical-align: middle;">{{content.recurringJobDetail.dataSource.name}}</td>
                            <!-- <td style="vertical-align: middle;" ><p style="float: left;vertical-align:center">{{content.recurringJobDetail.lastIUStatus?content.recurringJobDetail.lastIUStatus:"-"}}</p ><p style="vertical-align:center;float: right;">{{content.recurringJobDetail.dataSource.frequency}}</p></td> -->
                            <td style="vertical-align: middle;" [ngStyle]="{ 'color' : (content.status === 'DELAYED') ? '#b70000' : (content.status === 'ASSIGNED' ||content.status === 'COMPLETED_ON_DEV') ? '#00a510' : (content.status === 'PASSED') ? '#0094FF': '#E54C0A' }">
                                {{content.status}}</td>
                            <td style="vertical-align: middle;">
                                <ng-container *ngIf="content.errorMessage.length === 0 else errorMesg">-</ng-container>
                                <ng-template #errorMesg>
                                    <div style=" overflow: auto;
                                            white-space: nowrap; 
                                            border: 1px solid rgb(229, 76, 10);
                                       
                                            background: #fff;
                                            padding:5px 10px 0px 10px">
                                        <p style="display: inline-block;">{{content.errorMessage}}</p>
                                    </div>

                                </ng-template>
                            </td>

                            <!-- <td style="vertical-align: middle;">{{content.totalCountAfterCleaning}}</td> -->
                            <td style="vertical-align: middle;">{{content.totalPastCount}}</td>
                            <td style="vertical-align: middle;">{{content.totalNewCount}}</td>
                            <td style="vertical-align: middle;">{{content.totalCount}}</td>
                            <td style="vertical-align: middle;">{{content.totalChanged}}</td>
                            <td style="vertical-align: middle;">{{content.createdDate | date:'mediumDate'}}</td>
                            <td style="vertical-align: middle;">
                                <ng-container *ngIf="content.newNames.length > 0">
                                    <button (click)='openDialog(jobDetails); setJobData(content);' type="button" mdbBtn rounded="true" size="sm" mdbTooltip="View Details" placement="down" mdbWavesEffect class="btn button-blue"><i class="fa fa-info-circle" style="font-size: 16px;"></i></button>

                                </ng-container>
                                <ng-container *ngIf="content.newNames.length === 0">
                                    <p>-</p>
                                </ng-container>

                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot *ngIf="recurrentJobDetails?.length !== 0">
                    <tr>
                        <td colspan="10">
                            <pagination-controls [maxSize]="maxSize" [directionLinks]="directionLinks" directionLinks="true" previousLabel="Previous" nextLabel="Next" (pageChange)="pageChange($event)"></pagination-controls>
                        </td>
                    </tr>
                </tfoot>

            </table>
            <ng-container *ngIf="recurrentJobDetails?.length === 0">
                <h5 class="text-center">No Data Found</h5>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #jobDetails class="modal-xl" let-modal>
    <div class="modal-header" style="padding: 1rem; border:none">
        <h4 class="modal-title fullWidth text-center" id="modal-basic-title">New Entries List</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="row modal-body">
    </div>
    <div class="row">
        <div class="col-md-12 mx-auto mb-2 inputGroup">
            <div class="row" style="padding:10px 50px;">
                <div class="col-lg-6  col-xs-12 common">
                    <h6 class="sub-heading">Aml Data Source Id</h6>
                    <p class="sub-items"> {{selectedJobData?.recurringJobDetail?.dataSource?.amlDataSourceId}}</p>
                </div>

                <div class="col-lg-6  col-xs-12 common">
                    <h6 class="sub-heading">Data Source Name</h6>
                    <p class="sub-items">
                        {{selectedJobData?.recurringJobDetail?.dataSource?.name}}
                    </p>
                </div>
                <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 common">
                    <h6 class="sub-heading">Total SIP</h6>
                    <p class="sub-items">
                        {{overallStatusValues?.category[3]?.doc_count?overallStatusValues.category[3].doc_count:'-'}}
                    </p>
                </div> -->
            </div>
            <ng-container *ngIf="newNamesArr.length !== 0">
                <div class="row" style="padding:10px 50px;">
                    <table class="table table-bordered table-responsive-md table-striped text-center" mdbTable responsive="true">
                        <thead class="thead-light">
                            <tr>
                                <th style="width: 10vh;">#</th>
                                <th style="width: 30vh">Name</th>
                                <th>GUID</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr mdbTableCol *ngFor="let item of newNamesArr;let i = index">
                                <td>{{i+1}}</td>
                                <td>
                                    <span style="word-break: break-word;">{{item.name}}</span>

                                </td>
                                <td>
                                    <span style="word-break: break-word;">{{item.guid}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>