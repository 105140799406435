import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(
    private authService:AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: `application/json`
    };
    const val =this.authService.currentUserValue
    const pythonToken = this.authService.pythonTokenValue || null
    const isLoggedIn =val && val.access_token;

    // condition for python endpoint  old value == 15.206.141.8
    if(request.url.indexOf("http://34.142.77.139:8701")==0){
      //console.log("this is request", request);
      headersConfig["Content-Type"] =`application/json`;
      // headersConfig[
      //   "Access-Control-Allow-Origin"
      // ] ='*';

      // for loggedin case use access_token recieved from be else use constant auth and form content-type
      if(pythonToken){
        headersConfig[
          "Authorization"
        ] = `Bearer ${pythonToken.access_token}`;
      }
      else{
        headersConfig[
          "Authorization"
        ] = `!@#$VGYJK*(&^%3%546JK$%6`;
        headersConfig["Content-Type"] =`application/x-www-form-urlencoded`;
        headersConfig[
          "Access-Control-Allow-Origin"
        ] ='*'
      }
      
      
      console.log("this is request", request);

    }
    
    
    else if (request.url.indexOf("oauth/token")>0) {
      headersConfig["Content-Type"] =`application/x-www-form-urlencoded`;
      
      headersConfig[
        "Authorization"
      ] = `Basic NzQ1NGFhNDU2NjczMmQzOGRlNDhlNDE3NmFhOGQ2MzFjMmZiNWE0MzIwMjE5ZGRlNzQzZDJlNjFjNzUwODk5MjpiMGM3OTJhNGZkMWU3ZDVhZDNhMjliY2JiN2FiM2I2YWY0NzQ3OTVmMmJlNzU1NGU3ZDE5YjY1NGY4OGRmYWQw`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
    } 
    
    else if(isLoggedIn && request.url.indexOf("/download/extractedFile")>0){
      //console.log('new');
      headersConfig["Content-Type"] = `application/json`;
      headersConfig["Response-Type"] = `blob`;
      headersConfig["Authorization"]=`Bearer ${val.access_token}`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
      
    }
    else if(isLoggedIn && request.url.indexOf("/clientsuser/")>0){
      //console.log('new');
      
      headersConfig["Authorization"]=`Bearer ${val.access_token}`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
      
    }
    else if(isLoggedIn && request.url.indexOf("/categories")>0){
      //console.log('new');
      
      headersConfig["Authorization"]=`Bearer ${val.access_token}`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
      
    }
   
    
    else if(isLoggedIn && request.url.indexOf("/v1/")>0){
      //console.log('new');
      
      headersConfig["Authorization"]=`Bearer ${val.access_token}`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
      
    }
    else if(isLoggedIn && request.url.indexOf("/datasource/search")>0){
      //console.log('new');
      
      headersConfig["Authorization"]=`Bearer ${val.access_token}`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
      
    }
    else if(isLoggedIn){
      //console.log('new', val.access_token);
      
      headersConfig["Authorization"]=`Bearer ${val.access_token}`;
      headersConfig[
        "Access-Control-Allow-Origin"
      ] ='*'
      
    }
    request = request.clone({
      setHeaders: headersConfig
    });
      //console.log(request)
        return next.handle(request).pipe(
          catchError((err: HttpErrorResponse)=>{
            if (err instanceof HttpErrorResponse) {
              console.log('Processing http error', err);
              if (err && err['status'] == 401) {
                  // this._data.toastrShow('Please Log In to Continue', 'error');
                  // this._data.removeAllCookie();
                  this.router.navigate(['/login']);
              }
          }

            return throwError(err);
          })
        )
    
  }
}
