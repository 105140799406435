<div class="content">
    <div class="row">
        <ng-container *ngIf="allowedSections.indexOf('dashboard-ongoing') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 common">

                <div class="card cardFixed">
                    <h5 class="heading">Ongoing</h5>
                    <div class="row">
                        <div class="col-11 mt-3">
                            <canvas *ngIf="ongooingPieChartData[0]!=0" baseChart [data]="ongooingPieChartData" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [labels]="pieChartLabels" [colors]="chartColors">
                            </canvas>
                        </div>
                    </div>
                    <h6 *ngIf="ongooingPieChartData[0]==0 " class="text-center">No Ongoing Data Found</h6>
                    <h6 *ngIf="ongooingPieChartData[0]!=0 ">Total:{{ongooingPieChartData[0]}}</h6>

                </div>


            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-delayed') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Delayed</h5>
                    <div class="row">
                        <div class="col-11 mt-3">
                            <canvas *ngIf="delayedPieChartData[0]!=0 " baseChart [data]="delayedPieChartData" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [labels]="pieChartLabels" [colors]="chartColors">
                            </canvas>
                        </div>
                    </div>
                    <h6 *ngIf="delayedPieChartData[0]==0 " class="text-center">No Delayed Data Found</h6>
                    <h6 *ngIf="delayedPieChartData[0]!=0 ">Total:{{delayedPieChartData[0]}}</h6>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-waiting') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Logs</h5>
                    <div class="row">
                        <div class="col-11 mt-3">
                            <canvas *ngIf="logsPieChartData[0]!=0 " baseChart [data]="logsPieChartData" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [labels]="pieChartLabels" [colors]="chartColors">
                            </canvas>
                        </div>
                    </div>
                    <h6 *ngIf="logsPieChartData[0]===0 " class="text-center">No Logs Found</h6>
                    <h6 *ngIf="logsPieChartData[0]!=0 ">Total:{{logsPieChartData[0]}}</h6>

                </div>

            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-completed') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Completed</h5>
                    <div class="row">
                        <div class="col-11 mt-3">
                            <canvas *ngIf="completedPieChartData[0]!=0  " baseChart [data]="completedPieChartData" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [labels]="pieChartLabels" [colors]="chartColors">
                            </canvas>
                        </div>
                    </div>
                    <h6 *ngIf="completedPieChartData[0]===0 " class="text-center">No Completed Data Found</h6>
                    <h6 *ngIf="completedPieChartData[0]!=0  ">Total:{{completedPieChartData[0]}}</h6>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-waiting') !== -1">

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Waiting</h5>

                    <div class="row">
                        <div class="col-11 mt-3">
                            <canvas *ngIf="waitingPieChartData[0]!=0 " baseChart [data]="waitingPieChartData" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [labels]="pieChartLabels" [colors]="chartColors">
                            </canvas>
                        </div>
                    </div>
                    <h6 *ngIf="waitingPieChartData[0]==0 " class="text-center">No Waiting Data Found</h6>
                    <h6 *ngIf="waitingPieChartData[0]!=0 ">Total:{{waitingPieChartData[0]}}</h6>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-upcoming-work') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Upcoming Work</h5>
                    <div class="row" *ngIf="upcomingValues?.length !== 0">
                        <div class="col-6">
                            <p class="subhead">Datasource Name</p>
                        </div>
                        <div class="col-6">
                            <p class="subhead">Process</p>
                        </div>
                    </div>
                    <div *ngFor="let content of upcomingValues" class="text-card ">
                        <div class="row">
                            <div class="col-6">{{content.job.dataSource.name}}</div>
                            <div class="col-6">
                                <ng-container [ngSwitch]="content.process?.name">
                                    <ng-container *ngSwitchCase="'SUPER ADMINSTRATION'">
                                        <button class="btn btn-super-admin btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Adminstration'">
                                        <button class="btn btn-admin btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Extraction'">
                                        <button class="btn btn-extractor btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Transformation'">
                                        <button class="btn btn-transformer btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'QA'">
                                        <button class="btn btn-qa btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Processing'">
                                        <button class="btn btn-processor btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-container *ngIf="upcomingValues?.length === 0">
                            <h6 class="text-center">No Data Found</h6>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="upcomingValues?.length === 0">
                        <h6 class="text-center">No Data Found</h6>
                    </ng-container>
                    <a *ngIf="upcomingValues?.length>0" class="openLink" routerLink="upcoming-work">View More</a>

                </div>

            </div>

        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-delayed-work') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Delayed Work</h5>
                    <div class="row" *ngIf="delayedValues?.length !== 0">
                        <div class="col-6">
                            <p class="subhead">Datasource Name</p>
                        </div>
                        <div class="col-6">
                            <p class="subhead">Process</p>
                        </div>
                    </div>
                    <div *ngFor="let content of delayedValues" class="text-card ">
                        <div class="row">
                            <div class="col-6">{{content.job.dataSource.name}}</div>
                            <div class="col-6">
                                <ng-container [ngSwitch]="content.process?.name">
                                    <ng-container *ngSwitchCase="'SUPER ADMINSTRATION'">
                                        <button class="btn btn-super-admin btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Adminstration'">
                                        <button class="btn btn-admin btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Extraction'">
                                        <button class="btn btn-extractor btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Transformation'">
                                        <button class="btn btn-transformer btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'QA'">
                                        <button class="btn btn-qa btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Processing'">
                                        <button class="btn btn-processor btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="delayedValues?.length === 0">
                        <h6 class="text-center">No Data Found</h6>
                    </ng-container>

                    <a *ngIf="delayedValues?.length>0" class="openLink" routerLink="delayed-work">View More</a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-unassigned-work') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Unassigned Datasources</h5>
                    <div class="row" *ngIf="unassignedValues?.length !== 0">
                        <div class="col-6">
                            <p class="subhead">Datasource Name</p>
                        </div>
                        <div class="col-6">
                            <p class="subhead">Process</p>
                        </div>
                    </div>
                    <div *ngFor="let content of unassignedValues" class="text-card ">
                        <div class="row">
                            <div class="col-6">{{content.job.dataSource.name}}</div>
                            <div class="col-6">
                                <ng-container [ngSwitch]="content.process?.name">
                                    <ng-container *ngSwitchCase="'SUPER ADMINSTRATION'">
                                        <button class="btn btn-super-admin btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Adminstration'">
                                        <button class="btn btn-admin btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Extraction'">
                                        <button class="btn btn-extractor btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Transformation'">
                                        <button class="btn btn-transformer btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'QA'">
                                        <button class="btn btn-qa btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Processing'">
                                        <button class="btn btn-processor btn-sm">{{ content.process?.name }}</button>
                                        <div class="vl"></div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="unassignedValues?.length === 0">
                        <h6 class="text-center">No Data Found</h6>
                    </ng-container>
                    <a *ngIf="unassignedValues?.length>0" class="openLink" routerLink="unassigned-datasources">View
                        More</a>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('dashboard-recurring-job-status') !== -1">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 common">
                <div class="card cardFixed">
                    <h5 class="heading">Recurring Job Status</h5>
                    <div class="row">
                        <div class="col-6 common">
                            <h6 class="sub-heading success">Success</h6>
                            <p class="sub-items">{{recurringValues.successCount}}</p>
                        </div>
                        <div class="col-6 common">
                            <h6 class="sub-heading failure">Failed</h6>
                            <p class="sub-items"> {{recurringValues.failedCount}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 common">
                            <h6 class="sub-heading">Total Records</h6>
                            <p class="sub-items"> {{recurringValues.totalRecordsCount}}</p>
                        </div>
                        <div class="col-6 common">
                            <h6 class="sub-heading">Total Changes</h6>
                            <p class="sub-items"> {{recurringValues.totalChangesCount}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 common">
                            <h6 class="sub-heading">Total New Records</h6>
                            <p class="sub-items"> {{recurringValues.totalNewRecordsCount}}</p>
                        </div>
                        <div class="col-6 common">
                            <h6 class="sub-heading">Total Datasources</h6>
                            <p class="sub-items"> {{recurringValues.totalDataSourcesCount}}</p>
                        </div>


                    </div>
                    <a class="openLink" routerLink="recurring-job-status">View More</a>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="allowedSections.indexOf('dashboard-overall-status') !== -1">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 common">
                <div class="card">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h5 class="heading">Overall Status </h5>
                        </div>
                        <div class="col-4"></div>
                    </div>
                    <div class="row">
                        <div *ngFor="let item of overallStatusValues.type" class=" col-lg-3 col-md-6 col-sm-6 col-xs-12 common ">
                            <h6 class="sub-heading ">Total {{item.key}}</h6>
                            <p class="sub-items ">
                                {{item.doc_count || '-'}}
                            </p>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 common ">
                            <h6 class="sub-heading ">Total Entities</h6>
                            <p class="sub-items "> {{totaEntitiesCount}}
                            </p>
                        </div>
                        <div *ngFor="let category of categoryOverallStatusValues" class="col-lg-3 col-md-6 col-sm-6 col-xs-12 common ">
                            <h6 class="sub-heading ">Total {{category.key}} Entities</h6>
                            <p class="sub-items ">
                                {{category.doc_count || '-'}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 common ">
                            <h6 class="sub-heading ">Total Datasources
                            </h6>
                            <p class="sub-items ">{{overallStatusValues?.source?.length}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="allowedSections.indexOf( 'dashboard-task-issue-list') !==- 1 ">
        <div class="row ">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 common ">
                <div class="card ">
                    <h5 class="heading ">
                        Overall Issues
                    </h5>
                    <table class="table sm-view ">
                        <thead>
                            <tr>
                                <th>Datasource Name</th>
                                <th>Raised By</th>
                                <th>Assigned To</th>
                                <th>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container *ngIf="issueTrackVal?.length!==0 ">
                                <tr class="text-card " *ngFor="let content of issueTrackVal | paginate: { itemsPerPage: 10, currentPage: page ,totalItems: total } ">
                                    <td style="vertical-align: middle; max-width: 40%; ">
                                        {{(content.task.job.name?content.task.job.name:'')}}
                                    </td>
                                    <td style="vertical-align: middle; ">
                                        {{(content.issueAssignedBy.firstName?content.issueAssignedBy.firstName:'')+' '+(content.issueAssignedBy.middleName?content.issueAssignedBy.middleName:'')+' '+(content.issueAssignedBy.lastName?content.issueAssignedBy.lastName:'')}}
                                    </td>
                                    <td style="vertical-align: middle; ">
                                        {{(content.issueAssignedTo.firstName?content.issueAssignedTo.firstName:'')+' '+(content.issueAssignedTo.middleName?content.issueAssignedTo.middleName:'')+' '+(content.issueAssignedTo.lastName?content.issueAssignedTo.lastName:'')}}
                                    </td>

                                    <td style="vertical-align: middle; ">{{content?.issueStatus}}</td>

                                </tr>

                            </ng-container>

                        </tbody>

                    </table>
                    <ng-container *ngIf="issueTrackVal?.length===0 ">
                        <h6 class="text-center ">No Data Found</h6>
                    </ng-container>
                    <div class="row mt-2 " style="padding-left: 10px; ">
                        <a *ngIf="total>0" class="openLink " routerLink="/issue-tracker">View More</a>
                    </div>
                </div>

            </div>
        </div>
    </ng-container>
</div>