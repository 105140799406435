<div class="content">
    <div class="card">
        <form [formGroup]="detailsForm" class="p-5" style="padding-top: 1rem !important">

            <div class="row mt-3">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-1">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Data Source Name*</label>
                        </div>
                        <div class="col-md-8">
                            <input disabled type="text" placeholder="Enter Data Source Name" formControlName="sourceName" class="fullWidth">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Harvesting URL*</label>
                        </div>
                        <div class="col-md-8">
                            <input disabled type="text" placeholder="Enter Harvesting URL" formControlName="harvestingUrl" class="fullWidth">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-1">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Raised By*</label>
                        </div>
                        <div class="col-md-8">
                            <input disabled type="email" placeholder="Enter Email" formControlName="raisedBy" class="fullWidth">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Data URL*</label>
                        </div>
                        <div class="col-md-8">
                            <input disabled type="text" placeholder="Enter Data URL" formControlName="dataUrl" class="fullWidth">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-1">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Issue Start Date*</label>
                        </div>
                        <div class="col-md-8">
                            <input [disabled]="!isAdmin" type="date" placeholder="Choose Start Date" formControlName="startDate" class="fullWidth">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Completion Date*</label>
                        </div>
                        <div class="col-md-8">
                            <input [disabled]="!isAdmin" type="date" placeholder="Choose Completion Date" formControlName="completionDate" class="fullWidth">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-1">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Assigned To*</label>
                        </div>
                        <div class="col-md-8">
                            <input [disabled]="!isAdmin" type="email" placeholder="Enter email" formControlName="assigedTo" class="fullWidth">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="dataLabel text-left fullWidth">Status*</label>
                        </div>
                        <div class="col-md-8">
                            <!-- <input [disabled]="!isAdmin" type="text" placeholder="Enter Status"
                                formControlName="status" class="fullWidth"> -->
                            <select formControlName="status" class="fullWidth">
                                <option class="placeholder" value="" selected disabled>Select</option>
                                <option value="UNASSIGNED">UNASSIGNED</option>
                                <option value="ASSIGNED">ASSIGNED</option>
                                <option value="IN_PROGRESS">IN PROGRESS</option>
                                <option value="DELAYED">DELAYED</option>
                                <option value="COMPLETED">COMPLETED</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-md-1">
                    <label class="dataLabel text-left fullWidth">Probable issue*</label>
                    <textarea [disabled]="!isAdmin" type="text" placeholder="Enter description" formControlName="description" class="fullWidth"></textarea>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 inputGroup position-relative">

                    <label class="dataLabel text-left fullWidth">Comments</label>

                    <textarea formControlName="desc" class="fullWidth" placeholder="Enter Comments">
                    </textarea>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-3 md-2 m-auto mb-2 mt-2">
                    <button class="btn button button-green " (click)="updateChanges()">Update Changes </button>
                </div>
            </div>
        </form>

    </div>

</div>

<div class="content">
    <div class="card">
        <table class="table sm-view">
            <thead>
                <tr>
                    <th>Comment</th>
                    <th>Raised By</th>
                    <th>Date</th>
                    <th>Time</th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngIf="commentVal?.length!==0">
                    <tr class="text-card" *ngFor="let content of commentVal | paginate: { itemsPerPage: 3, currentPage: page ,totalItems: total }">
                        <td style="vertical-align: middle;">{{(content.comment?content.comment:'-')}}</td>
                        <td style="vertical-align: middle;">{{content.commentedBy.email}}</td>
                        <td style="vertical-align: middle;">{{content.createdDate.split('T')[0]}}</td>
                        <td style="vertical-align: middle;">{{content.createdDate.split('T')[1]}}</td>

                    </tr>

                </ng-container>

            </tbody>
            <tfoot *ngIf="commentVal?.length!==0">
                <tr>
                    <td colspan="4">
                        <pagination-controls [maxSize]="maxSize" [directionLinks]="directionLinks" directionLinks="true" previousLabel="Previous" nextLabel="Next" (pageChange)="pageChange($event)">
                        </pagination-controls>
                    </td>
                </tr>
            </tfoot>

        </table>
        <ng-container *ngIf="commentVal?.length===0">
            <h5 class="text-center">No Comment Found</h5>
        </ng-container>
    </div>
</div>