import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public dataExtraction = new BehaviorSubject<any>('');
    extraction(value){
        this.dataExtraction.next(value);
    }
    
  constructor(
    private _http: HttpClient,
    private toastr: ToastrService,
    @Inject("API_URL") public API_URL: any
  ) { }
  
  postSelectors(Requirement) {
    return this._http.post<any>(`${this.API_URL}/v1/data-sources/generate-selectors`,Requirement)
    
  }
  getSelectors(url:string){
    return this._http.get<any>(`${this.API_URL}/v1/data-sources/selectors/?id=${url}`)
  }
  updateSelectors(newdata:Object,url:string) {
    return this._http.put<any>(`${this.API_URL}/v1/data-sources/update-selectors?id=${url}`,newdata)
    
  }
  statusUpdate(){
    return this._http.put<any>(`${this.API_URL}/dashboard/v1/update-status`, undefined)

  }
  putPagination(model,url:string){
    return this._http.put<any>(`${this.API_URL}/v1/data-sources/update-pagination?id=${url}`,model)

  }
  postExtraction(url:string){
    return this._http.post<any>(`${this.API_URL}/v1/data-sources/extract?id=${url}`,null)
  }
  getExtractedData(url:string){
    return this._http.get<any>(`${this.API_URL}/v1/data-sources/extracted-data?id=${url}`)
  }
  getUserRole(){
    return JSON.parse(localStorage.getItem('roles'))
  }
  getextractedpath(url:string){
    return this._http.get<any>(`${this.API_URL}/v1/data-sources/extracted-data-file-path?id=${url}`)
  }
  getfile(path:string){
    return this._http.get<any>(`${this.API_URL}/download/extractedFile?filePath=${path}`)
  }
  getProperty(obj){
    return this._http.get<any>(`${this.API_URL}/v1/aml-attributes/all`)
  }
  addProperty(obj){
    return this._http.post<any>(`${this.API_URL}/v1/aml-attributes/add`,obj)

  }
  verifySelector(verify){
    return this._http.post<any>(`${this.API_URL}/v1/data-sources/test-css-selector`,verify)
  }
  getImageUrl(obj){
    return this._http.get<any>(`${this.API_URL}/v1/data-sources/get-screenshot-url?id=${obj}`)

  }
  getImageSize(obj){
    return this._http.get<any>(`${this.API_URL}/v1/data-sources/labelled-image-info?id=${obj}`)

  }
  updateLabels(id,body){
    return this._http.put<any>(`${this.API_URL}/v1/data-sources/save-labelled-data?id=${id}`,body)

  }
  getAllSource(obj){
    return this._http.post<any>(`${this.API_URL}/v1/data-sources/all-source`,obj)


  }
  searchSourceAdmin(obj){
    return this._http.post<any>(`${this.API_URL}/datasource/search`,obj)

  }
  getAllAttribueFilter(obj){
    return this._http.post<any>(`${this.API_URL}/v1/aml-attributes/all-attributes`,obj)

  }
  getCleanersInfo(obj){
    return this._http.post<any>(`${this.API_URL}/v1/cleaners/info`,obj)

  }
  getCleanersInfoByAttribute(obj){
    return this._http.get<any>(`${this.API_URL}/v1/cleaners/info/attribute/${obj}`)

  }
  createCleaner(obj){
    return this._http.post<any>(`${this.API_URL}/v1/cleaners/info/create`,obj)
  }
  updateCleaner(obj){
    return this._http.put<any>(`${this.API_URL}/v1/cleaners/info/update`,obj)

  }
  getCleanerByName(obj){
    return this._http.get<any>(`${this.API_URL}/v1/cleaners/info/name/${obj}`)

  }
  getCleanersList(){
    return this._http.get<any>(`${this.API_URL}/v1/cleaners/info/list`)

  }
  getAttributeById(obj){
    return this._http.get<any>(`${this.API_URL}/v1/aml-attributes/get-by-id?id=${obj}`)

  }
  updateAttribute(obj,id){
    return this._http.put<any>(`${this.API_URL}/v1/aml-attributes/update?id=${id}`,obj)

  }
  getallsourceconfig(obj){
    return this._http.post<any>(`${this.API_URL}/v1/datasource/config`,obj)

  }
  createConfig(obj){
    return this._http.post<any>(`${this.API_URL}/v1/datasource/config/create`,obj)
  }
  getsourceconfig(obj){
    return this._http.get<any>(`${this.API_URL}/v1/datasource/config/datasource/${obj}`)

  }
  updateSourceConfig(obj){
    return this._http.put<any>(`${this.API_URL}/v1/datasource/config/update`,obj)

  }
  editsource(obj){
    return this._http.get<any>(`${this.API_URL}/v1/data-sources/selectors/?id=${obj}`)

  }
  getCategory(){
    return this._http.get<any>(`${this.API_URL}/categories/list`)

  }
  updateDataSource(obj){
    return this._http.put<any>(`${this.API_URL}/v1/data-sources/update-datasource`,obj)

  }
  createUser(obj){
    return this._http.post<any>(`${this.API_URL}/clientsuser/register`,obj)

  }
  setUpAccount(obj){
    return this._http.put<any>(`${this.API_URL}/clientsuser/setupaccount`,obj)

  }
  getEntityByFilter(){
    return this._http.get<any>(`${this.API_URL}/v1/er/filter`)
  }
  searchErSource(obj){
    return this._http.post<any>(`${this.API_URL}/v1/er/search`,obj)
  }
  manualResolve(obj){
    return this._http.post<any>(`${this.API_URL}/v1/er/manual/resolve`,obj)
  }
  toastrShow(message: any, type: any) {
    this.toastr.clear();
    switch (type) {
      case "INFO": {
        this.toastr.info("", message, { timeOut: 5000,positionClass:'toast-top-right' });
        break;
      }
      case "ERROR": {
        this.toastr.error("", message, { timeOut: 5000,positionClass:'toast-top-right' });
        break;
      }
      case "SUCCESS": {
        this.toastr.success("", message, { timeOut: 5000,positionClass:'toast-top-right' });
        break;
      }
      default: {
        this.toastr.warning("", message, { timeOut: 5000,positionClass:'toast-top-right' });
        break;
      }
    }
  }


  createDs(payload){
    return this._http.post<any>(`${this.API_URL}/v1/data-sources/create-source-filetype`,payload)
  }
  populateDs(payload){
    return this._http.post<any>(`${this.API_URL}/v1/cleaning/datasource/populate/in/dynamodb`,payload)
  }

  getuserRoles(){
    return this._http.get<any>(`${this.API_URL}/userrole/getnames`)
  }

  manageDs(){
    return this._http.get<any>(`/assets/data/ds-manage.json`);
  }

  manageJobFilter(payload){
    return this._http.post<any>(`${this.API_URL}/dashboard/v1/job/filter`,payload);
  }

  managelogFIlter(){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/pi-chart/logs`);
  }

  getRestrictedSection(pageName){
    return this._http.get<any>(`${this.API_URL}/dashboard/v1/page-access/get-by-page?pageName=${pageName}`);
  }
  getDataSourceListForQATesting(payload){
    return this._http.post(`${this.API_URL}/dashboard/v1/testing/search`,payload)
  }
  getCompareDataQA(slug){
    return this._http.get(`${this.API_URL}/dashboard/v1/testing/qa?slug=${slug}`)
  }
  getTaskTeamIdBySlug(slug){
    return this._http.get(`${this.API_URL}/dashboard/v1/testing/ids?slug=${slug}`)
  }
  getDatasourceLogs(payload){
    return this._http.post(`${this.API_URL}/dashboard/v1/versioning/search`,payload)
  }
  getEntitiesListByDsSlug(payload){
    return this._http.post(`${this.API_URL}/dashboard/v1/versioning/get-entities`,payload)
  }
  getEntityVersionsByEntityGuid(payload){
    return this._http.post(`${this.API_URL}/dashboard/v1/versioning/get-versions`,payload)
  }
  mergeMultipleEntities(payload){
    return this._http.post(`${this.API_URL}/er-dashboard/merge-entity`,payload)
  }
}
