
<span class="float-left">
  <a mdbBtn floating="true" (click)="goback()"    ><mdb-icon  class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon></a>
      
</span>
<div class="col-lg col-md col-sm d-flex justify-content-center">
    <div class="card">
        <h5 class="card-title">Pagination</h5>
        
        <form (ngSubmit)="onSubmit(content)" #pagedetails="ngForm">
          <label for="inlinecheckbox">Does the page have pagination?</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input data-md-icheck (click)="page($event.target.value)" [(ngModel)]="model.pagination" class="form-check-input" type="radio" id="inlineCheckbox1" value="Yes" name="pagination"> 
                    <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
              
              
                <div class="form-check form-check-inline">
                      <input data-md-icheck (click)="page($event.target.value)"  [(ngModel)]="model.pagination" class="form-check-input" type="radio" id="inlineCheckbox2" value="No" name="pagination">
                      <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
        
            </div>
          
            <div class="form-group" *ngIf="pageination" >
              <label for="paginationType">Type of Pagination</label>
              <select  class="form-control" (click)="pagetype($event.target.value)" [(ngModel)]="model.paginationType" name="paginationType" id="paginationtype" >
                <option value="" disabled selected>Please choose pagination type</option>
                <option value="next page button">Next Button</option>
                <option value="Load More">Load More</option>
                <option value="Infinite Scroll">Infifnite Scroll</option>
              </select>
            </div>
            

            <div class="form-group" *ngIf="pageination && pageinationtype " >
              <label for="inlinecheckbox">Next Button</label>
              <div class="form-check form-check-inline">
                <input data-md-icheck (click)="nxtbtn($event.target.value)" [(ngModel)]="model.isNextButton" class="form-check-input" type="radio" id="inlineCheckbox1" value="yes" name="isNextButton"> 
                <label class="form-check-label" for="inlineCheckbox1">Yes</label>
            </div>
          
          
            <div class="form-check form-check-inline">
                  <input data-md-icheck (click)="nxtbtn($event.target.value)" [(ngModel)]="model.isNextButton" class="form-check-input" type="radio" id="inlineCheckbox2" value="no" name="isNextButton">
                  <label class="form-check-label" for="inlineCheckbox2">No</label>
            </div>    
             
            </div>
                <div class="form-group"  >
                            
                  <label for="sourceurl">Harvesting Page Link Url</label>
                  <input  class="form-control"  type="text" id="sourceurl" name="harvestingPageUrl"  [(ngModel)]="model.harvestingPageUrl" placeholder="Enter the main Url ">
                  
                </div>
                <div class="form-group" *ngIf="pageination && pageinationtype " >
                            
                  <label for="nextpageselectors">Next Page Selectors</label>
                  <input  class="form-control"  type="text" id="nextpageselectors" name="nextPageSelector"  [(ngModel)]="model.nextPageSelector" placeholder="Enter the page selectors ">
                  
                </div>
                <div class="form-group" *ngIf="pageination && pageinationtype && !nextbtn">
                            
                  <label for="noOfPages">Enter no of Pages</label>
                  <input  class="form-control"  type="text" id="noOfPages" name="noOfPages"  [(ngModel)]="model.noOfPages" placeholder="Enter the page selectors ">
                  
                </div>
                <div class="form-group"  >
                            
                  <label for="harvesting">Harvesting link selectors</label>
                  <input  class="form-control"  type="text" id="harvesting" name="harvestingLinksSelector"  [(ngModel)]="model.havestingLinksSelector" placeholder="Enter the harvesting link selectors ">
                  
                </div>
                <div class="form-group" >
                            
                  <label for="isTable">Table</label>
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input data-md-icheck  [(ngModel)]="model.isTable" class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true" name="isTable"> 
                        <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                    </div>
                  
                  
                    <div class="form-check form-check-inline">
                          <input data-md-icheck  [(ngModel)]="model.isTable" class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false" name="isTable">
                          <label class="form-check-label" for="inlineCheckbox2">No</label>
                    </div>
            
                </div>
                  
                </div>
            
            
            <!-- <div class="form-group" *ngIf="model.pagination=='No'" >
              <label for="inputSelectors">Input selectors</label>
              <input class="form-control"  [(ngModel)]="model.harvestingLinksSelector" type="text" id="inputSelectors" name="harvestingLinksSelector"  placeholder="Please input Selectors">
            </div> -->
            <button type="submit" mdbBtn  class="btn btn-success"  >Submit</button>
            <!-- Button trigger modal -->




        </form>
    </div>
    
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <!-- <h4 class="modal-title">Please </h4> -->
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Are you sure want to extract data?&hellip;</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="modal.close && extractData() ">Confirm</button>
    </div>
  </ng-template>
