<!-- <app-admin-topmenu step="1"></app-admin-topmenu> -->

    <div class="container-fluid">
        <div class="row d-flex justify-content-center">
          
        <div class="card">

          <div class="card-body">
            
        <span class="table-add float-left mr-2">
          
           <button mdbBtn color="indigo" rounded="true" (click)="open(content)" size="sm" class="mb-2" type="button" >Add Cleaner</button>
      
        </span>
        <span class="table-add float-right mr-2">
          
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText" (keyup)="searchItems()" id="search-input"
          mdbInput>     
       </span>
          <table class="table table-bordered  text-center z-depth-1 " style="table-layout: fixed;" mdbTable #tableEl="mdbTable" >
              <thead class="thead-dark">
                <tr>
                   <th style="width: 8%;">ID</th>    
                  <th style="width: 20%;">Name</th>
                  <th style="width: 32%;">Short Description</th>
                  <th style="width: 30%;">Attribute</th>
                  <th style="width: 10%;">Action</th>
                </tr>
                  
              </thead>
              <tbody #row>
                <tr mdbTableCol *ngFor="let item of elements | paginate: { itemsPerPage: 10, currentPage: page };let i=index">
                  <td  class="red-text">{{item?.id}}</td>           
                  <td  >{{item?.name}}</td>
                  
                  <td  >{{item?.shortDescription}}</td>
                  <td  >
                    <select style="color: black;" disabled [(ngModel)]="item.attribute.attribute" name="item.attribute.attribute" [ngModelOptions]="{standalone: true}" ngDefaultControl>
                        <option [ngValue]="item.attribute"  *ngFor="let item of property">{{item.attribute}}</option>
                      </select>
                  </td>
                  
                  <td  >
                    <span class="table-remove">
                      <button type="button" mdbBtn color="light" rounded="true" size="sm" class="my-0"  (click)="onClick($event,contentone,item)" ><i class="fas fa-edit"></i></button>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot class="grey lighten-5 w-100">
                <tr>
                  <td colspan="8">
                    <pagination-controls (pageChange)="page = $event"></pagination-controls>
                  </td>
                </tr>
                </tfoot>
            </table>
            </div>
        </div>
            <!-- <div class="d-flex justify-content-center">
            <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
          </div> -->
            
      </div>
    </div>
      <ng-template #content  let-modal >
        <div class="modal-header" style="padding: 1rem;">
          <h4 class="modal-title" id="modal-basic-title">Create new Cleaner</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form [formGroup]="createForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" formControlName="name"  class="form-control" />
                    
                </div>
                
                <div class="form-group">
                    <label for="attribute">Attribute</label>
                    <select  formControlName="attribute" class="form-control">
                        <option [ngValue]="item"  *ngFor="let item of property">{{item.attribute}}</option>
                      </select>
                </div>
                <div class="form-group">
                    <label for="shortDescription">Short Description</label>
                    <input type="text" formControlName="shortDescription"  class="form-control" />
                    
                </div>
                <div class="form-group">
                    <label for="absolutePath">Absolute Path</label>
                    <input type="text" formControlName="absolutePath"  class="form-control" />
                    
                </div>
                <div class="form-group">
                    <label for="isActive">isActive</label>
                    <input type="text" formControlName="isActive"  class="form-control" />
                    
                </div>
                <div class="form-group">
                    <label for="inputArg">Input Arg</label>
                    <input type="text" formControlName="inputArg"  class="form-control" />
                    
                </div>
                <div class="form-group">
                    <label for="returnType">Return Type</label>
                    <input type="text" formControlName="returnType"  class="form-control" />
                    
                </div>
                <div class="form-group">
                    <label for="description">Description</label>
                    <textarea type="text" formControlName="description"  class="form-control" >
                    </textarea>
                </div>
                <div class="form-group">
                    <label for="example">Example</label>
                    <textarea type="text" formControlName="example"  class="form-control" >
                    </textarea>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button type="submit" class="btn btn-success">
                         Save
                    </button>
                </div>
                
            </form>
            </div>
        </ng-template>
        <ng-template #contentone  let-modal >
            <div class="modal-header" style="padding: 1rem;">
              <h4 class="modal-title" id="modal-basic-title">Update Cleaner Info</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              
                <form (ngSubmit)="onUpdate()">
                    <div class="form-group">
                        <label for="editcleaner.id">Id</label>
                        <input disabled type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="editcleaner.id"  class="form-control"  />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.name">Name</label>
                        <input type="text"  ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.name" [(ngModel)]="editcleaner.name"  class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.attribute">Attribute</label>
                        <select  disabled name="editcleaner.attribute.attribute" ngDefaultControl [ngModelOptions]="{standalone: true}"   [(ngModel)]="editcleaner.attribute.attribute" class="form-control">
                            <option disabled>{{editcleaner.attribute.attribute}}</option>
                            <option [ngValue]="item"  *ngFor="let item of property">{{item.attribute}}</option>
                          </select>
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.shortDescription">Short Description</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.shortDescription" [(ngModel)]="editcleaner.shortDescription"  class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.absolutePath">Absolute Path</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.absolutePath" [(ngModel)]="editcleaner.absolutePath"  class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.isActive">isActive</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.isActive" [(ngModel)]="editcleaner.isActive"   class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.inputArg">Input Arg</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.inputArg" [(ngModel)]="editcleaner.inputArg"  class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.returnType">Return Type</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.returnType" [(ngModel)]="editcleaner.returnType"    class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.description">Description</label>
                        <textarea type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.description" [(ngModel)]="editcleaner.description"   class="form-control" >
                          </textarea>
                    </div>
                    <div class="form-group">
                        <label for="editcleaner.example">Example</label>
                        <textarea type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="editcleaner.example" [(ngModel)]="editcleaner.example"  class="form-control" >
                        </textarea>
                    </div>
                    
                    <!-- <div class="form-group">
                        <label for="exampleDate">Example Date</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}"  name="datedetail.exampleDate" [(ngModel)]="editcleaner."   class="form-control" />
                        
                    </div> -->
                    
                    
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                        <button type="submit" class="btn btn-success">
                             Save
                        </button>
                    </div>
                    
                </form>
                </div>
            </ng-template>