<div class="container-fluid" style="margin-top: 0%;"> 
        <div class="row">
            <!-- <div class="col-2">
              <div class="card">
                <ul>
                  <p  style="padding-top: 10px;" *ngFor="let menu of sideMenu"><a routerLink="{{menu.route}}" >{{menu.title}}</a> </p>
                  
                </ul>
              </div>
            </div> -->
            <div class="col-12">
              <div class="card">
                
                <div class="card-body">
                  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="increaseSize($event)">
                    <option [ngValue]="25">25 items per page</option>
                    <option [ngValue]="50">50 items per page</option>
                    <option [ngValue]="100">100 items per page</option>
                  </select>
                  <span class="table-add float-right mb-3 mr-2">
                    <div class="d-flex justify-content-center " >
            
                        <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search by name" style="height: 32px;" id="search-input"
                            mdbInput>
                      
                      
                        <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
                        <button  style="margin:0px;" type="button" mdbBtn size="sm" color="dark"(click)="searchItems()" mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon></button>
                      
                  </div>
                
                  </span>
              
                <table class="table table-bordered table-striped text-center " style="table-layout: fixed;width:100%" mdbTable  #tableEl="mdbTable"  >
                    <thead class="thead-dark sticky-top" >
                      <tr>
                        <th style="width: 8%;">ID</th>    
                        <th style="width: 24%;">Name</th>
                        <th style="width: 30%;">Harvesting Url</th>
                        <!-- <th>Harvesting Page Url</th> -->
                        <th style="width: 15%;">Created Date</th>
                        <th class="th-sm" style="width: 15%;">Last Modified</th>
                        <th class="th-xl" style="width: 30%;">Actions</th>
                      </tr>
                        
                    </thead>
                    <tbody #row>
                      <tr mdbTableCol *ngFor="let item of dataSources| paginate: { itemsPerPage: pageSize, currentPage: page+1,totalItems: total };let id=index;">
                        <td  class="red-text">{{item?.amlDataSourceId}}</td>           
                        <td  >{{item?.name}}</td>
                        <td style="word-break: break-all;"  >{{item?.harvestingPageUrl}}</td>
                        <!-- <td>{{item.harvestingPageUrl}}</td> -->
                        <td  >{{item?.createdDate|date:'mediumDate'}}</td>
                        <td  >{{item?.lastModifiedBy?.userName}}</td>
                        <td  style="display: contents;">
                          <span class="table-remove" >
                            <button type="button" mdbBtn color="indigo" rounded="true" size="sm"  mdbTooltip="Add Config" placement="down" mdbWavesEffect  (click)="onClick($event,content,item)" class="btn"  ><i class="fas fa-plus fa-lg"></i></button>

                            <button type="button" mdbBtn color="light" rounded="true" size="sm" mdbTooltip="Edit Data Source" placement="down" mdbWavesEffect (click)="onClickEdit($event,item)" class="btn"  ><i class="fas fa-edit fa-lg"></i></button>
                            <button type="button" mdbBtn color="info" rounded="true" size="sm"  mdbTooltip="Check Image Labelling" placement="down" mdbWavesEffect (click)="checkImage($event,item)" class="btn"  ><i class="fas fa-image fa-lg"></i></button>
                            <button type="button" mdbBtn color="deep-orange" rounded="true" size="sm" mdbTooltip="Check Extracted Data" placement="down" mdbWavesEffect (click)="checkExtractedData($event,item)" class="btn"  ><i class="fas fa-table fa-lg"></i></button>
                            <button type="button" mdbBtn color="amber" rounded="true" size="sm" mdbTooltip="Start Fresh Extraction" placement="down" mdbWavesEffect (click)="startExtraction($event,item,start)" class="btn"  >Extract</button>

                            <button type="button" mdbBtn color="success" rounded="true" size="sm" mdbTooltip="Download JSON" placement="down" mdbWavesEffect (click)="onClickDownload($event,item)" class="btn"  ><i class="fas fa-arrow-down fa-lg"></i></button>
                            
                            
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                      <tr>
                        <td colspan="8">
                          <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                          <pagination-controls [maxSize]="maxSize"
                            
                          [directionLinks]="directionLinks"
                          
                          [responsive]="responsive" 
                          (pageChange)="pagec($event)"
                          
                          ></pagination-controls>

                          <!-- <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="elements"></mdb-table-pagination> -->
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-center">
            <button mdbBtn class="btn btn-success " (click)="download()" >Download(JSON)</button>
          </div> -->
            
      </div>
      </div>
      <ng-template #content class="modal-lg" let-modal >
        <div class="modal-header" style="padding: 1rem;">
          <h4 class="modal-title" id="modal-basic-title">Add Data Config</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="col-lg col-md col-sm d-flex justify-content-center">
                <div class="card">
                  
                    <div class="card-body">
                      <div id="table" class="table-editable">
                        
                        <form [formGroup]="createForm"  (ngSubmit)="onSubmit()" >
                          <div class="form-group">
                            <label for="baseImgUrl">Base Image Url</label>
                            <input appFormcontrol type="text" formControlName="baseImgUrl" name="baseImgUrl"  class="form-control"  />
                            
                        </div>
                        <div class="form-group">
                          <label for="employer">Employer</label>
                          <input appFormcontrol type="text" formControlName="employer" name="employer"  class="form-control"  />
                          
                      </div>
                      <div class="form-group">
                        <label for="category">Category</label>
                        <select appFormcontrol formControlName="category" name="category" class="form-control">
                          
                          <option [ngValue]="item" *ngFor="let item of allCategory">{{item.name}}</option>
                          </select>
                    </div>
                    <div class="form-group">
                      <label for="riskLevel">Risk Level</label>
                      <select appFormcontrol formControlName="riskLevel" class="form-control">
                        <option value="National">National</option> 
                        <option value="International">International</option> 
                        </select>
                  </div>
                  <div class="form-group">
                    <label for="entityType">Entity Type</label>
                    <select appFormcontrol formControlName="entityType" class="form-control">
                      <option value="Individual">Individual</option> 
                      <option value="Organisation">Organisation</option> 
                      <option value="Aircraft">Aircraft</option> 
                      <option value="Vessel">Vessel</option>
                      </select>
                </div>
                <div class="form-group">
                  <label for="nationality">Nationality</label>
                  <input appFormcontrol type="text" formControlName="nationality" name="nationality"  class="form-control"  />
                  
              </div>

              <div class="form-group">
                <label for="summary">Summary</label>
                <input appFormcontrol type="text" formControlName="summary" name="summary"  class="form-control"  />
                
            </div>
                            
                        <table class="table table-bordered table-responsive-md table-striped text-center">
                          <tr>
                            <th class="text-center">Attribute</th>
                            
                            <th class="text-center">cleaner</th>
                          
                            <th class="text-center">Remove</th>
                          </tr>
                          <tr *ngFor="let item of list; let id = index">
                            <td>
                             
                              <select [(ngModel)]="item.name" (change)="onItemChange(item.name)"  name="item.name" [ngModelOptions]="{standalone: true}" ngDefaultControl class="form-control"> 
                                <option [ngValue]="new.slug"  *ngFor="let new of allAttributes">{{new.attribute}}</option>
                              </select>
                              <!-- <input type="text"    [(ngModel)]="person.property"    ngDefaultControl [ngModelOptions]="{standalone: true}" contenteditable="true"/> -->
                            </td>
                            
                            <td>
                                
                        <ng-multiselect-dropdown
                        [placeholder]="'custom placeholder'"
                        [settings]="dropdownSettings"
                        [data]="dropdownList"
                        [(ngModel)]="item.cleaner"
                        name="item.cleaner"
                        [ngModelOptions]="{standalone: true}" 
                        ngDefaultControl
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                        
                        >
                        </ng-multiselect-dropdown>
                                <!-- <select class="browser-default custom-select" name="person.extractionInfo" [(ngModel)]="person.extractionInfo" [ngModelOptions]="{standalone: true}" ngDefaultControl>
                                    <option selected disabled>{{person.extractionInfo}}</option>
                                    <option value="TEXT">TEXT</option>
                                    <option value="URL">URL</option>
                                    <option value="IMAGE">IMAGE</option>
                                  </select> -->
                            </td>
                    
                            <td>
                              <span class="table-remove">
                                <button type="button" mdbBtn color="danger" rounded="true" size="sm" class="my-0" (click)="remove(id)">Remove</button>
                              </span>
                            </td>
                          </tr>
                        </table>
                        <div class="modal-footer d-flex justify-content-center">
                          <button mdbBtn color="info" (click)="add()" rounded="true" size="md" class="mb-2" type="button" >Add more</button>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                            <button type="submit" class="btn btn-success">
                                 Save
                            </button>
                        </div>
                    </form>
                      </div>
                    </div>
                  </div>
                  </div>
            
            </div>
        </ng-template>
        <ng-template #start let-modal>
          <div class="modal-header">
            <!-- <h4 class="modal-title">Please </h4> -->
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure want to extract data?&hellip;</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
            <button type="button" class="btn btn-success" (click)="modal.close && extractData() ">Confirm</button>
          </div>
        </ng-template>
      