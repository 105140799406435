import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-topmenu',
  templateUrl: './admin-topmenu.component.html',
  styleUrls: ['./admin-topmenu.component.scss']
})
export class AdminTopmenuComponent implements OnInit {

  @Input() step="";
  menuRoutes=[
    {route:"/attributes",name:"Attributes"},
    {route:"/cleaners",name:"Cleaners"},
    {route:"/dataconfigs",name:"Data Configs"},
    {route:"/client-registration",name:"Register User"},
    {route:"/erhome",name:"Resolve Entity"}
  ];
  selectedMenu:any="";

  constructor(
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private location:Location,
  ) { }

  ngOnInit(): void {
    this.selectedMenu=this.step!=""?this.menuRoutes[parseInt(this.step)]:this.step;
  }

  goToMenu(){
    //  console.log("menu value: ",this.selectedMenu);
     this.router.navigate([this.selectedMenu.route])
  }

  goback(){
    if(this.router.url=="/dashboard/upcoming-work" || this.router.url=="/dashboard/delayed-work" ||this.router.url=="/dashboard/unassigned-datasources" ||this.router.url=="/dashboard/recurring-job-status"){
       this.router.navigate(['/manage-datasource']);
    }else{
      this.location.back();
    }
  }
}
