
<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-2">
            <a mdbBtn floating="true" (click)="goback()">
                <mdb-icon class="mt-2 mb-2 ml-2" fas icon="backspace" size="2x"></mdb-icon>
            </a>

        </div>
        <div class="col-10">
            <span class="float-right mt-2">
                <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/adminhome']"
                    routerLinkActive="active" class="mb-2" type="button">Home</button>

                <button mdbBtn color="primary" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/attributes']"
                    routerLinkActive="active" class="mb-2" type="button">Attributes</button>

                <button mdbBtn color="deep-orange" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/cleaners']"
                    routerLinkActive="active" class="mb-2" type="button">Cleaners</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/dataconfigs']"
                    routerLinkActive="active" class="mb-2" type="button">Data Configs</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm"
                    [routerLink]="['/client-registration']" routerLinkActive="active" class="mb-2"
                    type="button">Register User</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm" [routerLink]="['/erhome']"
                    routerLinkActive="active" class="mb-2" type="button">Resolve Entity</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm"
                    [routerLink]="['/create-datasource']" routerLinkActive="active" class="mb-2" type="button">Create
                    Data Source</button>
                <button mdbBtn color="elegant" mdbWavesEffect rounded="true" size="sm"
                    [routerLink]="['/populate-datasource']" routerLinkActive="active" class="mb-2"
                    type="button">Populate Data Source</button>


            </span>




        </div>
    </div>

</div> -->

<div class="container-fluid">
    <div class="row">

        <div class="col-lg col-md col-sm d-flex justify-content-center">
            <div class="card">
                <form [formGroup]="populateDsForm" (ngSubmit)="onSubmit()" class="text-center ">

                    <p class="card-title mb-2">Populate Data Source</p>

                    <div class="form-group">
                        <label for="dataSourceSlug">Source Slug</label>
                        <input type="text" formControlName="dataSourceSlug" class="form-control" placeholder="Enter Source Slug" />
                    </div>

                    <button mdbBtn color="info" [disabled]="loading"  size="md" class="my-5" type="submit">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Populate</button>
                </form>
            </div>
        </div>
    </div>
</div>