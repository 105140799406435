<div class="content">
    <ng-container *ngIf="allowedSections.indexOf('manage-team-process-flow') !== -1">
        <div class="row">
            <div class="card processContainer">
                <div class="alignRow">
                    <div class="title">Process List</div>
                    <div class="add-process">
                        <button class="btn btn-add-process" (click)="openDialogOneArg(addNewProcess)">Add New Process
                            Flow
                        </button>
                        <div style="margin-left: 10px;"></div>
                        <button class="btn btn-add add" (click)="openDialogOneArg(addNewTeam)"  >
                            <i class="fa fa-user-plus" style="color: #ffffff;"></i>&nbsp;Add New Team
                        </button>
                    </div>

                </div>
                <div class="col-12">
                    <ng-container *ngFor="let data of newArray; let i=index">
                        <div class="card">

                            <div class="processFlow-container">
                                <div class="title2">{{ data.name }}</div>

                                <ng-container *ngIf="data.terminated == true">

                                    <div>Terminated, No more changes allowed!</div>
                                </ng-container>
                                <ng-container *ngIf="!data.terminated">
                                    <div class="action-flows">
                                        <select class="menuSelect action-mr"
                                            (change)="addNewRole($event.target.value,newRole,data)">
                                            <option disabled selected>Add New Process</option>
                                            <option *ngFor="let item of roleArray" [value]="item.key">{{item.value}}
                                            </option>
                                        </select>
                                        <div class="form-check action-mr">
                                            <input class="form-check-input" (change)="handleEditable($event,data.id)"
                                                type="checkbox" value='true' id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable Editing
                                            </label>
                                        </div>
                                        <!-- <button (click)="openTerminateProcessFlowDialog(terminateProcessFlow,data)"
                                            style="padding: 5px 10px;" class="btn btn-danger">Terminate
                                        </button> -->

                                    </div>
                                </ng-container>

                            </div>
                            <div class="process-button" style="margin-top: 20px; padding:2vh 0px">
                                <ng-container *ngFor="let item of data?.processFlow;let i = index">

                                    <ng-container [ngSwitch]="item?.role?.name">
                                        <ng-container *ngSwitchCase="'SUPER ADMIN'">
                                            <ng-container *ngIf="i !== 0">
                                                <div class="vl"></div>
                                                <i class="fa fa-arrow-right" style="margin-top: 20px;"></i>
                                            </ng-container>
                                            <button class="btn btn-super-admin">{{ item?.name }}&nbsp;<ng-container
                                                    *ngIf="editableArr.indexOf(data.id) !== -1"><span class="removeIcon"
                                                        (click)="removeProcessStep(i,data)"><i
                                                            class="fa fa-times"></i></span></ng-container></button>
                                            <ng-container *ngIf="i !== data.processFlow.length-1">
                                                <div class="vl"></div>

                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'ADMIN'">
                                            <ng-container *ngIf="i !== 0">
                                                <div class="vl"></div>
                                                <i class="fa fa-arrow-right" style="margin-top: 20px;"></i>
                                            </ng-container>
                                            <button class="btn btn-admin">{{ item?.name }}&nbsp;<ng-container
                                                    *ngIf="editableArr.indexOf(data.id) !== -1"><span class="removeIcon"
                                                        (click)="removeProcessStep(i,data)"><i
                                                            class="fa fa-times"></i></span></ng-container></button>
                                            <ng-container *ngIf="i !== data.processFlow.length-1">
                                                <div class="vl"></div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'EXTRACTOR'">
                                            <ng-container *ngIf="i !== 0">
                                                <div class="vl"></div>
                                                <i class="fa fa-arrow-right" style="margin-top: 20px;"></i>
                                            </ng-container>
                                            <button class="btn btn-extractor">{{ item?.name }}&nbsp;<ng-container
                                                    *ngIf="editableArr.indexOf(data.id) !== -1"><span class="removeIcon"
                                                        (click)="removeProcessStep(i,data)"><i
                                                            class="fa fa-times"></i></span></ng-container></button>
                                            <ng-container *ngIf="i !== data.processFlow.length-1">
                                                <div class="vl"></div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'TRANSFORMER'">
                                            <ng-container *ngIf="i !== 0">
                                                <div class="vl"></div>
                                                <i class="fa fa-arrow-right" style="margin-top: 20px;"></i>
                                            </ng-container>
                                            <button class="btn btn-transformer">{{ item?.name }}&nbsp;<ng-container
                                                    *ngIf="editableArr.indexOf(data.id) !== -1"><span class="removeIcon"
                                                        (click)="removeProcessStep(i,data)"><i
                                                            class="fa fa-times"></i></span></ng-container></button>
                                            <ng-container *ngIf="i !== data.processFlow.length-1">
                                                <div class="vl"></div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'QA'">
                                            <ng-container *ngIf="i !== 0">
                                                <div class="vl"></div>
                                                <i class="fa fa-arrow-right" style="margin-top: 20px;"></i>

                                            </ng-container>
                                            <button class="btn btn-qa">{{ item?.name }}&nbsp;<ng-container
                                                    *ngIf="editableArr.indexOf(data.id) !== -1"><span class="removeIcon"
                                                        (click)="removeProcessStep(i,data)"><i
                                                            class="fa fa-times"></i></span></ng-container></button>
                                            <ng-container *ngIf="i !== data.processFlow.length-1">
                                                <div class="vl"></div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'PROCESSOR'">
                                            <ng-container *ngIf="i !== 0">
                                                <div class="vl"></div>
                                                <i class="fa fa-arrow-right" style="margin-top: 20px;"></i>
                                            </ng-container>
                                            <button class="btn btn-processor">{{ item?.name }}&nbsp;<ng-container
                                                    *ngIf="editableArr.indexOf(data.id) !== -1"><span class="removeIcon"
                                                        (click)="removeProcessStep(i,data)"><i
                                                            class="fa fa-times"></i></span></ng-container></button>
                                            <ng-container *ngIf="i !== data.processFlow.length-1">
                                                <div class="vl"></div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div style="float: right;"><ng-container *ngIf="!data.terminated">
                                <button (click)="openTerminateProcessFlowDialog(terminateProcessFlow,data)"
                                style="padding: 5px 10px; float: right; width: 140px; height:46px" class="btn btn-danger">Terminate
                            </button>
                            </ng-container></div>
                            


                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </ng-container>
    <ng-container *ngIf="allowedSections.indexOf('manage-team-count') !== -1">
        <div class="row">

            <ng-container *ngFor="let item of teamCountData">
                <div class="card  teams-container">
                    <div class="title3">{{ item.teamName }}</div>
                    <hr>
                    <div class="row mt-2 ml-3">
                        <div class="col-3 team-col-container">
                            <label class="dataLabel fullWidth text-left sub-heading">Team Size</label>
                            <p class="dataValue fullWidth text-left">{{ item.teamSize }}</p>
                        </div>
                        <div class="col-3 team-col-container"><label
                                class="dataLabel fullWidth text-left sub-heading">Transformer</label>
                            <p class="dataValue fullWidth text-left">{{ item.transformerCount }}</p>
                        </div>
                        <div class="col-3 team-col-container"><label
                                class="dataLabel fullWidth text-left sub-heading">QA</label>
                            <p class="dataValue fullWidth text-left">{{ item.qaCount }}</p>
                        </div>
                        <div class="col-3 team-col-container"><label
                                class="dataLabel fullWidth text-left sub-heading">Processor</label>
                            <p class="dataValue fullWidth text-left">{{ item.processorCount }}</p>
                        </div>
                        <div class="col-3 team-col-container"><label
                                class="dataLabel fullWidth text-left sub-heading">Extractor</label>
                            <p class="dataValue fullWidth text-left">{{ item.extractorCount }}</p>
                        </div>
                        <div class="col-3 team-col-container"> <label
                                class="dataLabel fullWidth text-left sub-heading">Admin</label>
                            <p class="dataValue fullWidth text-left">{{ item.adminCount }}</p>
                        </div>


                        <!-- 
                        <div class="col-3">
                            <button class="btn btn-add" (click)="openDialogOneArg(addNewTeamMember)">
                                        <i class="fa fa-plus" style="color: #ffffff;">
                                        </i> Add Member
                                    </button>
                        </div> -->
                    </div>
                </div>
            </ng-container>

        </div>
    </ng-container>

    <div class="row mt-4">
        <ng-container *ngIf="allowedSections.indexOf('manage-team-team-user') !== -1">
            <div class="col-4 my-1">
                <div class="inputWithIcon">
                    <input type="text" readonly placeholder="Search Database Here...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-3 my-1">
                <select (change)="filterByStatus($event.target.value)" class="menuSelect customSelect">
                    <option value="" selected disabled>Filter By Status</option>
                    <option *ngFor="let item of filterList" [value]="item.value">{{item.key}}</option>
                </select>
            </div>
            <div class="col-3 my-1">
                <select (change)="filterByTeam($event.target.value)" class="menuSelect customSelect">
                    <option value="all" selected>All Teams</option>
                    <option *ngFor="let team of teamsArr" [value]="team.id">{{team.name}}</option>
                </select>
            </div>
        </ng-container>
        <ng-container *ngIf="allowedSections.indexOf('manage-team-add-member') !== -1">
            <div class="col-2 add-member">
                <button class="btn btn-add add" (click)="openDialogOneArg(addNewTeamMember)">
                    <i class="fa fa-user-plus" style="color: #ffffff;">
                    </i> Add Member
                </button>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="allowedSections.indexOf('manage-team-team-user') !== -1">
        <div class="row card mx-0 my-3">
            <div class="col-md-12">
                <ng-container *ngIf="tableData?.length === 0">
                    <h5 class="text-center">No Data Found</h5>
                </ng-container>
                <table class="table" mdbTable #tableEl="mdbTable" *ngIf="tableData?.length > 0">
                    <thead>
                        <tr>
                            <th class="table-heading">NAME</th>
                            <th class="table-heading">ROLE</th>
                            <th class="table-heading">EMAIL</th>
                            <th class="table-heading">CONTACT NUMBER</th>
                            <th class="table-heading">TEAM</th>
                            <th class="table-heading">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody #row>
                        <tr mdbTableCol class="rowData"
                            *ngFor="let data of tableData | paginate: { itemsPerPage: pageSize, currentPage: page+1,totalItems: total };let i=index">
                            <td style="vertical-align: middle;">{{ data?.teamUser?.firstName ? data.teamUser.firstName :
                                '-' }}
                            </td>
                            <td style="vertical-align: middle; flex-wrap: wrap;justify-content: center;" class="d-flex">
                                <div class="table-role">
                                    <ng-container *ngFor="let item of data?.roles">
                                        <ng-container [ngSwitch]="item?.name">
                                            <ng-container *ngSwitchCase="'SUPER ADMIN'">
                                                <button class="btn btn-super-admin btn-sm">{{ item.name }}</button>
                                                <div class="vl"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'ADMIN'">
                                                <button class="btn btn-admin btn-sm">{{ item?.name }}</button>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'EXTRACTOR'">
                                                <button class="btn btn-extractor btn-sm">{{ item?.name }}</button>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'TRANSFORMER'">
                                                <button class="btn btn-transformer btn-sm">{{ item?.name }}</button>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'QA'">
                                                <button class="btn btn-qa btn-sm">{{ item?.name }}</button>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PROCESSOR'">
                                                <button class="btn btn-processor btn-sm">{{ item?.name }}</button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                            <td style="vertical-align: middle;">{{ data?.teamUser?.firstName ? data.teamUser.email : '-'
                                }}
                            </td>
                            <td style="vertical-align: middle;">{{ data?.teamUser?.contactNumber ?
                                data.teamUser.contactNumber : '-' }}
                            </td>
                            <td style="vertical-align: middle;">{{ data?.team?.name ?
                                data.team.name : '-' }}
                            </td>
                            <td
                                style="vertical-align: middle; display: flex; flex-direction: row; justify-content: center;">
                                <!-- <div class="button-cursor actionIcon"
                                    (click)="openEditMemberDialog(editTeamMember,data)"><i  placement="top" ngbTooltip="Edit User"
                                        class="fa-solid fa fa-user-edit" style="color: #0091bd;"></i>
                                </div> -->
                                <button style="padding: 5px 5px 5px 8px" type="button" mdbBtn color="info" size="sm"
                                    mdbTooltip="Edit Member" placement="top" mdbWavesEffect
                                    (click)="openEditMemberDialog(editTeamMember,data)" class="btn"><i
                                        class="fa-solid fa fa-user-edit"></i></button>
                                <button style="padding: 5px 5px 5px 8px" type="button" mdbBtn color="danger" size="sm"
                                    mdbTooltip="Remove Member" placement="top" mdbWavesEffect
                                    (click)="openDeleteMemberDialog(deleteTeamMember,data)" class="btn"><i
                                        class="fa-solid fa fa-user-minus"></i></button>
                                <!-- <div class="button-cursor actionIcon" 
                                    (click)="openDeleteMemberDialog(deleteTeamMember,data)"><i title="delete"
                                        class="fa-solid fa fa-user-minus" style="color: #ff3547;"></i>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="8">
                                <pagination-controls [maxSize]="pageSize" [directionLinks]="directionLinks"
                                    [responsive]="responsive" (pageChange)="pagec($event)"></pagination-controls>
                            </td>
                        </tr>
                    </tfoot>
                </table>

            </div>
        </div>
    </ng-container>
    <ng-template #newRole let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Create a New Process</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="newRoleForm">
                <div class="newMember">
                    <label>Name</label>
                    <input type="text" formControlName="name" placeholder="Enter Name">
                </div>
                <div class="newMember">
                    <label>Color</label>
                    <select class="menuSelect customSelect">
                        <option value="" selected disabled>Select Color</option>
                        <option *ngFor="let item of roleArray" value={{item.value}}>{{item.key}}</option>
                    </select>
                </div>
                <div class="newMember">
                    <label>Role Access</label>
                    <select class="menuSelect customSelect">
                        <option value="" selected disabled>Choose Role Access</option>
                        <option *ngFor="let item of roleArray" value={{item.value}}>{{item.name}}</option>
                    </select>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-add" (click)="modal.dismiss('Cross click')">Add</button>
        </div>
    </ng-template>
    <ng-template #terminateProcessFlow let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Terminate Process Flow</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="newMember">
                After terminating you will not be able to change the process flow.<br>Are you sure want to continue ?
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn" (click)="modal.dismiss('Cross click')">No</button>
            <button class="btn btn-danger"
                (click)="handleTerminateProcessFlow(); modal.dismiss('Cross click')">Yes</button>
        </div>
    </ng-template>
    <ng-template #addNewTeam let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Add New Team</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="newTeamForm">
            <div class="modal-body">
                <div class="newMember">
                    <label>Name<sup>*</sup></label>
                    <input type="text" formControlName="name" placeholder="Enter Name">
                </div>
                <div class="newMember">
                    <label>Description</label>
                    <input type="text" formControlName="description" placeholder="Enter Description">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-add"
                    (click)="handleAddNewTeam(); modal.dismiss('Cross click')">Add</button>
            </div>
        </form>
    </ng-template>
    <ng-template #addNewTeamMember let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Add New Team Member</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="newMemberForm">
                <div class="newMember">
                    <label>Email ID<sup>*</sup></label>
                    <ng-select [multiple]="false" [items]="emailArr" formControlName="email"
                        [(ngModel)]="emailSelectedForNewMember" placeholder="Select an Email" bindLabel="email"
                        bindValue="email" [typeahead]="peopleTypeahead" (clear)="clearEmailData()"
                        (change)="handleEmailSelectForAddMember($event)">
       
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            <div class="ng-option disabled">
                                No email found for "{{searchTerm}}"
                            </div>
                        </ng-template>
                        <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
                            <div class="ng-option disabled">
                                Fetching email for "{{searchTerm}}"
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="newMember">
                    <label>Roles<sup>*</sup></label>
                    <ng-select (add)="handleRoleAdd($event)" [multiple]="true" [hideSelected]="true"
                         [closeOnSelect]="false" [searchable]="false"
                        [items]="roles" [(ngModel)]="roleAddedArray" [ngModelOptions]="{standalone: true}"
                        placeholder="Add a Role" bindLabel="name" bindValue="slug" [clearable]="false">
                    </ng-select>
                </div>
                <div class="newMember">
                    <div style="padding: 10px 0px;">Team<sup>*</sup></div>
                    <ng-select [multiple]="false" [items]="teamsArr" formControlName="teamId"
                        [placeholder]="isTeamIdInputDisable == true ? 'Disabled for Super Admin':'Select a Team'"
                        bindLabel="name" bindValue="id" [clearable]='false'
                        [readonly]="isTeamIdInputDisable || lockTeamSelect">
                    </ng-select>
                </div>
            </form>

        </div>
        <div class="modal-footer">
            <button class="btn btn-add" (click)="addNewMember(); modal.dismiss('Cross click')">Add</button>
        </div>
    </ng-template>
    <ng-template #editTeamMember let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Edit Team Member</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="editMemberForm">
                <div class="newMember">
                    <label>Email ID<sup>*</sup></label>
                    <input style="padding-left: 10px;" type="email" readonly formControlName="email"
                        placeholder="Enter Email">
                </div>
                <div class="">
                    <label>Roles<sup>*</sup></label>
                    <ng-select  [multiple]="true" [hideSelected]="true"
                        [closeOnSelect]="false" [searchable]="false" [items]="roleArrForEditMember"
                        [(ngModel)]="rolesAddedForEditMember" [ngModelOptions]="{standalone: true}"
                        placeholder="Add a Role" bindLabel="name" bindValue="slug" [clearable]='false'>
                    </ng-select>
                </div>
                <div class="newMember">
                    <div style="padding: 10px 0px;">Team <sup>*</sup></div>
                    <input type="text" style="padding-left: 10px;" formControlName="teamId" readonly>
                </div>
                <div class="newMember">
                    <label>Active Status</label>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="customSwitches"
                            formControlName="isActive" [checked]="editMemberForm.controls['isActive'].value">
                        <label class="custom-control-label" for="customSwitches"></label>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-add" (click)="handleUpdateMember(); modal.dismiss('Cross click')">Add</button>
        </div>
    </ng-template>
    <ng-template #deleteTeamMember let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Delete Team Member</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="newMember">
                Are you sure want to delete this team member ?
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn" (click)="modal.dismiss('Cross click')">No</button>
            <button class="btn btn-danger" (click)="handleDeleteTeamMember(); modal.dismiss('Cross click')">Yes</button>
        </div>
    </ng-template>
    <ng-template #addNewProcess let-modal>
        <div class="modal-header">
            <h4 style="text-align: center;">Add New Process Flow</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="newProcessForm">
            <div class="modal-body">
                <div class="newMember">
                    <label>Name<sup>*</sup></label>
                    <input type="text" class="inputPadding" formControlName="name" placeholder="Enter Name">
                </div>
                <div class="newMember">
                    <label>Description</label>
                    <input type="text" class="inputPadding" formControlName="description" placeholder="Enter Description">
                </div>
                <!-- <div class="newMember">
                    <label>Team ID<sup>*</sup></label>
                    <input type="text" formControlName="teamId" placeholder="Enter Team ID">
                </div> -->
                <div class="newMember">
                    <div style="padding: 10px 0px;">Team<sup>*</sup></div>
                    <ng-select [multiple]="false" [items]="teamsArr" formControlName="teamId"
                        placeholder='Select a Team'
                        bindLabel="name" bindValue="id" [clearable]='false'>
                    </ng-select>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-add" [disabled]="newProcessForm.invalid"
                    (click)="addNewProcessFlow(); modal.dismiss('Cross click')">Add</button>
            </div>
        </form>
    </ng-template>
</div>