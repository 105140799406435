import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import * as _ from 'lodash';
import { DataService } from "../services/data.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService,private data: DataService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const currentUser = this.authService.currentUserValue;
    const roles = this.data.getUserRole() || []
    console.log(currentUser,roles);
    if (currentUser) {
      console.log('p====',next.data.roles,_.intersection(next.data.roles,[...roles].map(e=>e.id)).length)
      if (next.data.roles &&  _.intersection(next.data.roles,roles.map(e=>e.id)).length === 0) {
        this.router.navigate["/"];
        return false;
      }
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"]);
    return false;
  }
}
